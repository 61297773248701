<template>
  <div>
    <div v-if="m_visible">
      <w-card
        elevation="5"
        class="scene-toolbar"
      >
        <w-card-text class="pa-1">
          <div class="d-flex">
            <!-- Colors -->
            <BasicTool
              :p_imgDefault="'/img/icon/default/pen_red.svg'"
              :p_imgActive="'/img/icon/active/pen_red.svg'"
              :p_imgDisabled="'/img/icon/disabled/pen_red.svg'"
              :p_caption="'Couleur rouge'"
              :p_active="p_selectedColor === 'red'"
              @click="selectColor('red')"
              p_keepBackgroundWhite
            >
            </BasicTool>
            <BasicTool
              :p_imgDefault="'/img/icon/default/pen_blue.svg'"
              :p_imgActive="'/img/icon/active/pen_blue.svg'"
              :p_imgDisabled="'/img/icon/disabled/pen_blue.svg'"
              :p_caption="'Couleur rouge'"
              :p_active="p_selectedColor === 'blue'"
              @click="selectColor('blue')"
              p_keepBackgroundWhite
            >
            </BasicTool>
            <BasicTool
              :p_imgDefault="'/img/icon/default/pen_green.svg'"
              :p_imgActive="'/img/icon/active/pen_green.svg'"
              :p_imgDisabled="'/img/icon/disabled/pen_green.svg'"
              :p_caption="'Couleur rouge'"
              :p_active="p_selectedColor === 'green'"
              @click="selectColor('green')"
              p_keepBackgroundWhite
            >
            </BasicTool>

            <w-divider vertical></w-divider>

            <!-- Annuler l'action -->
            <BasicTool
              :p_imgDefault="'/img/icon/default/undo.svg'"
              :p_imgActive="'/img/icon/active/undo.svg'"
              :p_imgDisabled="'/img/icon/disabled/undo.svg'"
              :p_caption="'[Ctrl+Z] Annuler la dernière action de point'"
              :p_disabledWarning="'Il n\'est pas possible d\'annuler la dernière action.'"
              :p_disabled="!p_canCtrlZ"
              @click="ctrlZ()"
            >
            </BasicTool>

            <!-- Refaire l'action -->
            <BasicTool
              :p_imgDefault="'/img/icon/default/redo.svg'"
              :p_imgActive="'/img/icon/active/redo.svg'"
              :p_imgDisabled="'/img/icon/disabled/redo.svg'"
              :p_caption="'[Ctrl+Y] Refaire la dernière action de point'"
              :p_disabledWarning="'Il n\'est pas possible de refaire la dernière action.'"
              :p_disabled="!p_canCtrlY"
              @click="ctrlY()"
            >
            </BasicTool>
          </div>
        </w-card-text>
      </w-card>
    </div>
  </div>
</template>

<script lang="ts">
import BasicTool from "@/components/shared/BasicTool.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ImageDrawingToolbar",
  components: { BasicTool },
  props: {
    p_canCtrlZ: {
      type: Boolean,
      required: false,
    },
    p_canCtrlY: {
      type: Boolean,
      required: false,
    },
    p_selectedColor: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const m_visible = ref(true);

    function show() {
      m_visible.value = true;
    }

    function hide() {
      m_visible.value = false;
    }

    function ctrlZ(): void {
      context.emit("ctrl-z");
    }

    function ctrlY(): void {
      context.emit("ctrl-y");
    }

    function selectColor(p_color: string): void {
      context.emit("selectColor", p_color);
    }

    return {
      m_visible,
      show,
      hide,
      ctrlZ,
      ctrlY,
      selectColor,
    };
  },
});
</script>

<style lang="scss" scoped>
.purple-white-bg {
  background-color: #f1f1f9;
}
</style>
