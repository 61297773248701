<template>
  <w-list-item
    @click="download()"
    density="compact"
    base-color="primary"
    :append-icon="mdiDownload"
  >
    GCode
  </w-list-item>
</template>

<script lang="ts">
import Wire from "@/models/Wire";
import { Privilege } from "@winnove/vue-wlib/enums";
import { mdiDownload } from "@mdi/js";
import axios, { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "GCodeDownloadButton",
  props: {
    p_wire: {
      type: Object as () => Wire,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
  },
  setup(props, context) {
    const canDownload = computed(() => {
      return props.p_wire.order!.canDownloadProductionFiles(props.p_privilege);
    });

    async function download(): Promise<void> {
      await axios
        .get("wires/" + props.p_wire.id + "/gcode")
        .then((p_response: AxiosResponse) => {
          let fileName: string = props.p_wire.order!.getReference() + ".gcode";

          saveAs(
            new Blob([p_response.data.gcode], {
              type: "text/plain;charset=utf-8",
            }),
            fileName
          );
        });
    }

    return {
      canDownload,
      download,
      mdiDownload,
    };
  },
});
</script>
