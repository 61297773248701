<template>
  <EditableDataTableMenu
    :p_data="p_data ?? '-'"
    :p_wrapContent="p_wrapContent"
    @cancel="cancel"
    @save="save"
  >
    <w-text-field
      v-model="m_data"
      :rules="notempty && !isNumber ? [(v) => v && !!v.trim()] : []"
      :type="isNumber ? 'number' : 'text'"
      autofocus
    ></w-text-field>
  </EditableDataTableMenu>
</template>

<script lang="ts">
import Logger from "@/shared/logger";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "EditableDataTableValue",
  components: {},
  inheritAttrs: false,
  props: {
    p_data: {
      // prop sync
      type: String,
      default: "",
    },
    isNumber: {
      type: Boolean,
      default: false,
    },
    notempty: {
      type: Boolean,
      default: false,
    },
    p_wrapContent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const m_data = computed({
      get: () => props.p_data,
      set: (value) => context.emit("update:p_data", value),
    });
    const m_dataSave = ref(props.p_data);

    onMounted(() => {
      m_dataSave.value = m_data.value;
    });

    function cancel() {
      m_data.value = m_dataSave.value;
    }

    function save() {
      // check if the value is not empty
      if (props.notempty && m_data.value.trim() === "") {
        m_data.value = m_dataSave.value;
        Logger.getInstance().error("Entrée non valide! ");
        return;
      }

      m_dataSave.value = m_data.value;
      context.emit("save");
    }

    return {
      m_data,
      m_dataSave,
      save,
      cancel,
    };
  },
});
</script>
