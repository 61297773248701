import { Constants } from "@/Constants";
import WinnoveHelper from "@/helpers/WinnoveHelper";
import * as TWEEN from "@tweenjs/tween.js";
import { Arcade, GimbalIndex, GimbalType } from "@winnove/vue-wlib/enums";
import * as DAT from "dat.gui";
import * as THREE from "three";
import {
  AxesHelper,
  Box3,
  Camera,
  Clock,
  Euler,
  MOUSE,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  PointLight,
  Scene as Scene3D,
  Vector3,
  WebGLRenderer,
} from "three";
import {
  ArcballControls,
  ArcballControlsMouseActionOperations,
} from "three/examples/jsm/controls/ArcballControls";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import Stats from "three/examples/jsm/libs/stats.module";
import { getCameraDefaultPositionFromBBox } from "./SceneCommon";

export default class SceneControls {
  private _scene: Scene3D;
  // Renderer - not reactive.
  public renderer: WebGLRenderer = new WebGLRenderer({
    antialias: true,
    alpha: true,
    preserveDrawingBuffer: true,
  });
  public animationRequest: number = 0;

  // Cameras.
  public cameraPerspective!: PerspectiveCamera;
  public cameraOrtho!: OrthographicCamera;
  public camera!: Camera;
  // Lights.
  public lights: PointLight[] = [];
  // Controls.
  public arcballControls!: ArcballControls; // Use when 3D rotation in unlocked.
  public orbitControls!: OrbitControls; // Use by default, prevent vertival 360 rotation.
  public selectedControl!: ArcballControls | OrbitControls;
  public translateControls: TransformControls[] = new Array(
    Constants.CONTROLS_GIMBALS_AMOUNT
  );
  public rotateControl!: TransformControls;
  // Clock - not reactive.
  public clock: Clock = new Clock();
  // Stats.
  public stats?: Stats;
  // Dat.
  public dat?: DAT.GUI;
  // ClickableZones.
  public showZones = false;
  // Penetration.
  public showPenetration = false;

  constructor(p_scene3D: Scene3D) {
    // init scene renderer
    this._scene = p_scene3D;
  }

  public dispose(): void {
    // Stop animation loop.
    this.clock.stop();
    cancelAnimationFrame(this.animationRequest);

    if (this.stats) {
      document.documentElement.removeChild(this.stats.dom);
    }
    if (this.dat) {
      this.dat.destroy();
    }
    this.renderer.domElement.remove();

    // Clean scene and renderer.
    for (const light of this.lights) {
      light.dispose();
      this._scene?.remove(light);
    }
    this.lights.length = 0;
    this.renderer.renderLists.dispose();
    this.renderer.dispose();
  }

  public init() {
    const element: HTMLElement = document.getElementById(
      "scene"
    ) as HTMLElement;
    // Create the camera.
    this.cameraPerspective = new PerspectiveCamera(
      45,
      element.clientWidth / element.clientHeight,
      1,
      1000
    );

    this.cameraOrtho = new OrthographicCamera(
      element.clientWidth / -2,
      element.clientWidth / 2,
      element.clientHeight / 2,
      element.clientHeight / -2,
      0,
      1000
    );
    this.camera = this.cameraPerspective;

    // opt out from new threejs color management
    THREE.ColorManagement.enabled = false;
    this.renderer.outputColorSpace = THREE.LinearSRGBColorSpace;

    // Configure the renderer.
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(element.clientWidth, element.clientHeight);

    // Add renderer to the DOM.
    element.appendChild(this.renderer.domElement);

    if (WinnoveHelper.isDev()) {
      this.stats = new Stats();
      this.stats.showPanel(2);
      this.stats.dom.id = "stats";
      document.documentElement.appendChild(this.stats.dom);

      this.dat = new DAT.GUI();
      this.dat.domElement.id = "gui";

      this._scene.add(new AxesHelper(10));
    }
    // Setup controls.
    this._setupControls();

    // Add lights.
    this._addLights();

    // Animate and render the scene.
    this.clock.start();

    // Run.
    this._animate();
  }

  public useArcballControls(p_val: boolean): void {
    this.arcballControls.enabled = false;
    this.orbitControls.enabled = false;
    if (p_val) {
      this.selectedControl = this.arcballControls;
    } else {
      this.selectedControl = this.orbitControls;
    }
    this.selectedControl.enabled = true;
  }

  private _setupControls(): void {
    const element: HTMLElement = document.getElementById(
      "scene"
    ) as HTMLElement;
    // Setup orbit controls. (vertical rotation locked)
    this.orbitControls = new OrbitControls(this.camera, element);
    this.orbitControls.minDistance = Constants.CONTROLS_MIN_DISTANCE;
    this.orbitControls.maxDistance = Constants.CONTROLS_MAX_DISTANCE;
    this.orbitControls.autoRotate = false;
    this.orbitControls.enablePan = Constants.CONTROLS_ENABLE_PAN;
    this.orbitControls.rotateSpeed = Constants.CONTROLS_ROTATE_SPEED;
    this.orbitControls.zoomSpeed = Constants.CONTROLS_ZOOM_SPEED;
    this.orbitControls.mouseButtons = {
      LEFT: MOUSE.ROTATE,
      MIDDLE: MOUSE.PAN,
      RIGHT: 5 as MOUSE, // disable right click
    };

    // Setup arcball controls. (360 rotation)
    this.arcballControls = new ArcballControls(this.camera, element);
    this.arcballControls.maxDistance = Constants.CONTROLS_MAX_DISTANCE;
    this.arcballControls.rotateSpeed = Constants.CONTROLS_ROTATE_SPEED;

    this.arcballControls.setMouseAction(
      "ROTATE" as ArcballControlsMouseActionOperations,
      MOUSE.LEFT
    );
    this.arcballControls.setMouseAction(
      "PAN" as ArcballControlsMouseActionOperations,
      MOUSE.MIDDLE
    );
    // disable right click
    this.arcballControls.unsetMouseAction(MOUSE.RIGHT);
    this.arcballControls.adjustNearFar = true;

    // Set default controls.
    this.useArcballControls(false);

    // Setup translate controls.
    for (let i = 0; i < Constants.CONTROLS_GIMBALS_AMOUNT; i++) {
      this.translateControls[i] = new TransformControls(
        this.camera,
        this.renderer.domElement
      );
      this.translateControls[i].size = 0.5;
      this.translateControls[i].space = "local";
      this.translateControls[i].addEventListener(
        "dragging-changed",
        (event) => {
          this.selectedControl.enabled = !event.value;
        }
      );
      this.translateControls[i].addEventListener("objectChange", () => {
        this.translateControls.forEach((p_temp) => {
          if (p_temp != this.translateControls[i])
            p_temp.object!.position.copy(
              this.translateControls[i].object!.position
            );
        });
      });
      this._scene.add(this.translateControls[i]);
    }
    this.translateControls[
      GimbalIndex.TRANSVERSAL_1
    ].children[0].children[0].children.splice(1, 1);
    this.translateControls[
      GimbalIndex.TRANSVERSAL_2
    ].children[0].children[0].children.splice(1, 1);
    // Setup rotate controls.
    this.rotateControl = new TransformControls(
      this.camera,
      this.renderer.domElement
    );
    this.rotateControl.mode = "rotate";
    this.rotateControl.size = 0.5;
    this.rotateControl.children[0].children[1].children.splice(4, 1); //remove yellow circle
    this.rotateControl.addEventListener("dragging-changed", (event) => {
      this.selectedControl.enabled = !event.value;
    });

    this._scene.add(this.rotateControl);
  }

  // Reset the camera at its default position/rotation.
  public resetCamera(bBoxScene: Box3): void {
    console.log("resetCam");
    this.camera.position.copy(this.getCameraDefaultPosition(bBoxScene));
    this.camera.lookAt(this._scene.position);
  }

  // Add lights to the scene.
  private _addLights(): void {
    const lightCenterFar = new PointLight(Constants.LIGHT_COLOR, 40000);
    lightCenterFar.position.set(0, 0, 75);
    this.lights.push(lightCenterFar);
    const lightLeft = new PointLight(Constants.LIGHT_COLOR, 20000);
    lightLeft.position.set(60, 0, -60);
    this.lights.push(lightLeft);
    const lightRight = new PointLight(Constants.LIGHT_COLOR, 20000);
    lightRight.position.set(-60, 0, -60);
    this.lights.push(lightRight);

    for (const light of this.lights) {
      this._scene.add(light);
    }

    if (this.dat) {
      const lightFolder = this.dat.addFolder("Lights");
      lightFolder
        .add(lightCenterFar, "intensity", 0, 50000)
        .name("Center far intensity");
      lightFolder.add(lightLeft, "intensity", 0, 50000).name("Left intensity");
      lightFolder
        .add(lightRight, "intensity", 0, 50000)
        .name("Right intensity");
    }
  }

  // Animation loop.
  private _animate(): void {
    if (WinnoveHelper.isDev()) {
      this.stats?.begin();
    }
    this.selectedControl.update();
    TWEEN.update();
    this.renderer.render(this._scene, this.camera);
    if (WinnoveHelper.isDev()) {
      this.stats?.end();
    }
    this.animationRequest = requestAnimationFrame(() => {
      this._animate();
    });
  }

  public resizeScene(sceneElt: HTMLElement): void {
    const targetAspect: number = sceneElt.clientWidth / sceneElt.clientHeight;

    // Perspective.
    this.cameraPerspective.aspect = targetAspect;
    this.cameraPerspective.updateProjectionMatrix();

    // Orthographic.
    this.cameraOrtho.left = sceneElt.clientWidth / -2;
    this.cameraOrtho.right = sceneElt.clientWidth / 2;
    this.cameraOrtho.top = sceneElt.clientHeight / 2;
    this.cameraOrtho.bottom = sceneElt.clientHeight / -2;
    this.cameraOrtho.updateProjectionMatrix();

    // Renderer
    this.renderer.setSize(sceneElt.clientWidth, sceneElt.clientHeight);
  }

  public toggleCamera(): void {
    // Set same position.
    this.cameraPerspective.position.copy(this.camera.position);
    this.cameraPerspective.up.copy(this.camera.up);
    this.cameraOrtho.position.copy(this.camera.position);
    this.cameraOrtho.up.copy(this.camera.up);

    if (this.camera === this.cameraPerspective) {
      this.camera = this.cameraOrtho;
    } else {
      this.camera = this.cameraPerspective;
    }

    this.arcballControls.camera = this.camera;
    this.orbitControls.object = this.camera;
    this.camera.lookAt(this.selectedControl.target);
  }

  public getCameraDefaultPosition(bBoxScene: Box3): Vector3 {
    return getCameraDefaultPositionFromBBox(
      this.cameraPerspective,
      bBoxScene,
      Constants.CAMERA_DEFAULT_POSITION_PADDING
    );
  }

  public interpolateCameraTo(p_x: number, p_y: number, p_z: number): void {
    // Already moving.
    if (!this.selectedControl.enabled) {
      return;
    }

    const coords = {
      camPosX: this.camera.position.x,
      camPosY: this.camera.position.y,
      camPosZ: this.camera.position.z,
      camUpX: this.camera.up.x,
      camUpY: this.camera.up.y,
      camUpZ: this.camera.up.z,
      controlTargetPosX: this.selectedControl.target.x,
      controlTargetPosY: this.selectedControl.target.y,
      controlTargetPosZ: this.selectedControl.target.z,
    };

    new TWEEN.Tween(coords)
      .to(
        {
          camPosX: p_x,
          camPosY: p_y,
          camPosZ: p_z,
          camUpX: 0,
          camUpY: 1,
          camUpZ: 0,
          controlTargetPosX: this._scene.position.x,
          controlTargetPosY: this._scene.position.y,
          controlTargetPosZ: this._scene.position.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onStart(() => {
        this.selectedControl.enabled = false;
      })
      .onUpdate(() => {
        this.camera.position.set(
          coords.camPosX,
          coords.camPosY,
          coords.camPosZ
        );
        this.selectedControl.target.set(
          coords.controlTargetPosX,
          coords.controlTargetPosY,
          coords.controlTargetPosZ
        );
        this.camera.up.set(coords.camUpX, coords.camUpY, coords.camUpZ);
        this.camera.lookAt(
          this._scene.position.clone().add(this.selectedControl.target)
        );
      })
      .onComplete(() => {
        this.selectedControl.enabled = true;
      })
      .start();
  }

  public getRendereredData(): string {
    this.renderer.render(this._scene, this.camera);
    return this.renderer.domElement.toDataURL("image/png");
  }

  public setBaseGimbal(): GimbalType {
    this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showX =
      this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showY =
      this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showZ =
        true;
    this.translateControls[GimbalIndex.TRANSVERSAL_1].showX =
      this.translateControls[GimbalIndex.TRANSVERSAL_2].showX =
      this.translateControls[GimbalIndex.VERTICAL].showY =
        false;
    this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].object?.rotation.set(
      0,
      0,
      0
    );
    return GimbalType.BASE;
  }

  public setPlaneGimbal(p_rot: Euler = new Euler()): GimbalType {
    this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showX =
      this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showY = true;
    this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showZ =
      this.translateControls[GimbalIndex.TRANSVERSAL_1].showX =
      this.translateControls[GimbalIndex.TRANSVERSAL_2].showX =
      this.translateControls[GimbalIndex.VERTICAL].showY =
        false;
    this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].object?.rotation.copy(
      p_rot
    );
    return GimbalType.PLANE;
  }

  public setWireGimbal(): GimbalType {
    this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showX =
      this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showY =
      this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showZ =
        false;
    this.translateControls.forEach((p_control, p_id) => {
      if (p_id > 0)
        p_control.object!.position.copy(
          this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].object!.position
        );
    });
    return GimbalType.NONE;
  }

  public viewLingual() {
    const distance = this.camera.position.distanceTo(this._scene.position);

    this.interpolateCameraTo(
      this._scene.position.x,
      this._scene.position.y,
      distance
    );
  }

  public viewOcclusal(p_arcade: Arcade) {
    const distance = this.camera.position.distanceTo(this._scene.position);
    this.interpolateCameraTo(
      0,
      p_arcade === Arcade.MANDIBLE ? distance : -distance,
      0
    );
  }

  public getSelectedPointArcadeName(): string {
    return this.translateControls[
      GimbalIndex.BASE_PLANE_ANTERO
    ].object!.name.split("|")[0];
  }

  public getSelectedPointId(): number {
    return parseInt(
      this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].object!.name.split(
        "|"
      )[1]
    );
  }

  public getSelectedPointObject(): Object3D | undefined {
    return this.translateControls[GimbalIndex.BASE_PLANE_ANTERO].object;
  }
}
