<template>
  <w-card class="h-100 w-100 bg-white custom-padding">
    <w-row
      class="custom-height w-100 ma-0 d-flex flex-column justify-center align-center"
    >
      <w-line
        class="ma-4"
        :data="m_shippedVsOrderChartData"
        :options="m_options"
        :p_loading="m_loading"
      />
    </w-row>
    <w-btn
      class="go-to-top"
      data-cy="go-to-top-stats"
      size="small"
      variant="tonal"
      rounded
      @click="goTo(0, {})"
    >
      <w-icon :icon="mdiChevronUp" />
      <w-tooltip
        activator="parent"
        location="top"
      >
        <span>Aller aux premières statistiques</span>
      </w-tooltip>
    </w-btn>
  </w-card>
</template>

<script lang="ts">
import {
  PROD_STAT_LINE_1,
  PROD_STAT_LINE_2,
  PROD_STAT_LINE_3,
} from "@winnove/vue-wlib/enums";
import { mdiChevronUp } from "@mdi/js";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useGoTo } from "vuetify";

export default defineComponent({
  name: "ShippedVersusOrderLine",
  components: {},
  props: {
    p_refreshInterval: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const goTo = useGoTo();
    let m_loading = ref(true);
    const m_shippedVsOrderChartData = ref();
    const m_interval = ref();

    const m_options = ref({
      responsive: true,
      maintainAspectRatio: true,
      tension: 0.25,
      plugins: {
        datalabels: {
          font: {
            weight: "bold",
            size: 14,
          },
          display: "auto",
        },
      },
    });

    onMounted(() => {
      refreshLineData();
      m_interval.value = setInterval(refreshLineData, props.p_refreshInterval);
    });

    onUnmounted(() => {
      clearInterval(m_interval.value);
    });

    async function refreshLineData(): Promise<void> {
      m_loading.value = true;

      await axios
        .get(`stat_production_process/ordersVsShipped`, {
          // query URL without using browser cache
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        })
        .then((p_response: AxiosResponse) => {
          const response = p_response.data.response.orderShippedPerWeek;
          m_shippedVsOrderChartData.value = constructBarChart(response);

          m_loading.value = false;
        });
    }

    function constructBarChart(orderShippedPerWeek: any[]): any {
      let weekLabels: string[] = [];
      let dataOrdered: number[] = [];
      let dataShipped: number[] = [];
      let dataEstimation: number[] = [];

      for (let dataOrders of orderShippedPerWeek) {
        weekLabels.push(dataOrders.civilWeek);
        dataOrdered.push(dataOrders.createdOrders);
        dataShipped.push(dataOrders.shippedOrders);
        dataEstimation.push(dataOrders.estimateToShip);
      }

      return {
        labels: weekLabels,
        datasets: [
          {
            label: "Nombre de commande S-2",
            backgroundColor: PROD_STAT_LINE_1,
            borderColor: PROD_STAT_LINE_1,
            pointRadius: 15,
            pointHoverRadius: 20,
            data: dataOrdered,
            datalabels: {
              color: "white",
              fontStyle: "bold",
            },
          },
          {
            label: "Nombre d'expédition estimée",
            backgroundColor: PROD_STAT_LINE_2,
            borderColor: PROD_STAT_LINE_2,
            pointRadius: 15,
            pointHoverRadius: 20,
            data: dataEstimation,
            datalabels: {
              color: "white",
              fontStyle: "bold",
            },
          },
          {
            label: "Nombre d'expédition",
            backgroundColor: PROD_STAT_LINE_3,
            borderColor: PROD_STAT_LINE_3,
            pointRadius: 15,
            pointHoverRadius: 20,
            data: dataShipped,
            hidden: true,
            datalabels: {
              color: "white",
              fontStyle: "bold",
            },
          },
        ],
      };
    }

    return {
      goTo,
      mdiChevronUp,
      refreshLineData,
      m_shippedVsOrderChartData,
      m_options,
      m_loading,
    };
  },
});
</script>

<style lang="scss" scoped>
.go-to-top {
  position: absolute;
  right: 25px;
  top: 15px;
}

.custom-padding {
  padding-top: 100px;
}
</style>
