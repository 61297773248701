<template>
  <w-card
    class="pa-0 pt-0 ma-0 mt-0 reduce-br"
    elevation="0"
  >
    <!-- show wire config -->
    <w-card-text class="px-4 pb-0 pt-2">
      <p class="text-caption">
        Décrivez les modifications à apporter à ce fil, puis cliquez sur
        "Envoyer".
      </p>
      <CommentArea
        data-cy="modification-comment-area"
        :p_comment="m_updateComment"
        color="white"
        :p_label="
          p_privilege === Privilege.ADMIN
            ? 'Modifications Client Demandées'
            : 'Modifications Demandées'
        "
        @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
      />
    </w-card-text>

    <w-card-actions class="d-flex justify-end">
      <!-- Cancel button activator -->
      <w-btn
        data-cy="change-decision-button"
        color="error"
        size="small"
        @click="$emit('cancel')"
      >
        Changer de décision
      </w-btn>

      <!-- Submit validation -->
      <w-btn
        data-cy="submit-validation-button"
        color="white"
        size="small"
        @click="$emit('submit')"
      >
        Envoyer
      </w-btn>
    </w-card-actions>
  </w-card>
</template>

<script lang="ts">
import CommentArea from "@/components/shared/CommentArea.vue";
import Order from "@/models/Order";
import { Privilege } from "@winnove/vue-wlib/enums";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "WireEditRequestCard",
  components: {
    CommentArea,
  },
  props: {
    p_order: {
      type: Object as () => Order,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
  },
  setup(props, context) {
    const m_updateComment = ref(props.p_order.getUpdateComment());
    return {
      m_updateComment,
      Privilege: Privilege,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-btn::before {
  background-color: transparent;
}

.reduce-br {
  border-radius: 5px;
}
.project-info-tabs {
  img {
    background-color: white !important;
    border-radius: 12px;
  }
}
</style>
