<template>
  <div v-if="m_visible">
    <w-card
      elevation="5"
      class="purple-white-bg"
    >
      <w-card-text class="py-3 px-3">
        <!-- wires choices -->
        <div
          v-for="wire in m_wires"
          :key="wire.id"
          class="my-2"
        >
          <w-card
            @click="selectWire(wire.id)"
            :color="wire.selected ? '#d9eeff' : '#FFFFFF'"
            :class="' d-flex flex-column justify-space-between'"
            hover
          >
            <w-card-title class="pb-0"
              ><h5>{{ wire.name }}</h5></w-card-title
            >
            <w-img
              :src="p_snapshotArray[wire.id]"
              alt=""
              width="100%"
            />
          </w-card>
        </div>

        <!-- Buttons -->
        <div class="d-flex flex-column">
          <!-- Choisir ce dessin -->
          <w-btn
            @click="submitWireChoice()"
            class="mt-2"
            block
            :disabled="!m_wires.some((wire) => wire.selected)"
            :iconRight="mdiCheckCircle"
          >
            Choisir ce dessin
          </w-btn>

          <!-- Revenir au dessin manuel -->
          <w-btn
            @click="$emit('requestManuelDrawing')"
            color="primary"
            block
            class="mt-2"
            variant="outlined"
            :iconRight="mdiDraw"
          >
            Revenir au dessin manuel
          </w-btn>
        </div>
      </w-card-text>
    </w-card>
  </div>
</template>

<script lang="ts">
import Project from "@/models/Project";
import {
  AlgoVersion,
  Arcade,
  Dimension,
  EditorState,
  Material,
  Privilege,
} from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import { mdiCheckCircle, mdiDraw } from "@mdi/js";
import { Ref, computed, defineComponent, ref, watch } from "vue";

// Interface for the wires
interface AIWire {
  id: number;
  name: string;
  points: string;
  selected: boolean;
}

export default defineComponent({
  name: "SceneViewAIWireChoice",
  props: {
    p_project: {
      type: Object as () => Project,
      required: false,
    },
    p_editorState: {
      type: Number as () => EditorState,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_selectedArcade: {
      type: Number as () => Arcade,
      required: true,
    },
    p_jsonWires: {
      type: String,
      required: true,
    },
    p_snapshotArray: {
      type: Array as () => string[],
      required: true,
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_visible = ref(false);
    const m_wires: Ref<AIWire[]> = ref<AIWire[]>([]);

    // Parse the JSON string to get the wires
    function parseWireJson() {
      // empty the array
      m_wires.value = [];
      // parse the JSON string
      if (!props.p_jsonWires || props.p_jsonWires === "") {
        return;
      }
      const parsedWireJson = JSON.parse(props.p_jsonWires);
      const wiresJson = parsedWireJson.wires;

      // for each entry wire_x in the wires value of the parsed JSON, add a new wire to the array
      for (const [key, value] of Object.entries(wiresJson)) {
        // check if the key is a wire
        if (!key.startsWith("wire_")) {
          continue;
        }
        // get the wire id
        const wireId = parseInt(key.split("_")[1]);
        // add the wire to the array
        const pts3D = JSON.parse(JSON.stringify(value)).pts3D;
        m_wires.value.push({
          id: wireId,
          name: "Proposition " + (wireId + 1),
          points: JSON.stringify(pts3D),
          selected: false,
        });
      }
      // get the snapshot for the wire
      setTimeout(() => {
        getSnapshots();
        show();
      }, 1000); // TODO : wait for the scene to be loaded directly in the getAIsnapshot function
    }

    const hasMaxiArch = computed(() => {
      return (
        !!props.p_project?.archMaxilla() && !!props.p_project.archMaxilla()!.id
      );
    });

    const hasMandiArch = computed(() => {
      return (
        !!props.p_project?.archMandible() &&
        !!props.p_project.archMandible()!.id
      );
    });

    const editingPrescription = computed(() => {
      return props.p_editorState === EditorState.PRESCRIPTION_EDIT;
    });

    function show() {
      m_visible.value = true;
    }

    function hide() {
      m_visible.value = false;
    }

    watch(
      () => props.p_editorState,
      () => {
        if (props.p_editorState === EditorState.AI_WIRE_GENERATION) {
          parseWireJson();
        } else {
          hide();
        }
      }
    );

    function selectWire(id: number) {
      // save the selected value of the wire with the given id
      const selected = m_wires.value[id].selected;
      // unselect all wires
      m_wires.value.forEach((wire) => {
        wire.selected = false;
      });
      // select the wire with the given id
      m_wires.value[id].selected = !selected;
      // build the wire in the scene
      if (m_wires.value[id].selected) {
        buildWireInScene(id);
      } else {
        unloadWire();
      }
    }

    function _getWireJSON(id: number) {
      const wire = m_wires.value.filter((wire) => wire.id === id)[0];
      // To display the wire, we need to build a json object with the moints, the material, the dimension, isSquared, and color
      const wireJson = [
        {
          points: JSON.parse(wire.points),
          material: Material.TMA,
          dimension: Dimension._016,
          isSquared: false,
          color: "B37005",
          history: true, // flag initially used by the history drawer. TODO : rename it in the next refactoring
        },
      ];
      return JSON.stringify(wireJson);
    }

    function buildWireInScene(id: number) {
      context.emit("buildWireInScene", _getWireJSON(id));
    }

    function unloadWire() {
      context.emit("unload-wire");
    }

    function getSnapshots() {
      const wiresArray: string[] = [];
      m_wires.value.forEach((wire) => {
        wiresArray.push(_getWireJSON(wire.id));
      });
      context.emit("getSnapshots", JSON.stringify(wiresArray));
    }

    function submitWireChoice() {
      // get the selected wire
      const selectedWire = m_wires.value.filter((wire) => wire.selected)[0];

      // check if a wire is selected
      if (!selectedWire) {
        logger.error("No wire selected");
        return;
      }
      // get the ai choice
      const aiChoice =
        m_wires.value.indexOf(selectedWire) + AlgoVersion.AI_CHOICE_1;
      // emit the event
      context.emit(
        "submitWireChoice",
        JSON.stringify(JSON.parse(selectedWire.points)),
        aiChoice
      );
    }

    return {
      Arcade,
      EditorState,
      Privilege,
      m_visible,
      hasMaxiArch,
      hasMandiArch,
      editingPrescription,
      m_wires,
      show,
      hide,
      selectWire,
      submitWireChoice,
      mdiCheckCircle,
      mdiDraw,
    };
  },
});
</script>

<style lang="scss" scoped>
.purple-white-bg {
  background-color: #f1f1f9;
}
</style>
