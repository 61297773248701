<template>
  <!-- Comments. -->
  <td>
    <w-menu
      v-if="p_order"
      right
      min-width="340px"
      max-width="340px"
      open-on-hover
      :close-on-content-click="false"
      v-model="m_menu"
    >
      <template v-slot:activator="{ props }">
        <w-badge
          v-if="p_order.hasAttestationComment()"
          color="red"
          overlap
          dot
          bordered
          ><w-icon
            v-bind="props"
            :icon="mdiMessageTextOutline"
          ></w-icon
        ></w-badge>
        <w-badge
          v-else-if="p_order.hasInternalComment()"
          color="green"
          overlap
          dot
          bordered
          ><w-icon
            v-bind="props"
            :icon="mdiMessageTextOutline"
          ></w-icon
        ></w-badge>
        <w-badge
          v-else-if="p_order.hasClientComment()"
          color="blue"
          overlap
          dot
          bordered
          ><w-icon
            v-bind="props"
            :icon="mdiMessageTextOutline"
          ></w-icon
        ></w-badge>
        <w-badge
          v-else
          color="transparent"
          overlap
          dot
          ><w-icon
            color="grey lighten-1"
            v-bind="props"
            :icon="mdiMessageTextOutline"
          ></w-icon
        ></w-badge>
      </template>
      <div
        class="pa-3"
        style="background-color: white"
      >
        <WireSummary
          :p_privilege="p_privilege"
          :p_order="p_order"
          :p_project="p_order.project"
          :p_isEditing="false"
          @order_updated="$emit('refresh')"
          @open-project="$emit('load')"
        />
      </div>
    </w-menu>
  </td>
</template>

<script lang="ts">
import Order from "@/models/Order";
import { Privilege } from "@winnove/vue-wlib/enums";
import { mdiMessageTextOutline } from "@mdi/js";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CommentsDialog",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_order: {
      type: Object as () => Order,
      required: true,
    },
  },
  setup(props, context) {
    const m_menu = ref(false);
    return { mdiMessageTextOutline, m_menu };
  },
});
</script>
