<template>
  <w-chip
    data-cy="statsChip"
    variant="flat"
    class="mx-2"
    :color="p_color"
    size="small"
  >
    <w-icon
      class="pr-2"
      :color="p_textColor"
      :icon="p_icon"
      size="x-large"
    >
    </w-icon>
    <w-progress-circular
      v-if="p_loading"
      indeterminate
      :color="p_textColor"
      size="20"
    ></w-progress-circular>
    <span
      v-else
      :style="{ color: p_textColor }"
    >
      <span class="text-h6 font-weight-bold">{{ p_number }}</span
      ><span
        :style="{ color: p_textColor }"
        v-if="p_late"
        >&nbsp;en retard</span
      ><span
        :style="{ color: p_textColor }"
        v-else
        >&nbsp;urgents</span
      >
    </span>
  </w-chip>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProductionProjectTableStatsChip",
  components: {},
  props: {
    p_number: {
      type: Number,
      required: false,
    },
    p_color: {
      type: String,
      required: true,
    },
    p_icon: {
      type: String,
      required: true,
    },
    p_textColor: {
      type: String,
      required: false,
      default: "black",
    },
    p_loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_late: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    return {};
  },
});
</script>
