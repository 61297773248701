<template>
  <w-list-item
    @click="download()"
    density="compact"
    base-color="primary"
    :append-icon="mdiDownload"
  >
    {{ m_btn_text }}
  </w-list-item>
</template>

<script lang="ts">
import Arch from "@/models/Arch";
import { Arcade, Privilege } from "@winnove/vue-wlib/enums";
import { mdiDownload } from "@mdi/js";
import axios, { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "STLDownloadButton",
  props: {
    p_arch: {
      type: Object as () => Arch,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_for3DPrinting: {
      type: Boolean as () => boolean,
      required: true,
    },
  },
  setup(props, context) {
    const m_btn_text = ref("");

    onMounted(() => {
      if (props.p_arch.jaw === Arcade.MANDIBLE) {
        m_btn_text.value = "STL Mandibule";
      } else {
        m_btn_text.value = "STL Maxillaire";
      }
      if (props.p_for3DPrinting) {
        m_btn_text.value += " (I3D)";
      }
    });

    async function download(): Promise<void> {
      if (props.p_for3DPrinting) {
        await axios
          .get("archs/" + props.p_arch.id + "/stlI3D", {
            responseType: "blob",
          })
          .then((p_response: AxiosResponse) => {
            const file: File = new File(
              [p_response.data],
              props.p_arch.fileName
            );
            saveAs(file, props.p_arch.getReference() + "_I3D.stl");
          });
      } else {
        await axios
          .get("archs/" + props.p_arch.id + "/stl", {
            responseType: "blob",
          })
          .then((p_response: AxiosResponse) => {
            const file: File = new File(
              [p_response.data],
              props.p_arch.fileName
            );
            saveAs(file, props.p_arch.getReference() + ".stl");
          });
      }
    }

    return {
      m_btn_text,
      download,
      mdiDownload,
    };
  },
});
</script>
