<template>
  <w-container fluid>
    <w-expansion-panels v-model="m_panel">
      <w-expansion-panel>
        <w-expansion-panel-title>
          Ajouter un utilisateur
        </w-expansion-panel-title>
        <w-expansion-panel-text>
          <w-form
            ref="formNewUser"
            v-model="m_isNewUserFormValid"
            @submit.prevent="addUser()"
            nowrap
          >
            <w-text-field
              v-model.trim="m_newUser.lastName"
              label="Nom"
              :rules="[(v) => (v && !!v.trim()) || 'Nom requis']"
              required
              autofocus
            ></w-text-field>

            <w-text-field
              v-model.trim="m_newUser.firstName"
              label="Prénom"
              :rules="[(v) => (v && !!v.trim()) || 'Prénom requis']"
              required
            ></w-text-field>

            <w-text-field
              v-model.trim="m_newUser.email"
              label="Email"
              :rules="[(v) => (v && !!v.trim()) || 'Adresse email requise']"
              required
            ></w-text-field>

            <w-text-field
              v-model.trim="m_newUser.password"
              label="Mot de passe"
              :rules="[
                (v) =>
                  (v && testPwd(v)) ||
                  'Le mot de passe doit faire Min. 8 caractères avec au moins un chiffre et un caractère spécial.',
              ]"
              required
            ></w-text-field>

            <w-select
              v-model="m_newUser.privilege"
              :items="m_privileges"
              item-title="text"
              item-value="value"
              class="rounded-xl p-0 m-0"
              label="Privileges"
              required
            ></w-select>
            <w-select
              v-model="m_newUser.role"
              :items="m_roles"
              item-title="text"
              item-value="value"
              class="rounded-xl p-0 m-0"
              label="Roles"
              required
            ></w-select>
            <div class="d-flex justify-end">
              <w-btn
                :disabled="!m_isNewUserFormValid"
                type="submit"
                >Ajouter
              </w-btn>
            </div>
          </w-form>
        </w-expansion-panel-text>
      </w-expansion-panel>
    </w-expansion-panels>

    <w-data-table
      class="table_user mt-4 text-primary"
      :headers="m_headers"
      :items="m_users"
      :search="m_search"
      :items-per-page="20"
      :hover="true"
      item-key="id"
      fixed-header
      density="compact"
    >
      <template v-slot:top>
        <w-text-field
          v-model="m_search"
          label="Rechercher"
          class="mx-4 pt-4"
          :prepend-inner-icon="mdiMagnify"
        ></w-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <!-- userID -->
          <td>{{ item.id }}</td>
          <!-- user lastName -->
          <td>
            <EditableDataTableValue
              v-model:p_data="item.lastName"
              @save="updateUser(item)"
              notempty
            />
          </td>
          <!-- user firstName -->
          <td>
            <EditableDataTableValue
              v-model:p_data="item.firstName"
              @save="updateUser(item)"
              notempty
            />
          </td>
          <!-- email -->
          <td class="text-center">
            <EditableDataTableValue
              v-model:p_data="item.email"
              @save="updateUser(item)"
              notempty
            />
          </td>
          <!-- password -->
          <td class="text-center">
            <EditableDataTablePassword
              v-model:p_data="item.password"
              @save="updateUserPwd(item)"
              hideContent
            />
          </td>
          <!-- phoneNumber -->
          <td class="text-center">
            <EditableDataTableValue
              v-model:p_data="item.phoneNumber"
              @save="updateUser(item)"
            />
          </td>
          <!-- preferences -->
          <td class="text-center">
            <EditableDataTableTextArea
              v-model:p_data="item.preferences"
              @save="updateUser(item)"
            />
          </td>
          <!-- blocked -->
          <td class="text-center">
            <EditableDataTableSelect
              v-model:p_data="item.blocked"
              :p_itemvalues="BooleanChoice"
              :p_itemtexts="BooleanChoiceString"
              @save="updateUser(item)"
            />
          </td>
          <!-- Address -->
          <td class="text-center">
            <EditableUserAddress
              v-if="item.address"
              v-model:p_address="item.address"
              @save="updateAddress(item.address)"
            />
            <!-- {{ item.address }} -->
          </td>
          <!-- firstConnect -->
          <td class="text-center">
            {{ !item.fistConnect ? "Oui" : "Non" }}
          </td>
          <!-- Privilege -->
          <td class="text-center">
            <span>
              <EditableDataTableSelect
                v-model:p_data="item.privilege"
                :p_itemvalues="Privilege"
                :p_itemtexts="PrivilegeString"
                @save="updateUser(item)"
              />
            </span>
            <!-- {{ PrivilegeString[item.privilege] }} -->
          </td>
          <!-- Role -->
          <td class="text-center">
            <span>
              <EditableDataTableSelect
                v-model:p_data="item.role"
                :p_itemvalues="Role"
                :p_itemtexts="RoleString"
                @save="updateUser(item)"
              />
            </span>
          </td>
          <!-- UnavailableDates -->
          <!-- <td class="text-center w-25">
            <EditableDataTableValue
              v-model:p_data="item.unavailableDates"
              @save="updateUser(item)"
            />
          </td> -->
          <!-- Actions -->
          <td class="text-center"></td>
        </tr>
      </template>
    </w-data-table>
  </w-container>
</template>

<script lang="ts">
import AddressHelper from "@/helpers/AddressHelper";
import { EnumHelper } from "@/helpers/EnumHelper";
import UserHelper from "@/helpers/UserHelper";
import Address from "@/models/Address";
import User from "@/models/User";
import {
  BooleanChoice,
  BooleanChoiceString,
  Privilege,
  PrivilegeString,
  Role,
  RoleString,
} from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import { mdiMagnify } from "@mdi/js";
import { useRepo } from "pinia-orm";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "UserTable",
  props: {},
  setup(props, context) {
    const logger = Logger.getInstance();
    const formNewUser = ref<InstanceType<typeof HTMLFormElement> | null>(null);
    const m_search = ref("");
    const m_panel = ref(-1);
    const m_users = ref<User[]>([]);
    const m_isNewUserFormValid = ref(true);
    const m_newUser = ref<User>(new User());
    const m_privileges = EnumHelper.getSelectListAsArray(
      Privilege,
      PrivilegeString
    );
    const m_roles = EnumHelper.getSelectListAsArray(Role, RoleString);
    const m_headers = [
      {
        title: "ID",
        align: "start",
        value: "id",
        sortable: true,
        filterable: true,
      },
      {
        title: "NOM",
        align: "start",
        value: "lastName",
        sortable: true,
        filterable: true,
      },
      {
        title: "PRENOM",
        align: "start",
        value: "firstName",
        sortable: true,
        filterable: true,
      },
      {
        title: "EMAIL",
        align: "center",
        value: "email",
        sortable: true,
        filterable: true,
      },
      {
        title: "Mot de passe",
        align: "center",
        value: "password",
        sortable: false,
        filterable: false,
      },
      {
        title: "TELEPHONE",
        align: "center",
        value: "phoneNumber",
        sortable: true,
        filterable: true,
      },
      {
        title: "PRÉFÉRENCES",
        align: "left",
        value: "preferences",
        sortable: false,
        filterable: false,
      },
      {
        title: "BLOQUÉ",
        align: "left",
        value: "blocked",
        sortable: true,
        filterable: true,
      },
      {
        title: "ADRESSE",
        align: "center",
        value: "address",
        sortable: false,
        filterable: false,
        minWidth: "200px",
      },
      {
        title: "CONNEXION",
        align: "center",
        value: "firstConnect",
        sortable: true,
        filterable: false,
      },
      {
        title: "PRIVILEGES",
        align: "center",
        value: "privilege",
        sortable: true,
        filterable: false,
      },
      {
        title: "ROLES",
        align: "center",
        value: "role",
        sortable: true,
        filterable: false,
      },
      // {
      //   title: "JOURS INDISPONIBLES",
      //   align: "center",
      //   value: "unavailableDates",
      //   sortable: false,
      //   filterable: false,
      // },
      {
        title: "ACTIONS",
        align: "center",
        filterable: false,
        sortable: false,
        value: "actions",
        width: "200px",
      },
    ];

    onMounted(() => {
      refresh();
    });

    function refresh(): void {
      m_users.value = useRepo(User).query().withAll().get();
    }

    function addUser(): void {
      UserHelper.addUser(m_newUser.value as User)
        .then(() => {
          formNewUser.value?.reset();
          m_newUser.value = new User();
          m_panel.value = -1;
          refresh();
          logger.success("Utilisateur ajouté avec succès.");
        })
        .catch((error) => {
          logger.error(
            error,
            "Erreur lors de l'ajout de l'utilisateur. Veuillez vérifier que le mail n'est pas déjà pris."
          );
        });
    }

    async function updateUser(p_user: User): Promise<void> {
      // Update user.
      await UserHelper.updateUser(p_user).then(() => {
        refresh();
        logger.success("Utilisateur modifié avec succès.");
      });
    }

    async function updateAddress(p_address: Address): Promise<void> {
      await AddressHelper.updateAddress(p_address).then(() => {
        refresh();
        logger.success("Adresse modifiée avec succès.");
      });
    }

    function testPwd(p_pwd: string): boolean {
      return UserHelper.testPwd(p_pwd);
    }

    async function updateUserPwd(p_user: User): Promise<void> {
      if (await UserHelper.updateUserPwd(p_user)) {
        refresh();
        logger.success("Mot de passe modifié avec succès.");
      } else {
        logger.warning(
          "Mot de passe non modifié : Min. 8 caractères avec au moins un chiffre et un caractère spécial."
        );
      }
    }

    return {
      formNewUser,
      Privilege,
      PrivilegeString,
      Role,
      RoleString,
      m_headers,
      m_search,
      m_panel,
      m_users,
      m_isNewUserFormValid,
      m_newUser,
      m_privileges,
      m_roles,
      BooleanChoice,
      BooleanChoiceString,
      refresh,
      addUser,
      updateUser,
      updateAddress,
      testPwd,
      updateUserPwd,
      mdiMagnify,
    };
  },
});
</script>

<style lang="scss">
.table_user {
  line-height: 1;
}
</style>
