import Order from "@/models/Order";
export default class SortHelper {
  static sortOrdersByDoctor(orders: Order[]): Array<{
    id: number;
    name: string;
    items: Array<Order>;
  }> {
    const ret: Array<{
      id: number;
      name: string;
      items: Array<Order>;
    }> = [];
    // build a dict of doctors while keeping the current orders order
    orders.forEach((order) => {
      const doctorId = order.project!.patient!.userId!;
      // check if the doctor exists in ret
      if (!ret.some((doctor) => doctor.id === doctorId)) {
        // if no add the doctor to the list
        ret.push({
          id: doctorId,
          name: order.project!.patient!.user!.lastName.toUpperCase(),
          items: [],
        });
      }
      const doctor = ret.find((doctor) => doctor.id === doctorId);
      doctor!.items.push(order);
    });
    return ret;
  }

  static groupAndSortOrders(p_orders: Order[]): ItemGroup<Order>[] {
    // return an array of ItemGroup<Order> objects build
    // such as one item group is one doctor
    // and the items are the orders of this doctor ordered by installation date
    // and the item groups are ordered by min installation date
    const ret: ItemGroup<Order>[] = [];
    // get all orders asociated with a task
    const ordersToList = p_orders;
    // sort orders by installation date
    const sortedOrders = this.sortOrders(ordersToList);
    // sort orders by doctor while keeping the installation date order
    const doctorOrdersList = this.sortOrdersByDoctor(sortedOrders);
    // for each entry in dictDoctorOrders, create an ItemGroup<Order> object
    // and add it to the ret array
    for (const doctorOrders of doctorOrdersList) {
      const itemGroup: ItemGroup<Order> = {
        name: doctorOrders.name,
        items: doctorOrders.items,
      };
      ret.push(itemGroup);
    }
    // update the size of m_itemsSelected
    return ret;
  }

  static sortOrdersAsGroup(p_orders: Order[]): ItemGroup<Order>[] {
    const ret: ItemGroup<Order>[] = [];
    const ordersToList = this.sortOrders(p_orders);
    // sort and group all orders in the same list
    const itemGroup: ItemGroup<Order> = {
      name: "Orders",
      items: ordersToList,
    };
    ret.push(itemGroup);

    return ret;
  }

  static sortOrders(p_orders: Order[]): Order[] {
    // sort orders by installation date
    const orders = p_orders.toSorted((a, b) => {
      if (a.project!.sortId! < b.project!.sortId!) return -1;
      if (a.project!.sortId! > b.project!.sortId!) return 1;
      return 0;
    });
    return orders;
  }
}
