<template>
  <w-menu
    v-model="m_menu"
    :close-on-content-click="false"
    transition="slide-y-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ props }">
      <w-text-field
        data-cy="date-picker-input"
        v-model="m_date"
        type="date"
        :label="p_label"
        :rules="[
          (v: string) => !notempty || (v && !!v.trim()) || 'Date requise',
          (v: string) =>
            !p_min || new Date(v) > new Date(p_min) || 'Date minimal',
          (v: string) =>
            !p_max || new Date(v) < new Date(p_max) || 'Date maximal',
        ]"
        :append-inner-icon="mdiCalendar"
        v-bind="props"
        :required="notempty"
        :readonly="p_privilege == Privilege.FREEMIUM ? true : false"
        :hide-details="hidedetails"
        @click="
          (e: any) => {
            e.preventDefault();
            m_menu = true;
          }
        "
      ></w-text-field>
    </template>
    <w-date-picker
      class="custom-date-picker text-white bg-primary"
      data-cy="date-picker-popup"
      v-model="m_datePicker"
      @update:model-value="pickDate"
      :min="p_privilege === Privilege.IN_OFFICE ? undefined : p_min"
      :max="p_privilege === Privilege.IN_OFFICE ? undefined : p_max"
      hide-header
      show-adjacent-months
      :allowed-dates="
        p_privilege === Privilege.IN_OFFICE ? null : p_allowedDates
      "
    ></w-date-picker>
  </w-menu>
</template>

<script lang="ts">
import DateHelper from "@/helpers/DateHelper";
import { Privilege } from "@winnove/vue-wlib/enums";
import { mdiCalendar } from "@mdi/js";
import { DateTime } from "luxon";
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "DatePicker",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      default: Privilege.FREEMIUM,
    },
    p_date: {
      type: String,
      default: "",
    },
    p_label: {
      type: String,
      default: "",
    },
    notempty: {
      type: Boolean,
      default: false,
    },
    p_min: {
      type: String,
      default: "",
    },
    p_max: {
      type: String,
      default: "",
    },
    hidedetails: {
      type: Boolean,
      default: false,
    },
    p_allowedDates: {
      type: Function,
      default: undefined,
    },
  },
  setup(props, context) {
    const m_date = ref(props.p_date);
    const m_datePicker = ref(DateTime.now());
    const m_menu = ref(false);

    onMounted(() => {
      m_date.value = props.p_date;
      if (m_date.value != null) m_date.value = m_date.value.slice(0, 10); // we have to keep only the day part or it won't show
    });

    function getNextAvailableDate(): string | null {
      return DateHelper.parseDate(DateHelper.formatDate(props.p_min));
    }

    function getLastAvailableDate(): string | null {
      return DateHelper.parseDate(DateHelper.formatDate(props.p_max));
    }

    watch(m_date, () => {
      onDateChange();
    });

    function onDateChange() {
      if (m_date.value) {
        m_datePicker.value = DateTime.fromISO(m_date.value);
        const date = DateTime.fromISO(m_date.value).toUTC().toISO();
        context.emit("change", date);
      } else {
        context.emit("change", null);
      }
    }

    function pickDate(value: DateTime) {
      const date = value.toFormat("yyyy-MM-dd");
      m_menu.value = false;
      m_date.value = date;
    }

    return {
      Privilege,
      m_date,
      m_datePicker,
      m_menu,
      getNextAvailableDate,
      getLastAvailableDate,
      pickDate,
      onDateChange,
      mdiCalendar,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-date-picker {
  :deep(.v-date-picker-month__day button:not(.v-btn--disabled)) {
    color: white !important;
  }

  :deep(.v-date-picker-month__day--selected button) {
    background-color: rgb(var(--v-theme-wBlue)) !important;
    border: 1px solid !important;
  }
}

.v-input__slot:has(input[type="date"]) {
  .v-input__append-inner {
    pointer-events: none;
    position: absolute;
    right: 0;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
