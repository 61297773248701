<template>
  <div class="d-flex mt-1">
    <w-icon
      :icon="p_checked ? mdiCheckCircle : mdiCloseCircle"
      :color="'primary'"
      size="24"
      :disabled="p_orderCancelled"
      class=""
    ></w-icon>
    <div class="ml-4 mr-2">
      <p class="ma-0 text-uppercase text-caption font-weight-bold">
        {{ p_label }}
      </p>
      <p
        v-bind:class="{
          'ma-0 text-subtitle-2 font-weight-bold': true,
          'text-cancelled': p_orderCancelled,
          'text-not-cancelled': !p_orderCancelled,
        }"
      >
        {{ p_value }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { mdiCheckCircle, mdiCloseCircle } from "@mdi/js";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WireSummaryOrderDetail",
  props: {
    p_orderCancelled: {
      type: Boolean,
      required: true,
    },
    p_checked: {
      type: Boolean,
      default: true,
    },
    p_label: {
      type: String,
      required: true,
    },
    p_value: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    return { mdiCheckCircle, mdiCloseCircle };
  },
});
</script>

<style scoped lang="scss">
.text-cancelled {
  color: rgba(var(--v-theme-wBlue), 0.5);
}

.text-not-cancelled {
  color: rgb(var(--v-theme-wBlue));
}

.img-cancelled {
  filter: grayscale(100%);
}
</style>
