<template>
  <w-navigation-drawer
    v-model="m_visible"
    class="background_project_info_drawer"
    :permanent="true"
  >
    <div
      class="absolute"
      style="width: inherit; height: inherit"
    >
      <div
        class="d-flex flex-column"
        style="position: fixed; width: 100%; height: 100%"
      >
        <div
          class="flex-grow-1 overflow-y-auto"
          style="height: 100%"
        >
          <!-- Infos projet -->
          <div class="project-info-div pa-0 pt-6">
            <div
              v-if="p_project.reference"
              class="ma-0 pl-6"
            >
              <p>
                <span class="ma-0 summary-label"> RÉFÉRENCE PROJET </span>
                <span class="ma-0 ml-1 summary-value">
                  {{ p_project.reference }}
                </span>
              </p>
            </div>
            <div class="ma-0 pl-6">
              <p>
                <span class="ma-0 summary-label"> PATIENT </span>
                <span class="ma-0 ml-1 summary-value">
                  {{ p_project.patient.lastName }}
                  {{ p_project.patient.firstName }}
                </span>
              </p>
            </div>
            <div
              v-if="p_privilege === Privilege.ADMIN"
              class="ma-0 pl-6"
            >
              <p>
                <span class="ma-0 summary-label"> PRATICIEN </span>
                <span class="ma-0 ml-1 summary-value">
                  Dr. {{ p_project.patient.user.lastName }}
                </span>
              </p>
            </div>
          </div>

          <!-- Maxillaire -->
          <WireConfig
            data-cy="wire-config-maxilla"
            v-if="m_wireMaxilla"
            :p_wire="m_wireMaxilla"
            @update:p_wire="m_wireMaxilla = $event"
            :p_order="m_orderMaxilla"
            @update:p_order="m_orderMaxilla = $event"
            :p_privilege="p_privilege"
            :p_arcade="Arcade.MAXILLA"
            :p_isCancelled="isOrderCancelled(Arcade.MAXILLA)"
            :p_rotationToolActive="p_rotationToolActive"
            @save-order="saveOrder"
            @change-file="changeFile()"
            @prescriptionAsDefault="m_prefsMaxiAreDefault = $event"
          />

          <!-- Mandibulaire -->
          <WireConfig
            data-cy="wire-config-mandible"
            v-if="m_wireMandible"
            :p_wire="m_wireMandible"
            @update:p_wire="m_wireMandible = $event"
            :p_order="m_orderMandible"
            @update:p_order="m_orderMandible = $event"
            :p_privilege="p_privilege"
            :p_arcade="Arcade.MANDIBLE"
            :p_isCancelled="isOrderCancelled(Arcade.MANDIBLE)"
            :p_rotationToolActive="p_rotationToolActive"
            @save-order="saveOrder"
            @change-file="changeFile()"
            @prescriptionAsDefault="m_prefsMandiAreDefault = $event"
          />
        </div>

        <w-divider class="mx-6"></w-divider>

        <div class="py-4 px-6 mt-0">
          <!-- Envoi des fichiers -->
          <w-card
            v-if="p_filesUploading || p_filesUploadError"
            data-cy="upload-files-card"
            class="ma-1 mt-0"
            elevation="0"
            title="Envoi des fichiers..."
          >
            <w-card-text v-if="p_filesUploading">
              <span>{{ p_uploadText }}</span>
              <w-progress-linear
                :modelValue="p_uploadProgress"
                color="white"
                height="25"
                rounded
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </w-progress-linear>
            </w-card-text>
            <!-- Error while sending files -->
            <w-card-text v-if="p_filesUploadError">
              <w-alert type="error">
                Erreur Lors de l'envoi des fichiers STL.
              </w-alert>
              <w-btn
                class="mt-2"
                @click="$emit('retryFilesUpload')"
                block
                color="white"
                variant="flat"
                >Réessayer</w-btn
              >
            </w-card-text>
          </w-card>
          <!-- Check orientation -->
          <w-card
            data-cy="check-orientation-card"
            v-if="p_orientationCheck"
            class="pa-0 pt-0 ma-1 mt-0"
            elevation="0"
            title="Vérification des fichiers..."
          >
            <w-card-text class="text-center">
              <span>Calcul de la meilleure orientation</span>
              <w-progress-linear
                indeterminate
                color="white"
                height="25"
                rounded
                class="mt-1"
              >
              </w-progress-linear>
            </w-card-text>
          </w-card>

          <!-- enregistrement en cookies des paramètres de la prescription -->
          <w-checkbox
            v-if="p_project.hasToCompletePrescription()"
            v-model="m_savePreferences"
            color="primary"
            class="text-primary"
            @click="deletePreferences()"
            label="Enregistrer ces paramètres de prescription pour la prochaine fois."
          ></w-checkbox>

          <!-- Tracer le fil -->
          <w-btn
            v-if="
              p_project.canDrawWire(p_privilege) &&
              p_privilege !== Privilege.IN_OFFICE
            "
            @click="confirmPrescriptionAndDrawWire()"
            class="d-none d-md-block mt-2"
            block
            :ripple="false"
            :variant="p_project.hasToDrawWire(p_privilege) ? 'flat' : 'tonal'"
            :disabled="
              p_filesUploading ||
              p_filesUploadError ||
              p_rotationToolActive ||
              p_orientationCheck
            "
            :icon-right="mdiRayStartEnd"
          >
            <div v-if="p_project.hasToDrawWire(p_privilege)">
              Tracer les fils
            </div>
            <div v-else>Modifier les fils</div>
          </w-btn>

          <!-- Commander les fils -->
          <w-btn
            data-cy="place-order-button"
            @click="confirmPrescription()"
            class="d-none d-md-block mt-2"
            block
            :variant="
              p_project.hasToDrawWire(p_privilege) ? 'tonal' : 'elevated'
            "
            :disabled="
              p_filesUploading ||
              p_filesUploadError ||
              p_rotationToolActive ||
              p_orientationCheck
            "
          >
            <div
              v-if="
                (p_project.hasToCompletePrescription() &&
                  p_privilege != Privilege.IN_OFFICE) ||
                (p_privilege === Privilege.PREMIUM &&
                  p_project.hasToDrawAllWire(p_privilege))
              "
            >
              Commander les fils
              <w-icon
                style="position: absolute; right: 12px"
                :icon="mdiCartOutline"
              >
              </w-icon>
            </div>
            <div v-else>
              <w-icon
                style="position: absolute; left: 12px"
                :icon="mdiCheck"
              ></w-icon>
              Confirmer la prescription
            </div>
          </w-btn>
          <AddressDialog
            ref="addressDialog"
            :p_user="p_project.patient?.user"
            @AddressCompleted="m_isAddressComplete = true"
          />
        </div>
      </div>
    </div>
  </w-navigation-drawer>
</template>

<script lang="ts">
import { Constants } from "@/Constants";
import AddressDialog from "@/components/main/dialogs/AddressDialog.vue";
import Order from "@/models/Order";
import Project from "@/models/Project";
import Wire from "@/models/Wire";
import { Arcade, BLUE_MEDIUM, Privilege } from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import { mdiCartOutline, mdiCheck, mdiRayStartEnd } from "@mdi/js";
import Cookies from "js-cookie";
import { defineComponent, ref, watch } from "vue";
import WireConfig from "./shared/WireConfig.vue";

export default defineComponent({
  name: "WireConfigDrawer",
  components: {
    WireConfig,
    AddressDialog,
  },
  props: {
    p_project: {
      type: Object as () => Project,
      required: true,
    },
    p_wireMaxilla: {
      type: Object as () => Wire | null,
      default: null,
    },
    p_wireMandible: {
      type: Object as () => Wire | null,
      default: null,
    },
    p_privilege: {
      type: Number as () => Privilege,
      default: Privilege.FREEMIUM,
    },
    p_filesUploading: {
      type: Boolean,
      default: false,
    },
    p_filesUploadError: {
      type: Boolean,
      default: false,
    },
    p_uploadProgress: {
      type: Number,
      default: 0,
    },
    p_orientationCheck: {
      type: Boolean,
      default: false,
    },
    p_uploadText: {
      type: String,
      default: "",
    },
    p_rotationToolActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const m_visible = ref(false);
    const commentInput = ref(null);
    const m_wireMaxilla = ref(props.p_wireMaxilla);
    const m_wireMandible = ref(props.p_wireMandible);
    const m_orderMaxilla = ref(props.p_project.orderMaxilla());
    const m_orderMandible = ref(props.p_project.orderMandible());
    const m_tab = ref("");
    const logger = Logger.getInstance();
    const m_savePreferences = ref(false);
    const m_prefsMandiAreDefault = ref(false);
    const m_prefsMaxiAreDefault = ref(false);

    const addressDialog = ref<InstanceType<typeof AddressDialog> | null>(null);
    const m_isAddressComplete = ref(
      props.p_project.patient?.user?.isAddressComplete()
    );

    function setVisible(p_value: boolean): void {
      m_tab.value = "";
      m_visible.value = p_value;
    }

    function show(): void {
      setVisible(true);
    }

    function hide(): void {
      setVisible(false);
    }

    function confirmPrescriptionAndDrawWire(): void {
      confirmPrescription(false);
    }

    function confirmPrescription(placeOrder = true): boolean {
      if (m_isAddressComplete.value) {
        // Check mandible teeth range
        if (
          !isOrderCancelled(Arcade.MANDIBLE) &&
          !_checkTeethRange(m_wireMandible.value as Wire | null)
        ) {
          logger.error(
            "Veuillez renseigner la numérotation pour la mâchoire inférieure."
          );
          return false;
        }
        // Check maxilla teeth range
        if (
          !isOrderCancelled(Arcade.MAXILLA) &&
          !_checkTeethRange(m_wireMaxilla.value as Wire | null)
        ) {
          logger.error(
            "Veuillez renseigner la numérotation pour la mâchoire supérieure."
          );
          return false;
        }

        // Save preferences in cookies if needed
        if (
          props.p_project.hasToCompletePrescription() &&
          m_savePreferences.value
        ) {
          if (m_wireMaxilla.value)
            Cookies.set(
              "prescriptionPreferencesMaxilla",
              JSON.stringify(m_wireMaxilla.value),
              { expires: 365 }
            );

          if (m_wireMandible.value)
            Cookies.set(
              "prescriptionPreferencesMandible",
              JSON.stringify(m_wireMandible.value),
              { expires: 365 }
            );
        }

        context.emit(
          "confirmPrescription",
          m_wireMaxilla.value,
          m_wireMandible.value,
          m_orderMaxilla.value,
          m_orderMandible.value,
          placeOrder
        );
        return true;
      } else {
        addressDialog.value?.show();
        return false;
      }
    }

    function _checkTeethRange(p_wire: Wire | null): boolean {
      let ret = true;
      if (p_wire) {
        const teethRanges = p_wire.teethRange.split("-");
        teethRanges.forEach((teethRange) => {
          if (teethRange.length < 2) ret = false;
        });
      }
      return ret;
    }

    function assemble(): void {
      context.emit("assemble", Constants.DEFAULT_ALGO);
    }

    function changeFile(): void {
      context.emit("changeFile");
    }

    function saveOrder(p_order: Order): void {
      context.emit("saveOrder", p_order);
    }

    function isOrderCancelled(p_arcade: Arcade): boolean {
      if (p_arcade === Arcade.MANDIBLE) {
        if (m_orderMandible.value) {
          return m_orderMandible.value.isCancelled();
        }
      } else if (m_orderMaxilla.value) {
        return m_orderMaxilla.value.isCancelled();
      }

      return false;
    }

    function deletePreferences(): void {
      if (m_savePreferences.value === false) {
        Cookies.remove("prescriptionPreferencesMandible");
        Cookies.remove("prescriptionPreferencesMaxilla");
      }
    }

    watch([m_prefsMandiAreDefault, m_prefsMaxiAreDefault], () => {
      m_savePreferences.value = // true if ordered wires prescriptions are as saved preferences
        (!m_orderMaxilla.value ||
          (!!m_orderMaxilla.value && m_prefsMaxiAreDefault.value)) &&
        (!m_orderMandible.value ||
          (!!m_orderMandible.value && m_prefsMandiAreDefault.value));
    });

    return {
      Privilege,
      Arcade,
      BLUE_MEDIUM,
      commentInput,
      m_visible,
      m_wireMaxilla,
      m_wireMandible,
      m_orderMaxilla,
      m_orderMandible,
      m_tab,
      m_savePreferences,
      m_prefsMandiAreDefault,
      m_prefsMaxiAreDefault,
      addressDialog,
      m_isAddressComplete,
      setVisible,
      show,
      hide,
      confirmPrescription,
      assemble,
      changeFile,
      saveOrder,
      isOrderCancelled,
      confirmPrescriptionAndDrawWire,
      deletePreferences,
      mdiRayStartEnd,
      mdiCartOutline,
      mdiCheck,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-btn::before {
  background-color: transparent;
}

.border-botom {
  border-bottom: 2px solid #d8dfea !important;
}

.border-top {
  border-top: 2px solid #d8dfea !important;
}

.v-card {
  border-radius: 5px;
}
</style>
