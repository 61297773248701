<template>
  <div>
    <w-hover v-slot="{ isHovering, props }">
      <w-btn
        :class="p_wrapContent ? 'editableValueBtnWrapContent' : ''"
        v-bind="props"
        variant="plain"
        @click="$emit('open')"
      >
        <w-badge
          color="transparent"
          floating
          :icon="isHovering ? mdiPencil : ''"
        >
          <span>{{ p_data }}</span>
          <slot name="data"></slot>
        </w-badge>
        <w-menu
          offset-y
          :close-on-content-click="false"
          transition="slide-y-transition"
          activator="parent"
          max-width="300px"
          min-width="100px"
          v-model="m_menu"
          persistent
        >
          <w-card class="px-4 pt-4">
            <slot></slot>

            <w-card-actions class="justify-end">
              <w-btn
                color="white"
                @click="cancel"
                variant="tonal"
                >Annuler</w-btn
              >
              <w-btn
                color="white"
                variant="flat"
                @click="save"
                >Sauvegarder</w-btn
              >
            </w-card-actions>
          </w-card>
        </w-menu>
      </w-btn>
    </w-hover>
  </div>
</template>

<script lang="ts">
import { mdiPencil } from "@mdi/js";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "EditableDataTableMenu",
  props: {
    p_data: {
      type: String,
      default: "",
    },
    p_wrapContent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const m_menu = ref(false);

    function cancel(): void {
      m_menu.value = false;
      context.emit("cancel");
    }

    function save(): void {
      m_menu.value = false;
      context.emit("save");
    }

    return {
      m_menu,
      open,
      cancel,
      save,
      mdiPencil,
    };
  },
});
</script>

<style lang="scss" scoped>
.editableValueBtnWrapContent :deep(.v-btn__content) {
  white-space: pre-wrap !important;
}

.editableValueBtnWrapContent {
  height: auto !important;
}
</style>
