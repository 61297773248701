<template>
  <EditableDataTableMenu
    @save="save"
    @cancel="cancel"
  >
    <template #data>
      <span>{{ m_address.address }}</span
      ><br />
      <div v-if="m_address.address2">
        <span>{{ m_address.address2 }}</span
        ><br />
      </div>
      <span>{{ m_address.postalCode }} {{ m_address.city }}</span
      ><br />
      <span>{{ m_address.country }}</span>
    </template>

    <template #default>
      <AddressTextField
        v-model:p_address="m_address.address"
        p_label="Adresse Principale"
      />
      <AddressTextField
        v-model:p_address="m_address.address2"
        p_label="Adresse suite"
      />
      <w-row>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_address.postalCode"
            label="Code postal"
            type="number"
            onkeydown="return event.keyCode !== 69"
          ></w-text-field>
        </w-col>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_address.city"
            label="Ville"
          ></w-text-field>
        </w-col>
      </w-row>
      <w-text-field
        v-model.trim="m_address.country"
        label="Pays"
        required
      ></w-text-field>
    </template>
  </EditableDataTableMenu>
</template>

<script lang="ts">
import AddressTextField from "@/components/shared/AddressTextField.vue";
import Address from "@/models/Address";
import { computed, defineComponent, onMounted, ref } from "vue";
import EditableDataTableMenu from "./EditableDataTableMenu.vue";

export default defineComponent({
  name: "EditableUserAddress",
  components: { AddressTextField, EditableDataTableMenu },
  inheritAttrs: false,
  props: {
    p_address: {
      type: Object as () => Address,
      required: true,
    },
  },
  setup(props, context) {
    const m_address = computed({
      get: () => props.p_address,
      set: (value: Address) => context.emit("update:p_address", value),
    });
    const m_dataSave = ref(new Address());

    function backupAddress(): void {
      m_dataSave.value.address = m_address.value.address;
      m_dataSave.value.address2 = m_address.value.address2;
      m_dataSave.value.city = m_address.value.city;
      m_dataSave.value.postalCode = m_address.value.postalCode;
      m_dataSave.value.country = m_address.value.country;
    }

    function restoreAddress(): void {
      m_address.value.address = m_dataSave.value.address;
      m_address.value.address2 = m_dataSave.value.address2;
      m_address.value.city = m_dataSave.value.city;
      m_address.value.postalCode = m_dataSave.value.postalCode;
      m_address.value.country = m_dataSave.value.country;
    }

    onMounted(() => {
      backupAddress();
    });

    function cancel(): void {
      restoreAddress();
    }

    function save(): void {
      backupAddress();
      context.emit("save");
    }

    return {
      m_address,
      save,
      cancel,
    };
  },
});
</script>
