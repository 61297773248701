import Cookies from "js-cookie";

export default class CookieHelper {
  static setCookie(cookies: string, options: any): void {
    // store options in cookies, expires in 24h
    Cookies.set(cookies, JSON.stringify(options), {
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });
  }

  static getCookie(cookie: string): string | undefined {
    return Cookies.get(cookie);
  }
}
