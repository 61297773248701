<template>
  <w-container
    key="ContainerDashboard"
    fluid
    class="background_dashboard pa-0"
    style="min-height: 100%"
  >
    <div
      class="d-flex"
      v-if="m_loaded"
    >
      <ProdProjectTable
        ref="prodProjectTable"
        :p_privilege="m_privilege"
        :p_isTaskList="p_isTaskList"
      />
    </div>
  </w-container>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import { Privilege } from "@winnove/vue-wlib/enums";
import { defineComponent, onMounted, ref } from "vue";
import ProdProjectTable from "./tables/ProdProjectTable.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    ProdProjectTable,
  },
  props: {
    p_route: {
      type: String,
      required: true,
    },
    p_isTaskList: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const prodProjectTable = ref<InstanceType<typeof ProdProjectTable> | null>(
      null
    );

    const m_privilege = ref(Privilege.FREEMIUM);

    const m_loaded = ref(false);

    onMounted(() => {
      m_privilege.value = AuthHelper.getLoggedUser().privilege;

      m_loaded.value = true;
    });

    function refreshProjectTable(): void {
      prodProjectTable.value?.refreshProjectList();
    }

    return {
      Privilege,
      m_privilege,
      m_loaded,
      refreshProjectTable,
      prodProjectTable,
    };
  },
});
</script>
