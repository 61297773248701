/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import LuxonAdapter from "@date-io/luxon";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import { fr } from "vuetify/locale";
import "vuetify/styles";

// Composables
import { ThemeDefinition, createVuetify } from "vuetify";

const winnoveTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: "#00144A",
    "primary-darken-1": "#000526",
    secondary: "#C0B4FE",
    "secondary-darken-1": "#99E3E2",
    surface: "#00144A",
    "surface-light": "#4F49E9",
    background: "#f6f9fd",

    "primary-appBar": "#00144A",
    "secondary-appBar": "#FFFFFF",
    success: "#00144A",
    info: "#00144A",
    warning: "#4F49E9",
    error: "#4F49E9",
    wOrange: "#FF8E62",
    wBlack: "#000526",
    wPink: "#FF9CFF",
    wLavander: "#C0B4FE",
    wWhite: "#F7FFFF",
    wViolet: "#8045F2",
    wBlue: "#4F49E9",
    wDeepBlue: "#00144A",
    wLemonGreen: "#D7FFC2",
    wMintGreen: "#99E3E2",
    wGrey: "#EBF0FF",
  },
};

const luxon = new LuxonAdapter({ locale: "fr" });

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  locale: {
    locale: "fr",
    fallback: "fr",
    messages: {
      fr,
    },
  },
  date: {
    adapter: luxon,
  },
  theme: {
    defaultTheme: "winnoveTheme",
    themes: {
      winnoveTheme,
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});
