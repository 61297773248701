<template>
  <w-container
    fluid
    v-if="m_mounted"
  >
    <w-data-table-server
      class="table_pack text-primary"
      :headers="m_headers"
      :items="getRemainingBillings()"
      :search="m_search"
      item-key="id"
      :items-per-page="m_options.itemsPerPage"
      :items-length="m_totalCount"
      :page="m_options.page"
      :sortBy="m_options.sortBy"
      v-model:expanded="m_expanded"
      show-expand
      expand-on-click
      @update:expanded="expandFunction"
      :loading="m_isLoading"
      @update:options="optionsChanged"
    >
      <template v-slot:top>
        <w-text-field
          v-model="m_search"
          label="Rechercher"
          class="mx-4 pt-4"
          :prepend-inner-icon="mdiMagnify"
        ></w-text-field>
      </template>
      <template #[`item.lastName`]="{ item }">
        <span class="text-subtitle-2 text-primary"
          >Dr. {{ item.lastName }}</span
        >
      </template>
      <template v-slot:expanded-row="{ item }">
        <td
          colspan="3"
          class="pa-4"
        >
          <w-row>
            <!-- Historique des consommations -->
            <w-col>
              <w-data-table-server
                class="sub_table_pack text-primary"
                :headers="m_historyTableHeaders"
                :items="getUserHistory(item.userId)"
                item-key="id"
                loading-text="Chargement de l'historique en cours..."
                no-data-text="Aucun historique"
                no-results-text="Aucun historique"
                :items-per-page="m_optionsHistory.itemsPerPage"
                :items-length="m_totalCountHistoryConsumption"
                :page="m_optionsHistory.page"
                :sortBy="m_optionsHistory.sortBy"
                density="compact"
                @update:options="optionsHistoryChanged(item.userId, $event)"
              >
                <template v-slot:top>
                  <h3>Historique des consommations</h3>
                </template>
                <template #[`item.date`]="{ item }">
                  <span>{{ DateHelper.formatDate(item.date) }}</span>
                </template>
                <template #[`item.comment`]="{ item }">
                  <span>{{ formatComment(item.comment) }}</span>
                </template>
              </w-data-table-server>
            </w-col>

            <!-- Historique des facturations -->
            <w-col class="mt-5">
              <w-data-table-server
                class="sub_table_pack text-primary"
                :headers="m_billsHistoryTableHeaders"
                :items="getUserBillsHistory(item.userId)"
                loading-text="Chargement de l'historique en cours..."
                item-key="id"
                no-data-text="Aucun historique"
                no-results-text="Aucun historique"
                :items-per-page="m_optionsBill.itemsPerPage"
                :items-length="m_totalCountHistoryInvoice"
                :page="m_optionsBill.page"
                :sortBy="m_optionsBill.sortBy"
                density="compact"
                @update:options="optionsBillChanged(item.userId, $event)"
              >
                <template v-slot:top>
                  <w-row>
                    <h3>Historique des facturations</h3>
                    <w-spacer></w-spacer>
                    <w-btn
                      rounded
                      class="mr-5 mb-5"
                      @click="openNewBillingDialog(item.userId)"
                    >
                      + Ajouter
                    </w-btn>
                  </w-row>
                </template>
                <template #[`item.date`]="{ item }">
                  <span>{{ DateHelper.formatDate(item.date) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <w-icon
                    small
                    @click="deleteBilling(item)"
                    :icon="mdiDelete"
                  >
                  </w-icon>
                </template>
                <template v-slot:item.invoiced="{ item }">
                  <w-switch
                    :modelValue="!!item.invoiced"
                    hide-details
                    color="primary"
                    density="compact"
                    @change="updateBillingInvoicedValue(item)"
                    @update:modelValue="item.invoiced = $event"
                  ></w-switch>
                </template>

                <template v-slot:item.paid="{ item }">
                  <w-switch
                    :modelValue="!!item.paid"
                    hide-details
                    color="primary"
                    density="compact"
                    @change="updateBillingPaidValue(item)"
                    @update:modelValue="item.paid = $event"
                  ></w-switch>
                </template>
              </w-data-table-server>
            </w-col>
          </w-row>
        </td>
      </template>
    </w-data-table-server>

    <!-- New billing dialog -->
    <BaseDialog
      ref="newBillingDialog"
      p_title="Ajouter une facturation"
      max-width="600px"
      p_noPadding
    >
      <w-form
        ref="formNewBilling"
        class="mt-4"
        v-model="m_isNewPackFormValid"
        @submit.prevent="addBilling()"
        nowrap
      >
        <w-card-text>
          <!-- Praticien  -->
          <w-row>
            <w-col>
              <w-autocomplete
                v-model="m_newBilling.userId"
                :items="m_users"
                :item-title="(itm) => 'Dr.' + itm.lastName"
                :item-value="(itm) => itm.id"
                :rules="[(v) => (v && v > -1) || 'Praticien requis']"
                no-data-text="Aucun praticien"
                label="Rechercher un praticien"
              >
              </w-autocomplete>
            </w-col>
          </w-row>

          <!-- Date de facturation  -->
          <w-row>
            <w-col>
              <DatePicker
                @change="changenewBillingDate"
                :p_privilege="Privilege.ADMIN"
                :p_date="m_newBilling.date"
                :p_label="'Date de facturation'"
                :notempty="true"
                required
              />
            </w-col>
          </w-row>

          <!-- Pack buttons -->
          <w-row class="my-4 justify-space-around">
            <w-btn
              variant="outlined"
              rounded
              @click="setNewBillingPack(25)"
              class="mx-1"
              >Pack 25</w-btn
            >
            <w-btn
              variant="outlined"
              rounded
              @click="setNewBillingPack(50)"
              class="mx-1"
              >Pack 50</w-btn
            >
            <w-btn
              variant="outlined"
              rounded
              @click="setNewBillingPack(100)"
              class="mx-1"
              >Pack 100</w-btn
            >
            <w-btn
              variant="outlined"
              rounded
              @click="setNewBillingPack(200)"
              class="mx-1"
              >Pack 200</w-btn
            >
          </w-row>

          <w-row class="">
            <!-- Commentaire  -->
            <w-col>
              <w-text-field
                v-model="m_newBilling.comment"
                label="Commentaire"
                :rules="[(v) => (v && !!v.trim()) || 'Commentaire requis']"
                required
              ></w-text-field>
            </w-col>
            <!-- Jetons -->
            <w-col>
              <w-text-field
                v-model="m_newBilling.credits"
                label="Jetons"
                :rules="[(v) => !!v || 'Jetons requis']"
                required
                type="number"
                onkeydown="return event.keyCode !== 69"
              ></w-text-field>
            </w-col>
          </w-row>
        </w-card-text>

        <w-card-actions class="justify-end">
          <w-btn
            color="primary"
            variant="tonal"
            @click="cancelAddBilling()"
          >
            Annuler
          </w-btn>
          <w-btn
            :disabled="!m_isNewPackFormValid"
            type="submit"
            variant="elevated"
            >Ajouter
          </w-btn>
        </w-card-actions>
      </w-form>
    </BaseDialog>
  </w-container>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import BillingHelper from "@/helpers/BillingHelper";
import CookieHelper from "@/helpers/CookieHelper";
import DateHelper from "@/helpers/DateHelper";
import { EnumHelper, EnumSelectEntry } from "@/helpers/EnumHelper";
import UserHelper from "@/helpers/UserHelper";
import { DataTableOptions } from "@/helpers/WinnoveHelper";
import Billing from "@/models/Billing";
import User from "@/models/User";
import { Privilege, PrivilegeString } from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import { mdiDelete, mdiMagnify } from "@mdi/js";
import { useRepo } from "pinia-orm";
import { defineComponent, onMounted, reactive, ref } from "vue";

const DEFAULT_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [{ key: "lastName", order: "asc" }],
  groupBy: [],
  search: "",
};

const DEFAULT_OPTIONS_BILLS: DataTableOptions = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [
    { key: "date", order: "desc" },
    { key: "cumulativeCredits", order: "asc" },
  ],
  groupBy: [],
  search: "",
};

const m_headers = [
  {
    title: "Docteur",
    align: "start",
    value: "lastName",
    sortable: true,
    filterable: true,
  },
  {
    title: "Jetons restants",
    align: "start",
    value: "credits",
    sortable: true,
    filterable: false,
  },
];

const m_historyTableHeaders = [
  {
    title: "Date",
    align: "start",
    value: "date",
    sortable: true,
    filterable: true,
  },
  {
    title: "Détails",
    align: "start",
    value: "comment",
    sortable: false,
    filterable: false,
  },
  {
    title: "Évolution jetons",
    align: "start",
    value: "credits",
    sortable: false,
    filterable: false,
  },
  {
    title: "# jetons à date",
    align: "start",
    value: "cumulativeCredits",
    sortable: true,
    filterable: false,
  },
];

const m_billsHistoryTableHeaders = [
  {
    title: "Date",
    align: "start",
    value: "date",
    sortable: true,
    filterable: true,
  },
  {
    title: "Détails",
    align: "start",
    value: "comment",
    sortable: true,
    filterable: false,
  },
  {
    title: "Évolution jetons",
    align: "start",
    value: "credits",
    sortable: true,
    filterable: false,
  },
  {
    title: "Actions",
    value: "actions",
    sortable: false,
    filterable: false,
  },
  {
    title: "Facturé",
    value: "invoiced",
    sortable: true,
    filterable: false,
  },
  {
    title: "Payé",
    value: "paid",
    sortable: true,
    filterable: false,
  },
];

export default defineComponent({
  name: "BillingTable",
  components: {
    DatePicker,
    BaseDialog,
  },
  props: {},
  setup(props, context) {
    const formNewBilling = ref<InstanceType<typeof HTMLFormElement> | null>(
      null
    );

    const m_panel = ref(-1);
    const m_users = ref<User[]>([]);
    const m_billings = reactive<Billing[]>([]);
    const m_billingsHistoryConsumption = reactive<Billing[]>([]);
    const m_billingsHistoryInvoice = reactive<Billing[]>([]);
    const m_isNewPackFormValid = ref(true);
    const m_newBilling = ref(new Billing());
    const m_privileges = ref<EnumSelectEntry[]>([]);
    const m_expanded = ref([]);
    const m_isLoading = ref(false);
    const newBillingDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    const m_mounted = ref(false);

    const m_totalCount = ref(0);
    const m_totalCountHistoryConsumption = ref(0);
    const m_totalCountHistoryInvoice = ref(0);

    const m_options = ref<DataTableOptions>(
      CookieHelper.getCookie("AdminBillingDashboardOptionsV3")
        ? JSON.parse(CookieHelper.getCookie("AdminBillingDashboardOptionsV3")!)
        : DEFAULT_OPTIONS
    );
    const m_optionsHistory = ref<DataTableOptions>(
      CookieHelper.getCookie("AdminBillingHistoryDashboardOptionsV3")
        ? JSON.parse(
            CookieHelper.getCookie("AdminBillingHistoryDashboardOptionsV3")!
          )
        : DEFAULT_OPTIONS_BILLS
    );
    const m_optionsBill = ref<DataTableOptions>(
      CookieHelper.getCookie("AdminBillingBillDashboardOptionsV3")
        ? JSON.parse(
            CookieHelper.getCookie("AdminBillingBillDashboardOptionsV3")!
          )
        : DEFAULT_OPTIONS_BILLS
    );

    const m_search = ref<string>(m_options.value.search);

    onMounted(async () => {
      m_mounted.value = false;
      m_privileges.value = EnumHelper.getSelectListAsArray(
        Privilege,
        PrivilegeString
      );
      m_users.value = useRepo(User).all();
      if (m_users.value.length === 0) {
        await UserHelper.fetchUsers();
        m_users.value = useRepo(User).all();
      }
      await refresh();
      m_mounted.value = true;
    });

    async function refresh(): Promise<void> {
      m_isLoading.value = true;
      // Get billing summary
      await BillingHelper.getRemainingTokens(undefined, m_options.value).then(
        (results: any) => {
          m_totalCount.value = results.count;
          m_billings.splice(0, m_billings.length, ...results.remaining);
        }
      );
      m_isLoading.value = false;
    }

    async function refreshHistory(userId: number): Promise<void> {
      m_isLoading.value = true;
      await BillingHelper.getBillingHistory(
        userId,
        m_optionsHistory.value
      ).then((results: { billings: Billing[]; count: number }) => {
        m_totalCountHistoryConsumption.value = results.count;
        m_billingsHistoryConsumption.splice(
          0,
          m_billingsHistoryConsumption.length,
          ...results.billings
        );
      });
      m_isLoading.value = false;
    }

    async function refreshBill(userId: number): Promise<void> {
      m_isLoading.value = true;
      await BillingHelper.getBillingHistoryInvoice(
        userId,
        m_optionsBill.value
      ).then((results: { billings: Billing[]; count: number }) => {
        m_totalCountHistoryInvoice.value = results.count;
        m_billingsHistoryInvoice.splice(
          0,
          m_billingsHistoryInvoice.length,
          ...results.billings
        );
      });
      m_isLoading.value = false;
    }

    function getRemainingBillings(): Billing[] {
      return m_billings as Billing[];
    }

    function getUserHistory(p_userId: number): Billing[] {
      const filteredBillings = (
        m_billingsHistoryConsumption as Billing[]
      ).filter((billing: Billing) => {
        if (billing.userId) return billing.userId === p_userId;
        else return false;
      });
      return filteredBillings;
    }

    function getUserBillsHistory(p_userId: number): Billing[] {
      const filteredBillings = (m_billingsHistoryInvoice as Billing[]).filter(
        (billing: Billing) => {
          // only keep bills
          if (billing.orderId) return false;

          if (billing.userId) return billing.userId === p_userId;
          else return false;
        }
      );
      return filteredBillings;
    }

    function cancelAddBilling(): void {
      newBillingDialog.value?.close();
    }

    async function addBilling(): Promise<void> {
      await BillingHelper.addBilling(m_newBilling.value as Billing).then(
        (response) => {
          Logger.getInstance().success("Facturation ajoutée avec succès");
          formNewBilling.value?.reset();
          m_newBilling.value = new Billing();
          newBillingDialog.value?.close();
          refresh();
          refreshBill(response.userId as number);
          refreshHistory(response.userId as number);
        }
      );
    }

    function changenewBillingDate(p_date: string): void {
      m_newBilling.value.date = p_date;
    }

    function setNewBillingPack(p_nb: number): void {
      m_newBilling.value.comment = "Pack de " + p_nb + " jetons";
      m_newBilling.value.credits = p_nb;
    }

    async function deleteBilling(p_billing: Billing): Promise<void> {
      await BillingHelper.deleteBilling(p_billing).then(() => {
        Logger.getInstance().success("Facturation supprimée avec succès");
        refresh();
        refreshBill(p_billing.userId as number);
        refreshHistory(p_billing.userId as number);
      });
    }

    async function updateBillingPaidValue(item: any) {
      await BillingHelper.updateBilling(item);
    }

    async function updateBillingInvoicedValue(item: any) {
      await BillingHelper.updateBilling(item);
    }

    function openNewBillingDialog(p_userId: number): void {
      m_newBilling.value.userId = p_userId;
      newBillingDialog.value?.show();
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      _onOptionsChanged();
    }

    async function _onOptionsChanged(): Promise<void> {
      CookieHelper.setCookie("AdminBillingDashboardOptionsV3", m_options.value);

      if (m_options.value.search === null) m_options.value.search = "";
      refresh();
    }

    function optionsBillChanged(
      userId: number,
      options: DataTableOptions
    ): void {
      m_optionsBill.value = options;
      _onOptionsBillChanged();
      refreshBill(userId);
    }

    async function _onOptionsBillChanged(): Promise<void> {
      CookieHelper.setCookie(
        "AdminBillingBillDashboardOptionsV3",
        m_optionsBill.value
      );
    }

    function optionsHistoryChanged(
      userId: number,
      options: DataTableOptions
    ): void {
      m_optionsHistory.value = options;
      _onOptionsHistoryChanged();
      refreshHistory(userId);
    }

    async function _onOptionsHistoryChanged(): Promise<void> {
      CookieHelper.setCookie(
        "AdminBillingHistoryDashboardOptionsV3",
        m_optionsHistory.value
      );
    }

    const expandFunction = (val: any) => {
      m_expanded.value = [val[m_expanded.value.length - 1]];
      m_optionsHistory.value = DEFAULT_OPTIONS_BILLS;
      m_optionsBill.value = DEFAULT_OPTIONS_BILLS;
    };

    function formatComment(value: string) {
      const splittedComment = value.split(" ");
      const reference = splittedComment[splittedComment.length - 1];
      let comment: string = value;
      if (value.includes("Maxillaire") || value.includes("Mandibulaire")) {
        comment = value.includes("Maxillaire") ? "U" : "L";
        comment += `${reference} `;
        if (value.includes("Annulation")) comment += " annulée";
      }
      return comment;
    }

    return {
      formNewBilling,
      Privilege,
      PrivilegeString,
      DateHelper,
      m_options,
      m_totalCount,
      m_totalCountHistoryConsumption,
      m_panel,
      m_users,
      m_search,
      m_billings,
      m_billingsHistoryConsumption,
      m_billingsHistoryInvoice,
      m_optionsHistory,
      m_optionsBill,
      m_isNewPackFormValid,
      m_newBilling,
      m_privileges,
      m_expanded,
      m_isLoading,
      newBillingDialog,
      refresh,
      optionsChanged,
      optionsBillChanged,
      optionsHistoryChanged,
      getUserHistory,
      getUserBillsHistory,
      getRemainingBillings,
      cancelAddBilling,
      addBilling,
      changenewBillingDate,
      setNewBillingPack,
      deleteBilling,
      openNewBillingDialog,
      updateBillingPaidValue,
      updateBillingInvoicedValue,
      m_headers,
      m_historyTableHeaders,
      m_billsHistoryTableHeaders,
      m_totalCountHistoryInvoice,
      mdiMagnify,
      mdiDelete,
      m_mounted,
      expandFunction,
      formatComment,
    };
  },
});
</script>

<style lang="scss">
.table_pack,
.sub_table_pack {
  line-height: 1;
}
</style>
