<template>
  <w-container
    fluid
    class="overflow-y-auto pa-0"
  >
    <div
      class="d-flex flex-column-reverse flex-md-row justify-start justify-md-space-between"
    >
      <div class="my-2 mx-4 d-flex flex-row justify-start">
        <!-- v-menu for table grouped action -->
        <w-menu
          v-model="m_actionMenu"
          offset-y
          close-on-click
        >
          <!-- Menu activator -->
          <template v-slot:activator="{ props }">
            <w-btn
              class="mr-2"
              size="small"
              :disabled="m_selectedOrders.length === 0"
              v-bind="props"
              variant="tonal"
              height="24px"
              :append-icon="mdiChevronDown"
            >
              Action groupée
            </w-btn>
          </template>
          <!-- Menu content -->
          <w-list class="ma-0 pa-0">
            <!-- Add a tracking number -->
            <w-list-item
              density="compact"
              link
              :prepend-icon="mdiPackageVariantClosed"
              title="Ajouter un numéro de suivi."
              @click="orderShippingDialog.show(m_selectedOrders)"
              :disabled="areAllOrdersReadyToBeShipped() === false"
            />
            <w-divider></w-divider>
            <!-- Mark keys ad Made -->
            <w-list-item
              density="compact"
              link
              :prepend-icon="mdiKeyVariant"
              title="Marquer les clés comme fabriquées."
              @click="markKeysAsMade(m_selectedOrders)"
              :disabled="areAllKeysToBeMade() === false"
            />
            <w-divider></w-divider>
            <!-- Download i3D -->
            <w-list-item
              density="compact"
              link
              :prepend-icon="mdiPrinter3dNozzleOutline"
              title="Télécharger les STLs I3D."
              @click="downloadI3DZip(m_selectedOrders)"
            />
            <w-divider></w-divider>
            <!-- Add to my todo -->
            <w-list-item
              v-if="!p_isTaskList"
              density="compact"
              link
              :prepend-icon="mdiCheckboxMarkedCirclePlusOutline"
              title="Ajouter à ma TODO."
              @click="addToMyTODO(m_selectedOrders)"
            />
            <w-divider v-if="!p_isTaskList"></w-divider>
            <!-- Remove from my todo -->
            <w-list-item
              v-if="p_isTaskList"
              base-color="primary"
              density="compact"
              link
              :prepend-icon="mdiCloseCircleOutline"
              title="Enlever de ma TODO."
              @click="removeFromTODO(m_selectedOrders)"
            />
          </w-list>
        </w-menu>

        <!-- search bar -->
        <div
          v-if="!p_isTaskList"
          id="prod-search-bar-container"
          class="mr-4"
        >
          <w-text-field
            class="ma-0"
            v-model="m_search"
            placeholder="Rechercher..."
            single-line
            density="compact"
            hide-details
            clearable
            color="primary"
            base-color="primary"
            height="14px"
            :prepend-inner-icon="mdiMagnify"
          >
          </w-text-field>
        </div>

        <!-- max date -->
        <div
          class="mx-2"
          style="line-height: 1 !important"
        >
          <w-menu
            v-model="m_dateMenu"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ props }">
              <w-btn
                class="mr-2"
                size="x-small"
                v-bind="props"
                variant="tonal"
                height="24px"
              >
                <span class="ml-2 font-weight-bold"
                  >max {{ m_maxDate.toLocaleDateString() }}</span
                >
                <w-icon :icon="mdiChevronDown"></w-icon>
              </w-btn>
            </template>

            <w-date-picker
              data-cy="date-picker-popup"
              v-model="m_selectedDate"
              @update:model-value="changeMaxDate"
              color="white"
              bg-color="primary"
              hide-header
              show-adjacent-months
            ></w-date-picker>
          </w-menu>
        </div>
      </div>
      <ProdProjectTableStats
        :p_criticalDate="
          getCriticalDate().toLocaleString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        "
        :p_urgentDate="
          getMaxUrgentDate().toLocaleString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        "
        :p_criticalNumber="m_criticalOrderNumber"
        :p_urgentNumber="m_urgentOrderNumber"
        :p_loading="m_loading"
      ></ProdProjectTableStats>
    </div>
    <w-data-table-server
      data-cy="prod-project-table"
      class="table_project_prod"
      :headers="m_headers"
      :items="m_ordersItems"
      :items-length="m_totalCount"
      item-key="id"
      :search="m_search"
      :page="m_page"
      :items-per-page="m_itemsPerPage"
      show-select
      @update:options="optionsChanged"
    >
      <template #header.data-table-select>
        <td class="pl-2">
          <w-checkbox
            :modelValue="
              m_selectedOrders.length > 0 &&
              m_selectedOrders.length === m_ordersItems.length
            "
            @update:model-value="selectAll"
            hide-details
            density="compact"
          ></w-checkbox>
        </td>
      </template>

      <template v-slot:body>
        <!-- for every doctor -->
        <template
          v-for="it in groupAndSortOrders()"
          :key="it.name"
        >
          <tr class="tableInfoRow">
            <td>
              <w-checkbox
                :modelValue="selectedOrthos(it.name)"
                @update:model-value="selectOrtho(it.name, $event)"
                hide-details
                density="compact"
              ></w-checkbox>
            </td>
            <td
              :colspan="m_headers.length"
              class=""
            >
              <h4>Dr. {{ it.name }}</h4>
            </td>
          </tr>

          <ProdProjectRow
            v-for="order in it.items"
            :key="order.id"
            :p_privilege="p_privilege"
            :p_order="order"
            :p_isFeatured="isOrderUrgent(order)"
            :p_isCritical="isOrderCritical(order)"
            :p_selectedOrders="m_selectedOrders"
            :p_isTaskList="p_isTaskList"
            @updateOrder="updateOrder"
            @selectItem="selectItem"
            @markAsShipped="orderShippingDialog.show([order])"
            @markKeyAsMade="markKeyAsMade"
            :style="m_loading ? 'opacity: 0.5;' : ''"
          >
          </ProdProjectRow>
        </template>
      </template>
    </w-data-table-server>
    <OrderShippingDialog
      ref="orderShippingDialog"
      @markAsShipped="markAsShipped"
    ></OrderShippingDialog>
  </w-container>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import DateHelper from "@/helpers/DateHelper";
import { EnumHelper } from "@/helpers/EnumHelper";
import OrderHelper from "@/helpers/OrderHelper";
import TaskHelper from "@/helpers/TaskHelper";
import WinnoveHelper, { DataTableOptions } from "@/helpers/WinnoveHelper";
import Arch from "@/models/Arch";
import Config from "@/models/Config";
import Order from "@/models/Order";
import Task from "@/models/Task";
import User from "@/models/User";
import {
  OrderState,
  Privilege,
  PrivilegeString,
} from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import {
  mdiCheckboxMarkedCirclePlusOutline,
  mdiChevronDown,
  mdiCloseCircleOutline,
  mdiKeyVariant,
  mdiMagnify,
  mdiPackageVariantClosed,
  mdiPrinter3dNozzleOutline,
} from "@mdi/js";
import axios, { AxiosResponse } from "axios";
import saveAs from "file-saver";
import Cookies from "js-cookie";
import { DateTime } from "luxon";
import { useRepo } from "pinia-orm";
import {
  WritableComputedRef,
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "vue";
import OrderShippingDialog from "./dialogs/OrderShippingDialog.vue";
import FilterValue from "./filterChip/FilterValue";
import PaginationFilter from "./filterChip/PaginationFilter";

const PROD_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 20,
  sortBy: [],
  groupBy: [],
  search: "",
};
const TASK_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 20,
  sortBy: [],
  groupBy: [],
  search: "",
};

// Headers for admin users.
// Matches the columns from ProdProjectRow.
const prod_headers = [
  {
    title: "COMS",
    sortable: false,
  },
  {
    title: "DATE DE LIVRAISON",
    sortable: false,
    value: "project.installationDate",
  },
  {
    title: "ÉTAT",
    sortable: false,
    value: "state",
  },
  {
    title: "REF UNIQUE",
    sortable: false,
    value: "ref",
  },
  {
    title: "MATÉRIAU",
    sortable: false,
  },
  {
    title: "MÂCHOIRE",
    sortable: false,
  },
  {
    title: "CLÉ",
    sortable: false,
  },
  {
    title: "I3D",
    sortable: false,
  },
  {
    title: "DENTS",
    sortable: false,
  },
  {
    title: "DATE DE CRÉATION",
    sortable: false,
    value: "creationDate",
  },
  {
    title: "ACTIONS",
    sortable: false,
  },
];

const PROD_PRIVILEGE_FILTER = new PaginationFilter(
  "Profils",
  "Users.privilege",
  FilterValue.fromEnum(
    PrivilegeString,
    Object.values(Privilege).filter(
      (value) => isNaN(Number(value)) === false
    ) as number[]
  )
);

const PROD_STATUS_FILTER = new PaginationFilter(
  "État",
  "Orders.status",
  FilterValue.fromEnum(
    EnumHelper.getOrderStateList(Privilege.ADMIN),
    Object.values(OrderState).filter(
      (value) => isNaN(Number(value)) === false
    ) as number[]
  )
);

function getProdFilters(): PaginationFilter[] {
  PROD_PRIVILEGE_FILTER.uncheckAll();
  PROD_PRIVILEGE_FILTER.checkByValues([
    Privilege.FREEMIUM,
    Privilege.PREMIUM,
    Privilege.ADMIN,
  ]);
  PROD_STATUS_FILTER.checkAll();
  PROD_STATUS_FILTER.uncheckByValues([
    OrderState.CANCELED,
    OrderState.DELIVERED,
    OrderState.SHIPPED,
    OrderState.ARCHIVED,
    OrderState.TO_BE_COMPLETED,
    OrderState.MADE_WITH_ROBOBEND,
    OrderState.TO_DRAW,
    OrderState.READY_TO_BE_ORDERED,
  ]);

  return [PROD_PRIVILEGE_FILTER, PROD_STATUS_FILTER];
}

export default defineComponent({
  name: "ProjectTable",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_isTaskList: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_ordersItems = ref<Order[]>([]);
    let m_orders: Order[] = [];
    const m_loading = ref(true);
    const m_totalCount = ref(0);
    const m_options = ref<DataTableOptions>(
      props.p_isTaskList
        ? getOptionsFromCookies("TaskDashboardOptions")
        : getOptionsFromCookies("ProdDashboardOptions")
    );
    const m_search: WritableComputedRef<string> = computed({
      get: () => m_options.value.search,
      set: (val: string) => {
        m_options.value.search = val;
        onOptionsChanged();
      },
    });
    const m_page: WritableComputedRef<number> = computed({
      get: () => m_options.value.page,
      set: (val: number) => {
        m_options.value.page = val;
        onOptionsChanged();
      },
    });
    const m_itemsPerPage: WritableComputedRef<number> = computed({
      get: () => m_options.value.itemsPerPage,
      set: (val: number) => {
        m_options.value.itemsPerPage = val;
        onOptionsChanged();
      },
    });
    let _refreshProjectListTimeout: ReturnType<typeof setTimeout> | null = null;
    const m_headers = prod_headers;
    // dates
    const m_maxDate = ref(getMaxDate());
    const m_maxUrgentDate = getMaxUrgentDate();
    const m_criticalDate = getCriticalDate();
    // stats
    const m_urgentOrderNumber = ref(0);
    const m_criticalOrderNumber = ref(0);
    // selection
    const m_selectedOrders = ref<Order[]>([]);
    const m_selectedOrthos = ref<User[]>([]);
    const orderShippingDialog = ref<InstanceType<
      typeof OrderShippingDialog
    > | null>(null);
    const m_actionMenu = ref(false);
    const m_dateMenu = ref(false);

    const m_selectedDate: WritableComputedRef<String> = computed({
      get() {
        return DateTime.fromJSDate(m_maxDate.value);
      },
      set(value: DateTime) {
        m_maxDate.value = new Date(value.toISO());
        m_maxDate.value.setHours(23, 59, 59);
      },
    });

    function getOptionsFromCookies(p_label: string): DataTableOptions {
      const options = Cookies.get(p_label);
      if (options) {
        return JSON.parse(options);
      } else {
        return PROD_OPTIONS;
      }
    }

    function refresh(): void {
      m_orders = useRepo(Order).query().withAllRecursive().get();
      m_ordersItems.value = useRepo(Order).query().withAllRecursive().get();
    }

    function getCriticalDate(): Date {
      const criticalDate = new Date(DateHelper.getNextAvailableDate());
      return criticalDate;
    }

    function getMaxUrgentDate(): Date {
      // fetch the number of weeks from the Config table
      const weeksUrgent = Config.getValue(
        "PROD_DASHBOARD_NB_WEEKS_URGENT_ORDERS"
      );
      // add the number of weeks to the current date
      const date = new Date();
      date.setDate(
        date.getDate() + 7 * (weeksUrgent ? parseInt(weeksUrgent) : 0)
      );
      return date;
    }
    function getMaxDate(): Date {
      // fetch the number of weeks from the Config table
      const weeksUrgent = Config.getValue(
        "PROD_DASHBOARD_NB_WEEKS_URGENT_ORDERS"
      );
      const weeksComplem = Config.getValue(
        "PROD_DASHBOARD_NB_WEEKS_COMPLEMENTARY_ORDERS"
      );
      // add the number of weeks to the current date
      const date = new Date();
      date.setDate(
        date.getDate() +
          7 * (weeksUrgent ? parseInt(weeksUrgent) : 0) +
          7 * (weeksComplem ? parseInt(weeksComplem) : 0)
      );
      // set hours to 23:59:59
      date.setHours(23, 59, 59);

      return date;
    }
    async function refreshProjectList(): Promise<void> {
      m_loading.value = true;
      m_selectedOrthos.value = [];
      m_selectedOrders.value = [];
      if (_refreshProjectListTimeout) clearTimeout(_refreshProjectListTimeout);
      _refreshProjectListTimeout = setTimeout(() => {
        // Get summary data
        (async () => {
          try {
            if (props.p_isTaskList) {
              m_totalCount.value = await WinnoveHelper.getUserTaskSummaryData();
            } else {
              m_totalCount.value = await WinnoveHelper.getProdSummaryData(
                m_options.value,
                getProdFilters(),
                m_maxDate.value
              );
            }
            m_criticalOrderNumber.value =
              await WinnoveHelper.getSummaryOrderCount(
                m_options.value,
                getProdFilters(),
                m_criticalDate
              );
            m_urgentOrderNumber.value =
              await WinnoveHelper.getSummaryOrderCount(
                m_options.value,
                getProdFilters(),
                m_maxUrgentDate
              );
            m_urgentOrderNumber.value -= m_criticalOrderNumber.value;
          } catch (e: any) {
            Logger.getInstance().error(e.message);
            WinnoveHelper.clearAllWithoutSession();
            return;
          } finally {
            refresh();
            m_loading.value = false;
          }
        })();
      }, 250);
    }
    function onOptionsChanged(): void {
      refreshProjectList();
      // store options in cookies, expires in 24h
      // if (props.p_isTaskList) {
      //   Cookies.set("TaskDashboardOptions", JSON.stringify(m_options.value), {
      //     expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      //   });
      // } else {
      //   Cookies.set("ProdDashboardOptions", JSON.stringify(m_options.value), {
      //     expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      //   });
      // }
    }
    async function updateOrder(order: Order): Promise<void> {
      await OrderHelper.updateOrder(order);
      refresh();
    }
    onMounted(() => {
      refreshProjectList();
    });
    function isOrderUrgent(order: Order): boolean {
      return new Date(order.project!.installationDate) <= m_maxUrgentDate;
    }
    function isOrderCritical(order: Order): boolean {
      return new Date(order.project!.installationDate) <= m_criticalDate;
    }
    function sortOrdersByDoctor(orders: Order[]): Array<{
      id: number;
      name: string;
      items: Array<Order>;
    }> {
      const ret: Array<{
        id: number;
        name: string;
        items: Array<Order>;
      }> = [];
      // build a dict of doctors while keeping the current orders order
      orders.forEach((order) => {
        const doctorId = order.project!.patient!.userId!;
        // check if the doctor exists in ret
        if (!ret.some((doctor) => doctor.id === doctorId)) {
          // if no add the doctor to the list
          ret.push({
            id: doctorId,
            name: order.project!.patient!.user!.lastName.toUpperCase(),
            items: [],
          });
        }
        const doctor = ret.find((doctor) => doctor.id === doctorId);
        doctor!.items.push(order);
      });
      return ret;
    }
    function groupAndSortOrders(): ItemGroup<Order>[] {
      // return an array of ItemGroup<Order> objects build
      // such as one item group is one doctor
      // and the items are the orders of this doctor ordered by installation date
      // and the item groups are ordered by min installation date
      const ret: ItemGroup<Order>[] = [];
      // get all orders asociated with a task
      const ordersToList = props.p_isTaskList
        ? m_orders.filter((order) => {
            return order.task !== null;
          })
        : m_orders;
      // sort orders by installation date
      const sortedOrders = ordersToList.sort((a, b) => {
        if (a.project!.sortId! < b.project!.sortId!) return -1;
        if (a.project!.sortId! > b.project!.sortId!) return 1;
        return 0;
      });
      // sort orders by doctor while keeping the installation date order
      const doctorOrdersList = sortOrdersByDoctor(sortedOrders);
      // for each entry in dictDoctorOrders, create an ItemGroup<Order> object
      // and add it to the ret array
      for (const doctorOrders of doctorOrdersList) {
        const itemGroup: ItemGroup<Order> = {
          name: doctorOrders.name,
          items: doctorOrders.items,
        };
        ret.push(itemGroup);
      }
      // update the size of m_itemsSelected
      return ret;
    }
    function selectItem(orderId: number, selected: boolean): void {
      if (selected) {
        m_selectedOrders.value.push(
          m_ordersItems.value.find((order) => order.id === orderId)!
        );
      } else {
        m_selectedOrders.value = m_selectedOrders.value.filter(
          (order) => order.id !== orderId
        );
      }
      // update orthos selection list : select an ortho if all orders are selected, unselect if not
      updateOrthoSelectionForOneOrder(
        m_ordersItems.value.find((order) => order.id === orderId)! as Order
      );
    }
    function updateOrthoSelectionForOneOrder(order: Order) {
      const ortho = order.project!.patient!.user!;
      if (
        m_ordersItems.value
          .filter(
            (order) =>
              order.project!.patient!.user!.lastName.toUpperCase() ===
              ortho.lastName.toUpperCase()
          )
          .every((order) => m_selectedOrders.value.includes(order))
      ) {
        if (!m_selectedOrthos.value.includes(ortho)) {
          m_selectedOrthos.value.push(ortho);
        }
      } else {
        m_selectedOrthos.value = m_selectedOrthos.value.filter(
          (user) => user.lastName.toUpperCase() !== ortho.lastName.toUpperCase()
        );
      }
    }
    function selectAll(value: boolean): void {
      if (!value) {
        m_selectedOrders.value = [];
        m_selectedOrthos.value = [];
      } else {
        m_selectedOrders.value = m_ordersItems.value;
        m_selectedOrthos.value = useRepo(User).all();
      }
    }
    function selectedOrthos(name: string): boolean {
      return m_selectedOrthos.value.some(
        (ortho) => ortho.lastName.toUpperCase() === name
      );
    }
    function selectOrtho(name: string, selected: boolean): void {
      const users = useRepo(User).all();
      if (selected) {
        m_selectedOrthos.value.push(
          users.find((user) => user.lastName.toUpperCase() === name)!
        );
        // select all orders with this ortho
        m_selectedOrders.value = m_selectedOrders.value.concat(
          m_ordersItems.value.filter(
            (order) =>
              order.project!.patient!.user!.lastName.toUpperCase() === name
          )
        );
      } else {
        m_selectedOrthos.value = m_selectedOrthos.value.filter(
          (user) => user.lastName.toUpperCase() !== name
        );
        // unselect all orders with this ortho
        m_selectedOrders.value = m_selectedOrders.value.filter(
          (order) =>
            order.project!.patient!.user!.lastName.toUpperCase() !== name
        );
      }
    }
    async function markOneOrderAsShipped(
      trackingNumber: string,
      order: Order,
      refreshAfter = true
    ) {
      order.status = OrderState.SHIPPED;
      order.trackingNumber = trackingNumber;
      await updateOrder(order);
      // unselect the order
      selectItem(order.id!, false);
      if (refreshAfter) refreshProjectList();
    }
    async function markAsShipped(trackingNumber: string, orders: Order[]) {
      orders.forEach((order) => {
        (async () => {
          await markOneOrderAsShipped(trackingNumber, order, false);
        })();
      });
      refreshProjectList();
    }
    function areAllOrdersReadyToBeShipped(): boolean {
      return m_selectedOrders.value.every(
        (order) => order.status === OrderState.TO_SHIP
      );
    }
    async function markKeyAsMade(order: Order): Promise<void> {
      order.markKeyAsBuilt();
      await updateOrder(order);
      // unselect the order
      selectItem(order.id!, false);
    }
    async function markKeysAsMade(orders: Order[]): Promise<void> {
      orders.forEach((order) => {
        (async () => {
          await markKeyAsMade(order);
        })();
      });
    }

    async function downloadI3DZip(p_orders: Order[]) {
      const archs: Arch[] = [];

      p_orders.forEach((order) => {
        const arch: Arch | null | undefined = order.project?.getArch(order.jaw);
        if (!arch) {
          logger.error(
            "Impossible de récupérer le fichier pour cette commande "
          );
        } else {
          archs.push(arch);
        }
      });
      await axios
        .post(
          "archs/zipStlI3D",
          { archs },
          {
            responseType: "blob",
          }
        )
        .then((p_response: AxiosResponse) => {
          console.log(p_response);
          const file: File = new File([p_response.data], "STL_I3D.zip");
          saveAs(file, Date.now() + "_STL_I3D.zip");
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            logger.warning(
              error,
              "Un des fichiers d'impression 3D n'est pas encore généré"
            );
          } else {
            logger.error(
              error,
              "Impossible de télécharger le fichier compressé"
            );
          }
        });
    }

    function areAllKeysToBeMade(): boolean {
      return m_selectedOrders.value.every(
        (order) => order.keyHasToBeMade() === true
      );
    }
    async function addToMyTODO(orders: Order[]) {
      let success = true;
      for (const order of orders) {
        try {
          // create task
          const task: Task = new Task();
          task.orderId = order.id;
          task.userId = AuthHelper.getLoggedUser().id;
          await TaskHelper.addTask(task);
          // unselect the order
          selectItem(order.id!, false);
        } catch (e) {
          success = false;
          Logger.getInstance().warning(
            e.message,
            `L'ajout de la commande ${order.getReference()} à votre TODO a échoué.`
          );
        }
      }
      // refresh list
      refreshProjectList();
      if (success)
        Logger.getInstance().success("Tâche(s) ajoutée(s) à votre TODO.");
    }
    async function removeFromTODO(
      orders: Order[],
      refreshAfter = true,
      currentUserOnly = true
    ) {
      let success = true;
      for (const order of orders) {
        // get task
        const task = currentUserOnly // if currentUserOnly, get the task of the current user
          ? useRepo(Task)
              .query()
              .where("orderId", order.id)
              .where("userId", AuthHelper.getLoggedUser().id)
              .first()
          : useRepo(Task).query().where("orderId", order.id).first(); // else get the first task independant of the user
        // check if task exists
        if (!task) {
          Logger.getInstance().error(
            "Impossible d'enlever la tâche de la TODO."
          );
          return;
        }
        // delete task
        try {
          await TaskHelper.deleteTask(task);
          // unselect the order
          selectItem(order.id!, false);
        } catch (e) {
          success = false;
          Logger.getInstance().warning(
            e.message,
            `Le retrait de la commande ${order.getReference()} de votre TODO a échoué.`
          );
        }
      }
      // refresh list
      if (refreshAfter) refreshProjectList();
      if (success) {
        if (currentUserOnly)
          Logger.getInstance().success("Tâche(s) enlevée(s) de votre TODO.");
        else Logger.getInstance().success("Tâche(s) enlevée(s) de la TODO.");
      }
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      onOptionsChanged();
    }

    watch(
      () => props.p_isTaskList,
      () => {
        refreshProjectList();
      }
    );

    function changeMaxDate() {
      m_dateMenu.value = false;
      refreshProjectList();
    }

    return {
      Privilege,
      m_search,
      m_page,
      m_itemsPerPage,
      m_headers,
      m_loading,
      m_totalCount,
      m_options,
      m_actionMenu,
      m_maxDate,
      m_selectedDate,
      m_dateMenu,
      isOrderUrgent,
      isOrderCritical,
      refreshProjectList,
      updateOrder,
      groupAndSortOrders,
      getMaxUrgentDate,
      getCriticalDate,
      selectItem,
      selectAll,
      m_urgentOrderNumber,
      m_criticalOrderNumber,
      m_selectedOrders,
      m_ordersItems,
      selectedOrthos,
      selectOrtho,
      markAsShipped,
      markKeyAsMade,
      markKeysAsMade,
      downloadI3DZip,
      orderShippingDialog,
      areAllOrdersReadyToBeShipped,
      areAllKeysToBeMade,
      addToMyTODO,
      removeFromTODO,
      onOptionsChanged,
      optionsChanged,
      changeMaxDate,
      mdiChevronDown,
      mdiPackageVariantClosed,
      mdiKeyVariant,
      mdiCheckboxMarkedCirclePlusOutline,
      mdiCloseCircleOutline,
      mdiMagnify,
      mdiPrinter3dNozzleOutline,
    };
  },
});
</script>

<style lang="scss">
#prod-search-bar-container {
  background: rgb(var(--v-theme-background));
  padding-left: 16px;
  width: 300px;
  height: 24px;
}

#prod-search-bar-container
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding: 0 12px;
}

#prod-search-bar-container .v-text-field__slot {
  height: 14px;
  & > input {
    height: 14px;
  }
}

#prod-search-bar-container .v-input {
  height: 24px;
  margin: 0 !important;

  .v-input__control {
    height: 24px;

    .v-field {
      height: 24px;

      .v-field__prepend-inner {
        height: 24px;
      }

      .v-field__field {
        height: 24px;

        .v-field__input {
          padding-top: 0;
          padding-bottom: 16px;
        }
      }
    }
  }
}

.tableInfoRow {
  background-color: rgb(var(--v-theme-background));
  color: rgb(var(--v-theme-primary));
  td {
    height: auto !important;
  }
}

.table_project_prod {
  margin: auto;

  .v-data-table__wrapper {
    padding: 0 16px;
    background-color: rgb(var(--v-theme-background));
    overflow-y: scroll;
    max-height: calc(100vh - 64px - 60px - 40px);
    @media screen and (max-width: 960px) {
      max-height: calc(100vh - 56px - 60px - 40px);
    }

    table {
      background-color: white;
      max-width: 1800px !important;
      margin: auto;
    }
  }

  th {
    top: 15px !important;
    background: rgb(var(--v-theme-background)) !important;
  }

  // Blocks to hide items shadows on the sides
  th:first-of-type:before {
    content: "";
    position: absolute;
    left: -10px;
    width: 10px;
    background: rgb(var(--v-theme-background));
    height: 100%;
    top: 0;
  }
  th:last-of-type:before {
    content: "";
    position: absolute;
    right: -10px;
    width: 10px;
    background: rgb(var(--v-theme-background));
    height: 100%;
    top: 0;
  }

  .v-data-footer {
    margin: auto;
    max-width: 1800px;
  }
}
</style>
