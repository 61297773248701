<template>
  <div class="winnova-background">
    <w-container
      id="container-login"
      fluid
      class="d-flex justify-center align-center"
    >
      <w-card
        class="pa-8 ma-auto"
        elevation="10"
      >
        <w-card-subtitle class="d-flex">
          <w-img
            src="/img/logo/iform-blanc-clinique.png"
            class="mx-4"
            height="50px"
          />
        </w-card-subtitle>
        <w-card-subtitle class="text-center">
          <h1>Bienvenue</h1>
        </w-card-subtitle>
        <w-card-text class="text-center pa-0 mb-8">
          Merci de renseigner votre email et votre mot de passe pour vous
          connecter
        </w-card-text>
        <w-card-text class="pa-0">
          <w-form
            v-model="m_isFormValid"
            @submit.prevent="connect()"
          >
            <div class="subtitle-2 grey--text text--darken-4 font-weight-bold">
              Email
            </div>
            <w-text-field
              data-cy="email"
              v-model.trim="m_email"
              :rules="[(v) => !!v.trim() || 'Adresse email requise']"
              required
              autofocus
            ></w-text-field>

            <div class="subtitle-2 grey--text text--darken-4 font-weight-bold">
              Mot de passe
            </div>
            <w-text-field
              data-cy="password"
              v-model.trim="m_password"
              :type="m_showPassword ? 'text' : 'password'"
              :rules="[(v) => !!v.trim() || 'Mot de passe requis']"
              :append-inner-icon="m_showPassword ? mdiEye : mdiEyeOff"
              @click:append-inner="m_showPassword = !m_showPassword"
              required
            ></w-text-field>
            <w-btn
              data-cy="submit-btn"
              block
              :disabled="!m_isFormValid"
              class="mt-6"
              type="submit"
              size="large"
              variant="outlined"
              color="wWhite"
            >
              Se connecter
              <!-- append mdi-login icon -->
              <template #append>
                <w-icon :icon="mdiLogin"></w-icon>
              </template>
            </w-btn>
          </w-form>
          <router-link
            :to="{ name: ROUTE_RESET_PASSWORD }"
            class="reset-password text-secondary"
            >Mot de passe oublié</router-link
          >
        </w-card-text>
      </w-card>
    </w-container>
  </div>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import WinnoveHelper from "@/helpers/WinnoveHelper";
import router, { ROUTE_HOME, ROUTE_RESET_PASSWORD } from "@/router";
import Logger from "@/shared/logger";
import { mdiEye, mdiEyeOff, mdiLogin } from "@mdi/js";
import axios, { AxiosError, AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";
import Cookies from "js-cookie";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "Login",
  components: {},
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_isFormValid = ref(true);
    const m_email = ref("");
    const m_password = ref("");
    const m_showPassword = ref(false);

    onMounted(() => {
      AuthHelper.clearAuthToken();
      WinnoveHelper.clearAll();
    });

    async function connect(): Promise<void> {
      await axios
        .post("users/auth", {
          email: m_email.value,
          password: m_password.value,
        })
        .then((p_response: AxiosResponse) => {
          logger.info("Bienvenue sur IForm !");
          const redirect = Cookies.get("redirect");
          if (redirect) {
            router.push({ path: redirect });
            Cookies.remove("redirect");
          } else {
            router.push({ name: ROUTE_HOME });
          }
        })
        .catch((p_error: AxiosError) => {
          if (
            p_error.response &&
            p_error.response.status === StatusCodes.TOO_MANY_REQUESTS
          ) {
            logger.error(
              "Trop de tentatives de connexion, veuillez réessayer plus tard."
            );
          } else {
            logger.error("Ce couple identifiant/mot de passe n'existe pas ");
          }
        });
    }

    return {
      ROUTE_RESET_PASSWORD,
      m_isFormValid,
      m_email,
      m_password,
      m_showPassword,
      connect,
      mdiLogin,
      mdiEye,
      mdiEyeOff,
    };
  },
});
</script>

<style lang="scss" scoped>
.winnova-background {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Pour éviter le débordement */
  position: relative;
  background: linear-gradient(
    to bottom right,
    rgb(var(--v-theme-wViolet)),
    rgb(var(--v-theme-wWhite))
  );
}

.winnova-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300vh;
  height: 300vh;
  background-image: url("/img/logo/WeNova_Lavande-60.svg");
  background-size: 250vh;
  transform: translate(-86.5vh, -80vh);
  opacity: 0.5;

  z-index: 0;
}

#container-login {
  height: 100vh;
  padding: 0 !important;

  .v-card {
    width: 420px;
    max-width: 90%;
  }
}

.reset-password {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3%;
}
</style>
