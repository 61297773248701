<template>
  <tr :class="p_isSelected ? 'bg-primary text-white' : ''">
    <!-- State. -->
    <td>
      <ProjectStateCell
        :p_project="p_project"
        :p_isSelected="p_isSelected"
        :p_privilege="p_privilege"
      />
    </td>

    <!-- Patient. -->
    <td
      align-self="center"
      :class="p_isSelected ? 'text-white' : 'text-black'"
    >
      <p class="ma-0">
        {{ p_project.getPatientLastName() }}
      </p>
      <p class="ma-0">
        {{ p_project.getPatientFirstName() }}
      </p>
    </td>

    <!-- Installation date. -->
    <td>
      <p class="ma-0">
        {{ p_project.getFormatedInstallationDate() }}
      </p>
    </td>
    <td>
      <p
        v-if="orderMaxilla"
        class="ma-0"
      >
        {{ orderMaxilla.getReference() }}
      </p>
      <p
        v-if="orderMandible"
        class="ma-0"
      >
        {{ orderMandible.getReference() }}
      </p>
    </td>

    <!-- Arcade image. -->
    <td>
      <span class="d-flex">
        <img
          :style="p_isSelected ? 'display: block;' : 'display: none;'"
          :src="
            orderMaxilla && orderMandible
              ? '/img/icon/active/maxilla-mandible.svg'
              : orderMaxilla
                ? '/img/icon/active/maxilla.svg'
                : '/img/icon/active/mandible.svg'
          "
          alt="Arcades maxillaire et mandibulaires"
        />
        <img
          :style="p_isSelected ? 'display: none;' : 'display: block;'"
          :src="
            orderMaxilla && orderMandible
              ? '/img/icon/default/maxilla-mandible.svg'
              : orderMaxilla
                ? '/img/icon/default/maxilla.svg'
                : '/img/icon/default/mandible.svg'
          "
          alt="Arcades maxillaire et mandibulaires"
        />
      </span>
    </td>

    <!-- Teeth range. -->
    <td>
      <span v-if="wireMaxilla && orderMaxilla && !orderMaxilla.toBeCompleted()">
        {{ wireMaxilla.teethRange }}
      </span>
      <br v-if="orderMaxilla && orderMandible" />
      <span
        v-if="wireMandible && orderMandible && !orderMandible.toBeCompleted()"
      >
        {{ wireMandible.teethRange }}
      </span>
    </td>

    <!-- Key. -->
    <td>
      <div v-if="orderMaxilla && !orderMaxilla.toBeCompleted()">
        <span v-if="wireMaxilla && wireMaxilla.keyNeeded">oui</span>
        <span v-else>non</span>
      </div>
      <div v-if="orderMandible && !orderMandible.toBeCompleted()">
        <span v-if="wireMandible && wireMandible.keyNeeded">oui</span>
        <span v-else>non</span>
      </div>
    </td>

    <!-- Creation date. -->
    <td align-self="center">
      <p class="ma-0">
        {{ p_project.getFormatedCreationDate() }}
      </p>
    </td>
  </tr>
</template>

<script lang="ts">
import OrderHelper from "@/helpers/OrderHelper";
import Order from "@/models/Order";
import Project from "@/models/Project";
import Wire from "@/models/Wire";
import { Privilege } from "@winnove/vue-wlib/enums";
import { computed, ComputedRef, defineComponent } from "vue";
import ProjectStateCell from "./cells/ProjectStateCell.vue";

export default defineComponent({
  name: "ProjectRow",
  components: {
    ProjectStateCell,
  },
  props: {
    p_project: {
      type: Object as () => Project,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_isSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const orderMandible: ComputedRef<Order | null> = computed(() => {
      return props.p_project.orderMandible();
    });

    const orderMaxilla: ComputedRef<Order | null> = computed(() => {
      return props.p_project.orderMaxilla();
    });

    const wireMandible: ComputedRef<Wire | null> = computed(() => {
      return props.p_project.wireMandible();
    });

    const wireMaxilla: ComputedRef<Wire | null> = computed(() => {
      return props.p_project.wireMaxilla();
    });

    function trackOrder(p_order: Order | null) {
      if (!p_order) return;
      return OrderHelper.trackOrder(p_order.trackingNumber);
    }

    function onClick() {
      emit("click");
    }

    function updateOrder(p_order: Order | null) {
      if (!p_order) return;
      emit("updateOrder", p_order);
    }

    return {
      orderMandible,
      orderMaxilla,
      wireMandible,
      wireMaxilla,
      onClick,
      updateOrder,
      trackOrder,
    };
  },
});
</script>
