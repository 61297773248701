<template>
  <w-container
    key="ContainerDashboard"
    fluid
    class="background_dashboard pa-0"
    style="min-height: 100%"
  >
    <div
      class="d-flex"
      v-if="m_loaded"
    >
      <ProductionTable
        v-if="p_route == ROUTE_ACCOUNT_MANAGER_VALIDATION"
        ref="accountManagerValidationTable"
        :p_headers="m_basicHeaders"
        :p_privilege="m_privilege"
        :p_cookie="'AccountManagerValidationTableOptions'"
        :p_route="ROUTE_ACCOUNT_MANAGER_VALIDATION"
        :p_groupByOrtho="true"
        :p_select="false"
      />
      <ProductionTable
        v-if="p_route == ROUTE_ACCOUNT_MANAGER_DELIVERY"
        ref="accountManagerDeliveryTable"
        :p_headers="m_basicHeaders"
        :p_privilege="m_privilege"
        :p_cookie="'AccountManagerDeliveryTableOptions'"
        :p_route="ROUTE_ACCOUNT_MANAGER_DELIVERY"
        :p_groupByOrtho="true"
        :p_select="false"
      />
    </div>
  </w-container>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import {
  ROUTE_ACCOUNT_MANAGER_DELIVERY,
  ROUTE_ACCOUNT_MANAGER_VALIDATION,
} from "@/router";
import { AccountManagerStep, Privilege } from "@winnove/vue-wlib/enums";
import { defineComponent, onMounted, ref } from "vue";
import ProductionTable from "./tables/ProductionTable.vue";

export default defineComponent({
  name: "DashboardAccountManager",
  components: {
    ProductionTable,
  },
  props: {
    p_route: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const m_privilege = ref(Privilege.FREEMIUM);

    const m_loaded = ref(false);
    const m_basicHeaders = [
      {
        title: "COMS",
        sortable: false,
      },
      {
        title: "DATE DE LIVRAISON",
        sortable: false,
        value: "project.installationDate",
      },
      {
        title: "ÉTAT",
        sortable: false,
        value: "state",
      },
      {
        title: "REF UNIQUE",
        sortable: false,
        value: "ref",
      },
      {
        title: "PRATICIEN",
        value: "Users.lastName",
        sortable: true,
      },
      {
        title: "PATIENT",
        value: "Patients.lastName",
        sortable: true,
      },
      {
        title: "ACTIONS",
        sortable: false,
      },
    ];

    onMounted(() => {
      m_privilege.value = AuthHelper.getLoggedUser().privilege;

      m_loaded.value = true;
    });

    return {
      ROUTE_ACCOUNT_MANAGER_DELIVERY,
      ROUTE_ACCOUNT_MANAGER_VALIDATION,
      Privilege,
      AccountManagerStep,
      m_privilege,
      m_loaded,
      m_basicHeaders,
    };
  },
});
</script>

<style lang="scss" scoped>
.background_dashboard {
  background-color: #f6f9fd;
}
</style>
