<template>
  <w-menu
    activator="parent"
    offset="4px"
    v-model="m_visible"
    width="250px"
  >
    <w-list density="compact">
      <w-list-item
        link
        @click="$emit('toggle-camera')"
        title="Caméra ortho"
      />
      <w-divider></w-divider>

      <w-list-item
        link
        @click="$emit('toggle-wireframe')"
        title="Fil de fer"
      />
      <w-divider></w-divider>

      <w-list-item
        link
        @click="$emit('snapshot')"
        title="Capture d'écran"
      />
      <w-divider></w-divider>

      <w-list-item
        link
        @click="$emit('export-maxi-wire')"
        title="Exporter le fil Maxi"
      />
      <w-divider></w-divider>

      <w-list-item
        link
        @click="$emit('export-mandi-wire')"
        title="Exporter le fil Mandi"
      />
    </w-list>
  </w-menu>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "OptionDrawer",
  setup(props, context) {
    const m_visible = ref(false);

    function toggle(): void {
      m_visible.value = !m_visible.value;
    }

    return {
      m_visible,
      toggle,
    };
  },
});
</script>
