<template>
  <div>
    <w-card
      elevation="5"
      class="scene-toolbar"
    >
      <w-card-text class="pa-1">
        <div class="d-flex">
          <!-- Unlock vertical rotation -->
          <BasicTool
            :p_imgDefault="'/img/icon/default/3D_rotation.svg'"
            :p_imgActive="'/img/icon/active/3D_rotation.svg'"
            :p_imgDisabled="'/img/icon/disabled/3D_rotation.svg'"
            :p_caption="'Déverrouiller la rotation verticale à 360°'"
            :p_active="m_verticalRotationUnlocked"
            @click="toggleVerticalRotationLock()"
          >
          </BasicTool>

          <w-divider vertical></w-divider>

          <!-- Annuler l'action -->
          <div class="d-none d-md-flex">
            <BasicTool
              :p_imgDefault="'/img/icon/default/undo.svg'"
              :p_imgActive="'/img/icon/active/undo.svg'"
              :p_imgDisabled="'/img/icon/disabled/undo.svg'"
              :p_caption="'[Ctrl+Z] Annuler la dernière action de point'"
              :p_disabledWarning="'Il n\'est pas possible d\'annuler la dernière action.'"
              :p_disabled="!canCtrlZ"
              @click="ctrlZ()"
            >
            </BasicTool>
          </div>

          <!-- Refaire l'action -->
          <div class="d-none d-md-flex">
            <BasicTool
              :p_imgDefault="'/img/icon/default/redo.svg'"
              :p_imgActive="'/img/icon/active/redo.svg'"
              :p_imgDisabled="'/img/icon/disabled/redo.svg'"
              :p_caption="'[Ctrl+Y] Refaire la dernière action de point'"
              :p_disabledWarning="'Il n\'est pas possible de refaire la dernière action.'"
              :p_disabled="!canCtrlY"
              @click="ctrlY()"
            >
            </BasicTool>
          </div>

          <!-- Affichage de la pénetration -->
          <BasicTool
            :p_imgDefault="'/img/icon/default/WireInArchPicto.svg'"
            :p_imgActive="'/img/icon/active/WireInArchPicto.svg'"
            :p_imgDisabled="'/img/icon/disabled/WireInArchPicto.svg'"
            :p_link="'/img/gifs/show_penetration.png'"
            :p_caption="'[n] Montrer la pénetration du fil dans l\'arcade'"
            :p_disabled="!visibleWire"
            :p_disabledWarning="'Outil actif seulement lorsque le fil est visible.'"
            :p_active="m_showPenetration"
            @click="showPenetration(!m_showPenetration)"
          >
          </BasicTool>

          <!-- Affichage des zones -->
          <div
            class="d-none d-md-flex"
            v-if="p_privilege !== Privilege.FREEMIUM"
          >
            <BasicTool
              :p_imgDefault="'/img/icon/default/show_zones.svg'"
              :p_imgActive="'/img/icon/active/show_zones.svg'"
              :p_imgDisabled="'/img/icon/disabled/show_zones.svg'"
              :p_link="'/img/gifs/show_zones.png'"
              :p_caption="'[shift] Montrer les zones de placement autour des points'"
              :p_disabled="!editingWire"
              :p_disabledWarning="'Outil actif seulement lors de l\'édition d\'un fil.'"
              :p_active="m_showZones"
              @click="showZones(!m_showZones)"
            >
            </BasicTool>
          </div>

          <!-- Calcul des points de contacts -->
          <BasicTool
            v-if="hasMandiArch && hasMaxiArch"
            :p_imgDefault="'/img/icon/default/occlusion.svg'"
            :p_imgActive="'/img/icon/active/occlusion.svg'"
            :p_imgDisabled="'/img/icon/disabled/occlusion.svg'"
            :p_link="'/img/gifs/occlusion.png'"
            :p_caption="'Calcul des points de contacts'"
            :p_disabled="editingPrescription || !hasMaxiArch || !hasMandiArch"
            :p_disabledWarning="'Outil non actif lors de l\'édition de la prescription.'"
            :p_active="m_occlusionVisible"
            @click="showContactPoints(!m_occlusionVisible)"
          >
          </BasicTool>

          <w-divider vertical></w-divider>

          <!-- Cacher arcade -->
          <BasicTool
            v-if="hasMaxiArch || hasMandiArch"
            :p_imgDefault="'/img/icon/default/maxilla.svg'"
            :p_imgActive="'/img/icon/active/maxilla.svg'"
            :p_imgDisabled="'/img/icon/disabled/maxilla.svg'"
            :p_caption="
              m_maxillaVisible ? 'Cacher maxillaire' : 'Montrer maxillaire'
            "
            :p_disabled="!hasMaxiArch"
            :p_active="!m_maxillaVisible"
            @click="showMaxilla(!m_maxillaVisible)"
          ></BasicTool>
          <BasicTool
            v-if="hasMaxiArch || hasMandiArch"
            :p_imgDefault="'/img/icon/default/mandible.svg'"
            :p_imgActive="'/img/icon/active/mandible.svg'"
            :p_imgDisabled="'/img/icon/disabled/mandible.svg'"
            :p_caption="
              m_mandibleVisible ? 'Cacher mandibule' : 'Montrer mandibule'
            "
            :p_disabled="!hasMandiArch"
            :p_active="!m_mandibleVisible"
            @click="showMandible(!m_mandibleVisible)"
          ></BasicTool>
          <!-- Opacité de l'arcade -->
          <BasicTool
            :p_imgDefault="'/img/icon/default/ChangeOpacity.svg'"
            :p_imgActive="'/img/icon/active/ChangeOpacity.svg'"
            :p_imgDisabled="'/img/icon/disabled/ChangeOpacity.svg'"
            :p_link="'/img/gifs/opacity.png'"
            :p_caption="'Ajuster l\'opacité'"
            :p_active="m_opacity"
            @click="toggleOpacity()"
          ></BasicTool>

          <w-divider
            class="d-none d-md-flex"
            vertical
          ></w-divider>

          <!-- Affichage de la vue linguale -->
          <div class="d-none d-md-flex">
            <BasicTool
              :p_imgDefault="'/img/icon/default/view_lingual_no_bg.svg'"
              :p_imgActive="'/img/icon/active/view_lingual.svg'"
              :p_imgDisabled="'/img/icon/disabled/view_lingual.svg'"
              :p_link="'/img/gifs/view_lingual.png'"
              :p_caption="'Vue linguale'"
              @click="$emit('view-lingual')"
            ></BasicTool>
          </div>
          <!-- Affichage de la vue occlusale -->
          <div class="d-none d-md-flex">
            <BasicTool
              :p_imgDefault="'/img/icon/default/view_occlusal_no_bg.svg'"
              :p_imgActive="'/img/icon/active/view_occlusal.svg'"
              :p_imgDisabled="'/img/icon/disabled/view_occlusal.svg'"
              :p_link="'/img/gifs/view_occlusal.png'"
              :p_caption="'Vue occlusale'"
              :p_disabled="
                p_rotationToolActive ||
                (m_mandibleVisible && hasMandiArch) ===
                  (m_maxillaVisible && hasMaxiArch)
              "
              :p_disabledWarning="
                p_rotationToolActive
                  ? 'Il n\'est pas possible de passer en vue occlusale en mode rotation.'
                  : 'Cachez une des arcades pour passer en vue occlusale.'
              "
              @click="$emit('view-occlusal')"
            ></BasicTool>
          </div>

          <w-divider vertical></w-divider>

          <!-- Historique des fils -->
          <w-divider vertical></w-divider>

          <BasicTool
            v-if="p_privilege == Privilege.ADMIN && visibleHistoryMenu"
            :p_imgDefault="'/img/icon/default/time.svg'"
            :p_imgActive="'/img/icon/active/time.svg'"
            :p_imgDisabled="'/img/icon/disabled/time.svg'"
            :p_caption="
              !m_wireHistoryVisible
                ? 'Montrer l\'historique du fil'
                : 'Cacher l\'historique du fil'
            "
            :p_active="m_wireHistoryVisible"
            @click="m_wireHistoryVisible = !m_wireHistoryVisible"
          ></BasicTool>
          <WireHistoryDrawer
            v-if="
              p_privilege == Privilege.ADMIN &&
              visibleHistoryMenu &&
              p_wireHistory?.length > 0
            "
            ref="wireHistoryDrawer"
            @load-wire="$emit('load-wire', $event)"
            @unload-wire="$emit('unload-wire')"
            @restore-wire="$emit('restore-wire', $event)"
            @close-history="m_wireHistoryVisible = false"
            :p_wireHistory="p_wireHistory"
            :p_visible="m_wireHistoryVisible"
          />
        </div>
      </w-card-text>
    </w-card>
  </div>
</template>

<script lang="ts">
import BasicTool from "@/components/shared/BasicTool.vue";
import Project from "@/models/Project";
import WireHistory from "@/models/WireHistory";
import { Arcade, EditorState, Privilege } from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import { computed, defineComponent, ref } from "vue";
import WireHistoryDrawer from "./WireHistoryDrawer.vue";

export default defineComponent({
  name: "SceneViewToolbar",
  components: { BasicTool, WireHistoryDrawer },
  props: {
    p_project: {
      type: Object as () => Project,
      required: false,
    },
    p_editorState: {
      type: Number as () => EditorState,
      required: true,
    },
    p_rotationToolActive: {
      type: Boolean as () => boolean,
      required: false,
    },
    p_isCtrlZEmpty: {
      type: Boolean as () => boolean,
      required: false,
    },
    p_isCtrlYEmpty: {
      type: Boolean as () => boolean,
      required: false,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_selectedArcade: {
      type: Number as () => Arcade,
      required: true,
    },
    p_wireHistory: {
      type: Array as () => WireHistory[],
      required: false,
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_visible = ref(true);
    const m_maxillaVisible = ref(true);
    const m_mandibleVisible = ref(true);
    const m_opacity = ref(false);
    const m_occlusionVisible = ref(false);
    const m_showZones = ref(false);
    const m_showPenetration = ref(false);
    const m_wireHistoryVisible = ref(false);
    const m_verticalRotationUnlocked = ref(false);

    const wireHistoryDrawer = ref<InstanceType<
      typeof WireHistoryDrawer
    > | null>(null);

    const hasMaxiArch = computed(() => {
      return (
        !!props.p_project?.archMaxilla() &&
        props.p_project?.archMaxilla().existsInDatabase()
      );
    });

    const hasMandiArch = computed(() => {
      return (
        !!props.p_project?.archMandible() &&
        props.p_project?.archMandible().existsInDatabase()
      );
    });

    const editingPrescription = computed(() => {
      return props.p_editorState === EditorState.PRESCRIPTION_EDIT;
    });

    const editingWire = computed(() => {
      return (
        props.p_editorState === EditorState.WIRE_MANUAL_EDITING ||
        props.p_editorState === EditorState.WIRE_DRAWING
      );
    });

    const visibleWire = computed(() => {
      return (
        props.p_editorState === EditorState.WIRE_SUMMARY ||
        props.p_editorState === EditorState.PROJECT_SUMMARY ||
        props.p_editorState === EditorState.WIRE_MANUAL_EDITING
      );
    });

    const visibleHistoryMenu = computed(() => {
      return (
        props.p_wireHistory &&
        props.p_wireHistory.length > 0 &&
        (props.p_editorState === EditorState.WIRE_SUMMARY ||
          props.p_editorState === EditorState.WIRE_DRAWING ||
          props.p_editorState === EditorState.PLANE_DRAWING ||
          props.p_editorState === EditorState.WIRE_MANUAL_EDITING)
      );
    });

    const canCtrlZ = computed(() => {
      return (
        !props.p_isCtrlZEmpty &&
        (props.p_editorState === EditorState.WIRE_DRAWING ||
          props.p_editorState === EditorState.PLANE_DRAWING ||
          props.p_editorState === EditorState.WIRE_MANUAL_EDITING)
      );
    });

    const canCtrlY = computed(() => {
      return (
        !props.p_isCtrlYEmpty &&
        (props.p_editorState === EditorState.WIRE_DRAWING ||
          props.p_editorState === EditorState.PLANE_DRAWING ||
          props.p_editorState === EditorState.WIRE_MANUAL_EDITING)
      );
    });

    function show() {
      m_visible.value = true;
    }

    function hide() {
      m_visible.value = false;
    }

    function showMaxilla(p_value: boolean): void {
      if (props.p_project?.archMaxilla()) {
        m_maxillaVisible.value = p_value;
        context.emit("show-maxilla", p_value);
      } else {
        logger.info("Aucune arcade supérieure");
      }
    }

    function showMandible(p_value: boolean): void {
      if (props.p_project?.archMandible()) {
        m_mandibleVisible.value = p_value;
        context.emit("show-mandible", p_value);
      } else {
        logger.info("Aucune arcade inférieure");
      }
    }

    function toggleOpacity(): void {
      context.emit("toggle-opacity");
      m_opacity.value = !m_opacity.value;
    }

    function showContactPoints(p_value: boolean): void {
      m_occlusionVisible.value = p_value;
      context.emit("show-contact-points", p_value);
    }

    function showZones(p_value: boolean): void {
      m_showZones.value = p_value;
      context.emit("show-zones", p_value);
    }

    function setShowZones(p_value: boolean): void {
      m_showZones.value = p_value;
    }

    function showPenetration(p_value: boolean): void {
      m_showPenetration.value = p_value;
      context.emit("show-penetration", p_value);
    }

    function setShowPenetration(p_value: boolean): void {
      m_showPenetration.value = p_value;
    }

    function setShowMaxilla(p_value: boolean): void {
      m_maxillaVisible.value = p_value;
    }

    function showOcclusion(p_value: boolean): void {
      m_occlusionVisible.value = p_value;
    }

    function ctrlZ(): void {
      context.emit("ctrl-z");
    }

    function ctrlY(): void {
      context.emit("ctrl-y");
    }

    function toggleVerticalRotationLock(): void {
      m_verticalRotationUnlocked.value = !m_verticalRotationUnlocked.value;
      context.emit("select-control", m_verticalRotationUnlocked.value);
    }

    return {
      Arcade,
      EditorState,
      Privilege,
      m_visible,
      m_maxillaVisible,
      m_mandibleVisible,
      m_opacity,
      hasMaxiArch,
      hasMandiArch,
      m_occlusionVisible,
      editingPrescription,
      editingWire,
      visibleWire,
      m_showZones,
      m_showPenetration,
      showContactPoints,
      showMandible,
      showMaxilla,
      setShowMaxilla,
      toggleOpacity,
      show,
      hide,
      showZones,
      setShowZones,
      showPenetration,
      setShowPenetration,
      showOcclusion,
      ctrlZ,
      canCtrlZ,
      ctrlY,
      canCtrlY,
      visibleHistoryMenu,
      wireHistoryDrawer,
      m_wireHistoryVisible,
      m_verticalRotationUnlocked,
      toggleVerticalRotationLock,
    };
  },
});
</script>
