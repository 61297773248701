type LogLevel = "success" | "debug" | "info" | "warning" | "error";

export default class Logger {
  private static instance: Logger;
  private subscribers: Record<LogLevel, ((msg: string) => void)[]> = {
    success: [],
    debug: [],
    info: [],
    warning: [],
    error: [],
  };

  private constructor() {}

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  public success(msg: string, msgToUser?: string): void {
    this.log("success", msg, msgToUser);
  }

  public debug(msg: string, msgToUser?: string): void {
    this.log("debug", msg, msgToUser);
  }

  public info(msg: string, msgToUser?: string): void {
    this.log("info", msg, msgToUser);
  }

  public warning(msg: string, msgToUser?: string): void {
    this.log("warning", msg, msgToUser);
  }

  public error(msg: string, msgToUser?: string): void {
    this.log("error", msg, msgToUser);
  }

  public subscribe(level: LogLevel, callback: (msg: string) => void): void {
    this.subscribers[level].push(callback);
  }

  public unsubscribe(level: LogLevel, callback: (msg: string) => void): void {
    this.subscribers[level] = this.subscribers[level].filter(
      (cb) => cb !== callback
    );
  }

  private log(level: LogLevel, msg: string, msgToUser?: string): void {
    switch (level) {
      case "debug":
      case "success":
      case "info":
        if (import.meta.env.NODE_ENV === "development") console.log(msg);
        break;
      case "warning":
        console.warn(msg);
        break;
      case "error":
        console.error(msg);
        break;
    }
    this.subscribers[level].forEach((callback) => callback(msgToUser ?? msg));
  }
}
