<template>
  <w-chip
    data-cy="order-state-chip"
    variant="flat"
    :class="
      'text-center' +
      (p_miniature ? ' mini-chip' : '') +
      (p_disable ? ' disabled-chip' : '')
    "
    :color="ProjectHelper.getOrderChipColor(p_privilege, p_state, p_isSelected)"
  >
    <div
      :style="`color: ${ProjectHelper.getOrderChipTextColor(
        p_privilege,
        p_state,
        p_isSelected
      )}`"
    >
      {{ displayedText }}
    </div>
  </w-chip>
</template>

<script lang="ts">
import ProjectHelper from "@/helpers/ProjectHelper";
import { OrderState, Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "OrderStateChip",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_state: {
      type: Number as () => OrderState,
      required: true,
    },
    p_isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_miniature: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const displayedText = computed(() => {
      if (props.p_miniature) {
        let text = ProjectHelper.getOrderStateToDisplay(
          props.p_privilege,
          props.p_state
        );
        if (text.length > 10) {
          text = text.substring(0, 8) + "...";
        }
        return text;
      } else {
        return ProjectHelper.getOrderStateToDisplay(
          props.p_privilege,
          props.p_state
        );
      }
    });

    return {
      ProjectHelper,
      displayedText,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-chip {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.mini-chip {
  height: 15px;
  font-size: 10px;
  margin-left: 6px !important;
  max-width: 80px;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.disabled-chip {
  opacity: 0.3;
}
</style>
