import { Constants } from "@/Constants";
import WinnoveHelper from "@/helpers/WinnoveHelper";
import Project from "@/models/Project";
import Logger from "@/shared/logger";
import {
  Arcade,
  CtrlZActionType,
  Dimension,
  GimbalIndex,
  GimbalType,
  LineIndex,
  Material,
  PointType,
  WireType,
} from "@winnove/vue-wlib/enums";
import {
  BufferAttribute,
  BufferGeometry,
  CurvePath,
  DoubleSide,
  ExtrudeGeometry,
  Line3,
  LineBasicMaterial,
  LineCurve3,
  LineSegments,
  MathUtils,
  Matrix4,
  Mesh,
  MeshMatcapMaterial,
  MeshPhongMaterial,
  Quaternion,
  Scene as Scene3D,
  Shape,
  SphereGeometry,
  Texture,
  TextureLoader,
  TubeGeometry,
  Vector3,
} from "three";
import {
  SAH,
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
} from "three-mesh-bvh";
import { CtrlZAction, PointData } from "./SceneCommon";
import SceneControls from "./SceneControls";
import SceneMeshOrPoints from "./SceneMeshOrPoints";

Mesh.prototype.raycast = acceleratedRaycast;
BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;

export default class SceneJawArchWireMesh extends SceneMeshOrPoints {
  private _project: Project | null;
  private _arcade: Arcade;
  private _performDistanceChecks: boolean = true;
  private _lines: LineSegments[] = [];

  private _textureLoader: TextureLoader = new TextureLoader();
  private _wireTexture: Texture = this._textureLoader.load(
    "/img/matcaps/wirematcap.png"
  );
  private _angleTexture: Texture = this._textureLoader.load(
    "/img/matcaps/anglematcap.png"
  );
  private _segTexture: Texture = this._textureLoader.load(
    "/img/matcaps/segmatcap.png"
  );
  private _wireMaterial: MeshMatcapMaterial = new MeshMatcapMaterial({
    matcap: this._wireTexture,
    side: DoubleSide,
    polygonOffset: true,
    polygonOffsetFactor: 1,
    polygonOffsetUnits: 1,
  });
  private _angleMaterial: MeshMatcapMaterial = new MeshMatcapMaterial({
    matcap: this._angleTexture,
    side: DoubleSide,
  });
  private _segMaterial: MeshMatcapMaterial = new MeshMatcapMaterial({
    matcap: this._segTexture,
    side: DoubleSide,
  });

  constructor(
    scene: Scene3D,
    p_arcade: Arcade,
    project: Project | null,
    controls: SceneControls
  ) {
    super(scene, controls);
    this._project = project;
    this._arcade = p_arcade;
    this._lines[LineIndex.ZONE] = new LineSegments(
      new BufferGeometry().setFromPoints([new Vector3(), new Vector3()]),
      new LineBasicMaterial({ color: 0x00ff00 })
    );
    this._lines[LineIndex.ZONE].frustumCulled = false;
    this._lines[LineIndex.PENETRATION] = new LineSegments(
      new BufferGeometry().setFromPoints([new Vector3(), new Vector3()]),
      new LineBasicMaterial({ color: 0xff0000 })
    );
    this._lines[LineIndex.PENETRATION].frustumCulled = false;
    this._lines[LineIndex.FIT] = new LineSegments(
      new BufferGeometry().setFromPoints([new Vector3(), new Vector3()]),
      new LineBasicMaterial({ color: 0xff0000 })
    );
    this._lines[LineIndex.FIT].frustumCulled = false;
    scene.add(this._lines[LineIndex.ZONE]);
    scene.add(this._lines[LineIndex.PENETRATION]);
    scene.add(this._lines[LineIndex.FIT]);
  }

  public updateProject(p_project: Project) {
    this._project = p_project;
  }

  // offset the point position along the normal by the wire radius
  private _applyWireDimToPoint(p_point: PointData): void {
    if (!this._project?.getPrescription(this._arcade)) {
      return;
    }
    // Compute the offseted position
    const wireDim = this._project.getPrescription(this._arcade)!.dimension;
    const radius = WinnoveHelper.inchDiameterDimensionToMMRadius(wireDim);
    const meshPosition = p_point.p
      .clone()
      .add(p_point.n.clone().multiplyScalar(radius));
    // Update the point position
    p_point.m.position.set(meshPosition.x, meshPosition.y, meshPosition.z);
    p_point.z.position.copy(p_point.m.position);
    p_point.m.scale.set(radius, radius, radius);
    p_point.z.visible = false;
  }

  public loadPoints(pointsJSON: string, p_type: PointType): void {
    const points = this._parsePointsJSON(pointsJSON, p_type);

    // check that p_type is either WIRE or BASE
    if (p_type !== PointType.WIRE && p_type !== PointType.BASE) {
      Logger.getInstance().error(
        "SceneJawArchWireMesh.loadPoints: p_type must be either WIRE or BASE",
        "Une erreur s'est produite, impossible d'ajouter ce point"
      );
      return;
    }
    points.forEach((p, i) => {
      p.m.name = this._getPointBaseName() + "|" + i;
      p.z.name = "zone" + p.m.name;
      this._applyWireDimToPoint(p);
      this._addPoint(p);
      // Add point to the scene
      this.scene.add(p.m);
      this.scene.add(p.z);
    });
    this.updateWireMesh(true);
  }

  public addPoint(
    p_point: Vector3,
    p_normal: Vector3,
    p_type: PointType,
    p_id: number
  ): CtrlZAction | undefined {
    // check that p_type is either WIRE or BASE
    if (p_type !== PointType.WIRE && p_type !== PointType.BASE) {
      Logger.getInstance().error(
        "SceneJawArchWireMesh.addPoint: p_type must be either WIRE or BASE",
        "Une erreur s'est produite, impossible d'ajouter ce point"
      );
      return;
    }

    // Check min distance
    if (this._performDistanceChecks) {
      if (this._getPointMinDistance(p_point) < Constants.MIN_DISTANCE_3D) {
        Logger.getInstance().warning(
          "Ce point est trop proche d'un autre point"
        );
        return;
      }
    }

    // Create point mesh
    const pointData = this._createPointData(p_point, p_normal, p_type);
    pointData.m.name =
      this._getPointBaseName() +
      "|" +
      (p_id === -1 ? this._getPointsLength() : p_id);
    pointData.z.name = "zone" + pointData.m.name;
    this._applyWireDimToPoint(pointData);
    if (p_id === -1) this._addPoint(pointData);
    else {
      this._insertPoint(pointData, p_id);
      this._updatePointsName();
    }

    // Add point to the scene
    this.scene.add(pointData.m);
    this.scene.add(pointData.z);
    return {
      type: CtrlZActionType.REMOVE_POINT,
      name: pointData.m.name,
      basePosition: pointData.p,
      normal: pointData.n,
      finalPosition: pointData.m.position,
    };
  }

  private _updatePointsName(): void {
    this.points.forEach((p, i) => {
      p.m.name = this._getPointBaseName() + "|" + i;
      p.z.name = "zone" + p.m.name;
    });
  }

  public insertWirePoint(p_index: number): CtrlZAction | undefined {
    // check that p_index is in range
    if (p_index < 0 || p_index >= this._getPointsLength() - 1) {
      Logger.getInstance().warning(
        "SceneJawArchWireMesh.insertPoint: p_index is out of range",
        "Impossible d'ajouter un point à cet endroit."
      );
      return;
    }
    // compute the new point position
    const idPoint = this.points[p_index];
    const nextPoint = this.points[p_index + 1];
    const newPointPosition = idPoint.m.position
      .clone()
      .lerp(nextPoint.m.position, 0.5);
    const newPointNormal = idPoint.n.clone().lerp(nextPoint.n, 0.5);

    // Check min distance
    if (this._performDistanceChecks) {
      if (
        this._getPointMinDistance(newPointPosition) < Constants.MIN_DISTANCE_3D
      ) {
        Logger.getInstance().warning(
          "Impossible d'insérer un point ici car il n'y a pas assez d'espace."
        );
        return;
      }
    }

    // Create point mesh
    const pointData = this._createPointData(
      newPointPosition,
      newPointNormal,
      PointType.WIRE
    );
    this._applyWireDimToPoint(pointData);
    this._insertPoint(pointData, p_index + 1);
    this._updatePointsName();

    // Add point to the scene
    this.scene.add(pointData.m);
    this.scene.add(pointData.z);
    return {
      type: CtrlZActionType.REMOVE_POINT,
      name: pointData.m.name,
      basePosition: pointData.p,
      normal: pointData.n,
      finalPosition: pointData.m.position,
    };
  }

  public getWirePoints(): string {
    let result = "[";
    this.points.forEach((point, i) => {
      result +=
        JSON.stringify(point.m.position, ["x", "y", "z"]) +
        (i < this.points.length - 1 ? "," : "");
    });
    return result + "]";
  }

  // return the points of the wire as a Vector3 array, ensure there are no duplicates
  private _getWirePoints(): Vector3[] {
    const result: Vector3[] = [];
    this.points.forEach((point) => {
      const pointPosition = point.m.position;
      if (
        result.length === 0 ||
        !pointPosition.equals(result[result.length - 1])
      ) {
        result.push(pointPosition);
      }
    });
    return result;
  }

  private _getMinSegLengthCurve(
    p_material: Material | undefined,
    p_isSquared: boolean | undefined,
    p_dimension: Dimension | undefined
  ): number[] {
    const material =
      p_material ?? this._project!.getPrescription(this._arcade)!.material;
    const isSquared =
      p_isSquared ?? this._project!.getPrescription(this._arcade)!.wireType;
    switch (material) {
      case Material.TMA:
        if (isSquared) {
          return Constants.TMA_SQUARED_MIN_SEG_CURVE;
        } else {
          if (p_dimension === Dimension._0180)
            return Constants.TMA_018_MIN_SEG_CURVE;
          return Constants.TMA_MIN_SEG_CURVE;
        }
      case Material.TWISTED_GOLD:
        return Constants.TWISTED_GOLD_MIN_SEG_CURVE;
      case Material.TWISTED_STEEL:
        return Constants.TWISTED_STEEL_MIN_SEG_CURVE;
    }
  }

  private _getMaxAngle(
    p_material: Material | undefined,
    p_isSquared: boolean | undefined
  ): number {
    const material =
      p_material ?? this._project!.getPrescription(this._arcade)!.material;
    const isSquared =
      p_isSquared ?? this._project!.getPrescription(this._arcade)!.wireType;
    switch (material) {
      case Material.TMA:
        if (isSquared) {
          return Constants.TMA_SQUARED_MAX_ANGLE;
        } else {
          return Constants.TMA_ROUNDED_MAX_ANGLE;
        }
      case Material.TWISTED_GOLD:
        return Constants.TWISTED_GOLD_ROUNDED_MAX_ANGLE;
      case Material.TWISTED_STEEL:
        return Constants.TWISTED_STEEL_ROUNDED_MAX_ANGLE;
    }
  }

  private _getWirePath(
    p_points: Vector3[],
    p_material: Material | undefined,
    p_isSquared: boolean | undefined,
    p_dimension: Dimension | undefined
  ): {
    path: CurvePath<Vector3>;
    steep: CurvePath<Vector3>[];
    short: LineCurve3[];
  } {
    // get wire fabrication limits
    const fabMinSegLengthCurve = this._getMinSegLengthCurve(
      p_material,
      p_isSquared,
      p_dimension
    );
    const fabMaxAngle = this._getMaxAngle(p_material, p_isSquared);

    const path: CurvePath<Vector3> = new CurvePath(); // wire path
    const steep: CurvePath<Vector3>[] = []; // steep curves
    const short: LineCurve3[] = []; // short segments

    let prevLineStart: Vector3 = p_points[0];

    //Calculs basés sur les formules de la tâche notion et appliqués sur les p_points du fil pris en haut
    //notion.so/winnove/Repr-sentation-3D-du-fil-utiliser-des-segments-arcs-de-tore-plutot-qu-une-spline-8ce9e63b1d224163a2529b406604f903
    //On commence à 1 car on se base sur B (pour l'angle ABC) et à chaque tour on ajoute aux CurvePaths le segment et l'arc de pli correspondant
    //(si dernier point on ajoute aussi le dernier segment)
    for (let i = 1; i < p_points.length - 1; i++) {
      const A = p_points[i - 1];
      const B = p_points[i];
      const C = p_points[i + 1];
      const BA = A.clone().sub(B);
      const BC = C.clone().sub(B);
      //Vecteur du plan ABC pour que l'arc de pli soit bien incliné
      const rotationAxis = BA.clone().cross(BC).normalize();
      //Utilisé pour calculer BD (ou BE) et check si l'angle est trop serré
      const angleABC = BA.angleTo(BC);
      //Longueurs BD (BE) et BF pour récupérer leurs positions
      const lenBD = Constants.BEND_ANGLE_RADIUS / Math.tan(angleABC / 2);
      const lenBF = Math.sqrt(
        Constants.BEND_ANGLE_RADIUS * Constants.BEND_ANGLE_RADIUS +
          lenBD * lenBD
      );
      const F = B.clone().add(
        BC.clone()
          .normalize()
          .add(BA.clone().normalize())
          .normalize()
          .multiplyScalar(lenBF)
      );
      const E = B.clone().add(BA.clone().multiplyScalar(lenBD / BA.length()));
      const D = B.clone().add(BC.clone().multiplyScalar(lenBD / BC.length()));
      //Angles en radian du début et de la fin de l'arc pour avoir la longueur de celui-ci
      const FD = D.clone().sub(F);
      const ED = D.clone().sub(E).normalize();
      const startAngle = Math.acos(FD.dot(ED) / FD.length());
      const FE = E.clone().sub(F);
      const endAngle = Math.acos(FE.dot(ED) / FE.length());

      const segmentAngle =
        (endAngle - startAngle) / Constants.BEND_ARCH_SEGMENTS_NB;
      const nextAngle = 180 - MathUtils.radToDeg(angleABC);
      const steep_angle = nextAngle > fabMaxAngle;
      if (steep_angle) steep.push(new CurvePath());
      const segLength =
        prevLineStart.distanceTo(E) +
        segmentAngle *
          Constants.BEND_ANGLE_RADIUS *
          Constants.BEND_ARCH_SEGMENTS_NB;
      let minLength = 0;
      for (let j = 0; j < fabMinSegLengthCurve.length; j++)
        minLength += fabMinSegLengthCurve[j] * Math.pow(nextAngle, j);

      if (segLength < minLength) short.push(new LineCurve3(prevLineStart, E));

      path.add(new LineCurve3(prevLineStart, E));
      if (MathUtils.radToDeg(angleABC) < 180 && endAngle - startAngle > 0) {
        for (
          let j = 0, angle = 0, prevSegmentStart = E;
          j < Constants.BEND_ARCH_SEGMENTS_NB;
          j++
        ) {
          angle -= segmentAngle;
          const FE: Vector3 = E.clone().sub(F);
          FE.applyAxisAngle(rotationAxis, angle);
          FE.normalize().multiplyScalar(Constants.BEND_ANGLE_RADIUS);
          const H: Vector3 = FE.add(F);
          path.add(new LineCurve3(prevSegmentStart, H));
          if (steep_angle)
            steep[steep.length - 1].add(new LineCurve3(prevSegmentStart, H));
          prevSegmentStart = H;
        }
      }
      if (i == p_points.length - 2) {
        path.add(new LineCurve3(D, C));
      }
      prevLineStart = D;
    }
    return { path, steep, short };
  }

  private _getSquareShape(p_radius: number): Shape {
    const shape = new Shape();
    shape.moveTo(-p_radius, -p_radius);
    shape.lineTo(-p_radius, p_radius);
    shape.lineTo(p_radius, p_radius);
    shape.lineTo(p_radius, -p_radius);
    shape.lineTo(-p_radius, -p_radius);
    return shape;
  }

  private _getWireRadius(p_dimension: Dimension | undefined): number {
    return WinnoveHelper.inchDiameterDimensionToMMRadius(
      p_dimension ?? this._project!.getPrescription(this._arcade)!.dimension
    );
  }

  private _getSquaredWireMesh(
    p_points: Vector3[],
    p_color: number | undefined,
    p_dimension: Dimension | undefined,
    p_isSquared: boolean | undefined,
    p_material: Material | undefined
  ): Mesh {
    const { path, steep, short } = this._getWirePath(
      p_points,
      p_material,
      p_isSquared,
      p_dimension
    );
    const radius = this._getWireRadius(p_dimension);
    // Draw complete wire
    const wire_geometry = new ExtrudeGeometry(this._getSquareShape(radius), {
      steps: 512,
      extrudePath: path,
    });
    wire_geometry.computeBoundsTree({ maxLeafTris: 1, strategy: SAH });
    const mesh = new Mesh(wire_geometry, this._wireMaterial);
    // Draw color angles
    for (const element of steep) {
      const geometry = new ExtrudeGeometry(this._getSquareShape(radius * 1.1), {
        steps: 512,
        extrudePath: element,
      });
      const segment_mesh = new Mesh(geometry, this._angleMaterial);
      mesh.add(segment_mesh);
    }

    // Draw color segments
    for (const element of short) {
      const geometry = new ExtrudeGeometry(
        this._getSquareShape(radius * 1.15),
        { steps: 512, extrudePath: element }
      );
      const segment_mesh = new Mesh(geometry, this._segMaterial);
      mesh.add(segment_mesh);
    }

    return mesh;
  }

  private _getRoundedWireMesh(
    p_points: Vector3[],
    p_color: number | undefined,
    p_dimension: Dimension | undefined,
    p_isSquared: boolean | undefined,
    p_material: Material | undefined
  ): Mesh {
    const { path, steep, short } = this._getWirePath(
      p_points,
      p_material,
      p_isSquared,
      p_dimension
    );
    const radius = this._getWireRadius(p_dimension);
    // Draw complete wire
    const wire_geometry = new TubeGeometry(path, 256, radius, 8, false);
    wire_geometry.computeBoundsTree({ maxLeafTris: 1, strategy: SAH });
    const mesh = new Mesh(wire_geometry, this._wireMaterial);
    // Draw wire extremities
    const geometrySphere = new SphereGeometry(radius, 8, 8);
    // start point
    const startPoint = new Mesh(geometrySphere, this._wireMaterial);
    startPoint.position.set(p_points[0].x, p_points[0].y, p_points[0].z);
    mesh.add(startPoint);
    // end point
    const endPoint = new Mesh(geometrySphere, this._wireMaterial);
    endPoint.position.set(
      p_points[p_points.length - 1].x,
      p_points[p_points.length - 1].y,
      p_points[p_points.length - 1].z
    );
    mesh.add(endPoint);
    // Draw color angles
    for (const element of steep) {
      const geometry = new TubeGeometry(element, 50, radius * 1.1, 8, false);
      const segment_mesh = new Mesh(geometry, this._angleMaterial);
      mesh.add(segment_mesh);
    }

    // Draw color segments
    for (const element of short) {
      const geometry = new TubeGeometry(element, 50, radius * 1.15, 8, false);
      const segment_mesh = new Mesh(geometry, this._segMaterial);
      mesh.add(segment_mesh);
    }

    return mesh;
  }

  public getWireMesh(
    p_points: Vector3[],
    p_color: number | undefined = undefined,
    p_dimension: Dimension | undefined = undefined,
    p_isSquared: boolean | undefined = undefined,
    p_material: Material | undefined = undefined
  ): Mesh {
    if (
      (this._arcade != Arcade.NONE &&
        this._project!.getPrescription(this._arcade)!.wireType ===
          WireType.SQUARED) ||
      p_isSquared
    )
      return this._getSquaredWireMesh(
        p_points,
        p_color,
        p_dimension,
        p_isSquared,
        p_material
      );
    else
      return this._getRoundedWireMesh(
        p_points,
        p_color,
        p_dimension,
        p_isSquared,
        p_material
      );
  }

  public updateWireMesh(p_visible: boolean): void {
    // check if there is a prescription
    if (!this._project?.getPrescription(this._arcade)) {
      return;
    }

    // get wire points
    const points = this._getWirePoints();
    if (points.length < 2) {
      return;
    }

    // Remove previous mesh
    this.disposeMesh();

    const wireDim = this._project.getPrescription(this._arcade)!.dimension;
    const wireMaterial = this._project.getPrescription(this._arcade)!.material;
    const isWireSquared =
      this._project.getPrescription(this._arcade)!.wireType ===
      WireType.SQUARED;
    // Create new mesh
    this.mesh = this.getWireMesh(
      points,
      undefined,
      wireDim,
      isWireSquared,
      wireMaterial
    );
    this.mesh.name =
      "wire" + (this._arcade === Arcade.MAXILLA ? "maxilla" : "mandible");

    // Add mesh to scene
    this.scene.add(this.mesh);
    this.setMeshVisible(p_visible);
  }

  public unselectPoints(): void {
    this._setColorToPoints(Constants.WIRE_POINT_UNSELECTED_COLOR);
  }

  public selectPoint(id: number): void {
    if (id < 0 || id >= this.points.length) {
      Logger.getInstance().error(
        "Invalid point id",
        "Impossible de sélectionner ce point."
      );
      return;
    }
    (this.points[id].m.material as MeshPhongMaterial).color.set(
      Constants.WIRE_POINT_SELECTED_COLOR
    );
    this._controls.translateControls[GimbalIndex.BASE_PLANE_ANTERO].attach(
      this.points[id].m
    );
  }

  private _getPointBaseName(): string {
    const ptBaseName =
      this._arcade === Arcade.MANDIBLE
        ? Constants.MESH_MANDIBLE_NAME
        : Constants.MESH_MAXILLA_NAME;
    return ptBaseName;
  }

  private _updateLines(
    lineType: LineIndex,
    p_visible: boolean,
    linesBufferArray: number[],
    p_scan: Mesh
  ): void {
    if (linesBufferArray.length && p_visible) {
      const geometry = this._lines[lineType].geometry;
      geometry.dispose();
      geometry.setAttribute(
        "position",
        new BufferAttribute(new Float32Array(linesBufferArray), 3, false)
      );

      geometry.setDrawRange(0, linesBufferArray.length / 3);
      geometry.attributes.position.needsUpdate = true;
      this._lines[lineType].position.copy(p_scan.position);
      this._lines[lineType].rotation.copy(p_scan.rotation);
      this._lines[lineType].scale.copy(p_scan.scale);
    }
    //its not possible that points of the same wire are visible and not visible so just check the 1rst one
    if (lineType === LineIndex.ZONE) {
      if (this.points[0] && this.points[0].m.visible)
        this._lines[lineType].visible = p_visible;
      else this._lines[lineType].visible = false;
    } else if (this.mesh?.visible) this._lines[lineType].visible = p_visible;
    else this._lines[lineType].visible = false;
  }

  public updateZonePoints(p_visible: boolean, p_scan: Mesh): void {
    const results: number[] = [];
    this.points.forEach((point) => {
      point.z.position.copy(point.m.position);
      this.scene.updateMatrixWorld(true);

      const matrix2to1 = new Matrix4()
        .copy(p_scan.matrixWorld)
        .invert()
        .multiply(point.z.matrixWorld);
      const edge = new Line3();

      // if boundsTree isnt in geometry, the asynchronous MeshBVH loading hasnt finished yet
      if (p_scan.geometry.boundsTree) {
        p_scan.geometry.boundsTree.bvhcast(
          point.z.geometry.boundsTree!,
          matrix2to1,
          {
            intersectsTriangles(triangle1, triangle2) {
              if (triangle1.intersectsTriangle(triangle2, edge)) {
                const { start, end } = edge;
                results.push(start.x, start.y, start.z, end.x, end.y, end.z);
              }
              return false;
            },
          }
        );
      }
    });

    this._updateLines(LineIndex.ZONE, p_visible, results, p_scan);
  }

  public updatePenetration(p_visible: boolean, p_scan: Mesh): void {
    const results: number[] = [];
    if (this.mesh) {
      this.scene.updateMatrixWorld(true);
      const matrix2to1 = new Matrix4()
        .copy(p_scan.matrixWorld)
        .invert()
        .multiply(this.mesh.matrixWorld);
      const edge = new Line3();

      // if boundsTree isnt in geometry, the asynchronous MeshBVH loading hasnt finished yet
      if (p_scan.geometry.boundsTree) {
        p_scan.geometry.boundsTree.bvhcast(
          this.mesh.geometry.boundsTree!,
          matrix2to1,
          {
            intersectsTriangles(triangle1, triangle2) {
              if (triangle1.intersectsTriangle(triangle2, edge)) {
                const { start, end } = edge;
                results.push(start.x, start.y, start.z, end.x, end.y, end.z);
              }
              return false;
            },
          }
        );
      }
    }

    this._updateLines(LineIndex.PENETRATION, p_visible, results, p_scan);
  }

  public updateFitVisualisation(p_visible: boolean, p_scan: Mesh): void {
    const results: number[] = [];
    if (this.mesh) {
      this.scene.updateMatrixWorld(true);
      const matrixWorldToBvh = new Matrix4().copy(p_scan.matrixWorld).invert();
      const points = this._getWirePoints();
      const { path } = this._getWirePath(
        points,
        undefined,
        undefined,
        undefined
      );
      const pathPoints = path.getSpacedPoints(1000);
      pathPoints.forEach((point) => {
        const targetPoint = point.clone().applyMatrix4(matrixWorldToBvh);
        const distanceResult =
          p_scan.geometry.boundsTree?.closestPointToPoint(targetPoint);
        if (distanceResult) {
          // add a line from the point to the closest point on the mesh
          const pStart = targetPoint;
          const pEnd = distanceResult.point;
          results.push(pStart.x, pStart.y, pStart.z, pEnd.x, pEnd.y, pEnd.z);
        }
      });
    }

    this._updateLines(LineIndex.FIT, p_visible, results, p_scan);
  }

  //fonction appellée à chaque fois que les wiregimbals doivent être update (losqu'on active une wiregimbal,
  //sélectionne un autre point ou bouge le point sélectionné), elle défini 3 coordonnées (si on a sélectionné un point
  //à l'extrémité A est le point sélectionné/B est le point voisin/C est la simetrique de A par rapport à B, sinon B
  //est le point sélectionnée est A et C sont ses points voisins) puis rotate chaque mesh en fonction de ces
  //coordonnées pour afficher correctement les wiregimbals correspondantes
  public rotateWireGimbals(p_id: number): void {
    //défini les coordonnées en fonction du point sélectionné
    //point avant (à gauche) le point sélectionné
    const A =
      this.points[
        !p_id
          ? 0 //replaced later
          : p_id == this.points.length - 1
            ? this.points.length - 2
            : p_id - 1
      ].m.position.clone();
    //point sélectionné
    const B = this.points[p_id].m.position.clone();
    //point aprés (à droite) le point sélectionné
    const C = !p_id
      ? this.points[1].m.position.clone()
      : p_id == this.points.length - 1
        ? A.clone().sub(B).negate().add(B)
        : this.points[p_id + 1].m.position.clone();
    if (!p_id) A.copy(C.clone().sub(B).negate().add(B));

    // réoriente les meshes (auxquels sont attachés les wiregimbals correspondantes) par rapport aux points ci-dessus
    this._controls.translateControls[
      GimbalIndex.BASE_PLANE_ANTERO
    ].object!.rotation.setFromQuaternion(
      new Quaternion().setFromUnitVectors(
        new Vector3(0, 0, 1),
        new Vector3(0, 0, 0).sub(B).negate().normalize()
      )
    );
    this._controls.translateControls[
      GimbalIndex.TRANSVERSAL_1
    ].object!.rotation.setFromQuaternion(
      new Quaternion().setFromUnitVectors(
        new Vector3(1, 0, 0),
        C.clone().sub(B).normalize()
      )
    );
    this._controls.translateControls[
      GimbalIndex.TRANSVERSAL_2
    ].object!.rotation.setFromQuaternion(
      new Quaternion().setFromUnitVectors(
        new Vector3(1, 0, 0),
        A.clone().sub(B).normalize()
      )
    );
    //no vertical gimbal rotation for now as it's just the base 0 1 0 y axis
  }

  public movePoint(
    p_positive: boolean,
    p_id: number,
    p_type: GimbalType
  ): void {
    this.points[p_id].m.position.add(
      new Vector3(
        Number(p_type === GimbalType.TRANSVERSAL),
        Number(p_type === GimbalType.VERTICAL),
        Number(p_type === GimbalType.ANTERO_POSTERIOR)
      )
        .applyEuler(
          this._controls.translateControls[this.getGimbalIndexFromType(p_type)]
            .object!.rotation
        )
        .normalize()
        .multiplyScalar(0.1 * (p_positive ? 1 : -1))
    );
  }

  private getGimbalIndexFromType(p_type: GimbalType): GimbalIndex {
    switch (p_type) {
      case GimbalType.ANTERO_POSTERIOR:
        return GimbalIndex.BASE_PLANE_ANTERO;
      case GimbalType.TRANSVERSAL:
        return GimbalIndex.TRANSVERSAL_1;
      case GimbalType.VERTICAL:
        return GimbalIndex.VERTICAL;
      default:
        return GimbalIndex.NONE;
    }
  }

  public setCheckMinDistance(p_check: boolean): void {
    this._performDistanceChecks = p_check;
  }

  public getPointPosition(p_id: number): Vector3 {
    return this._getPointPosition(p_id);
  }

  public getPointMeshPosition(p_id: number): Vector3 {
    return this._getPointMeshPosition(p_id);
  }
}
