<template>
  <div>
    <div class="d-flex flex-row my-2 mx-4">
      <ProductionProjectTableStatsChip
        :p_number="p_criticalNumber"
        p_color="secondary"
        :p_icon="mdiFire"
        :p_loading="p_loading"
        :p_late="true"
      ></ProductionProjectTableStatsChip>
      <ProductionProjectTableStatsChip
        :p_number="p_urgentNumber"
        p_color="wGrey"
        :p_icon="mdiFire"
        :p_loading="p_loading"
      ></ProductionProjectTableStatsChip>
    </div>
  </div>
</template>

<script lang="ts">
import { mdiFire } from "@mdi/js";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProductionProjectTableStats",
  props: {
    p_criticalNumber: {
      type: Number,
      required: true,
    },
    p_urgentNumber: {
      type: Number,
      required: true,
    },
    p_loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    return { mdiFire };
  },
});
</script>
