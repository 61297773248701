<template>
  <w-container fluid>
    <w-card class="bg-white">
      <w-card-title class="card-title-w-btn"
        >Capacité de production
        <w-btn
          color="primary"
          @click="redispatchOrders"
          >Redistribuer les tâches de productions</w-btn
        >
      </w-card-title>
      <w-card-text>
        <w-row>
          <w-col
            md="6"
            class="text-right"
          >
            <w-text-field
              v-model="m_week"
              type="week"
              required
            />
          </w-col>
          <w-col md="6">
            <w-text-field
              v-model="m_weekProductionCapacity"
              type="number"
              @change="updateWeekProductionCapacity"
            ></w-text-field>
          </w-col>
        </w-row>
        <w-row>
          <w-col md="12">
            <Bar
              v-if="m_dataReady"
              chart-id="chart"
              :data="m_chartData"
              :height="50"
            /> </w-col
        ></w-row>
      </w-card-text>
    </w-card>

    <w-data-table
      class="table_config mt-4 text-primary"
      :headers="m_headers"
      :items="m_configs"
      item-key="id"
      :items-per-page="20"
    >
      <template v-slot:body="{ items }">
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td>{{ item.id }}</td>
          <td>
            <EditableDataTableValue
              v-model:p_data="item.paramKey"
              @save="updateConfig(item as Config)"
              notempty
            />
          </td>
          <td>
            <EditableDataTableValue
              v-model:p_data="item.paramValue"
              :p_wrapContent="true"
              @save="updateConfig(item as Config)"
              notempty
            />
          </td>
        </tr>
      </template>
    </w-data-table>

    <w-expansion-panels
      v-model="m_panel"
      class="mt-4"
    >
      <w-expansion-panel>
        <w-expansion-panel-title>
          Ajouter une configuration
        </w-expansion-panel-title>
        <w-expansion-panel-text>
          <w-form
            ref="formNewConfig"
            v-model="m_isNewConfigFormValid"
            @submit.prevent="addConfig()"
            nowrap
          >
            <w-text-field
              v-model.trim="m_newConfig.paramKey"
              label="Clé"
              :rules="[(v) => (v && !!v.trim()) || 'Clé requise']"
              required
              autofocus
            ></w-text-field>

            <w-text-field
              v-model.trim="m_newConfig.paramValue"
              label="Valeur"
              :rules="[(v) => (v && !!v.trim()) || 'Valeur requis']"
              required
            ></w-text-field>
            <div class="d-flex justify-end">
              <w-btn
                :disabled="!m_isNewConfigFormValid"
                type="submit"
                color="white"
                >Ajouter
              </w-btn>
            </div>
          </w-form>
        </w-expansion-panel-text>
      </w-expansion-panel>
    </w-expansion-panels>
  </w-container>
</template>

<script lang="ts">
import ConfigHelper from "@/helpers/ConfigHelper";
import DateHelper from "@/helpers/DateHelper";
import Config from "@/models/Config";
import { ConfigConstants } from "@/shared/constants";
import Logger from "@/shared/logger";
import axios, { AxiosResponse } from "axios";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useRepo } from "pinia-orm";
import { defineComponent, onMounted, ref, watch } from "vue";
import { Bar } from "vue-chartjs";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default defineComponent({
  name: "ConfigTable",
  components: { Bar },
  props: {},
  setup(props, context) {
    const logger = Logger.getInstance();
    const formNewConfig = ref<InstanceType<typeof HTMLFormElement> | null>(
      null
    );
    const m_panel = ref(-1);
    const m_configs = ref<Config[]>([]);
    const m_isNewConfigFormValid = ref(true);
    const m_newConfig = ref(new Config());
    const m_week = ref("");
    const m_weekProductionCapacity = ref("0");
    const m_productionCapacity = ref<number[]>([]);
    const m_productionCharge = ref<number[]>([]);
    const m_chartData = ref(getChartData());
    const m_dataReady = ref(false);

    const m_headers = [
      {
        title: "ID",
        align: "start",
        value: "id",
        sortable: true,
        filterable: true,
      },
      {
        title: "CLÉ",
        align: "start",
        value: "paramKey",
        sortable: true,
        filterable: true,
      },
      {
        title: "VALEUR",
        align: "start",
        value: "paramValue",
        sortable: true,
        filterable: true,
      },
    ];

    onMounted(() => {
      refresh();
      const currentdate = new Date();
      m_week.value =
        "" +
        currentdate.getFullYear() +
        "-W" +
        DateHelper.getCurrentWeekNumber();
    });

    async function refresh(): Promise<void> {
      m_configs.value = useRepo(Config).all();
      m_productionCapacity.value = Config.getProductionCapacity();

      await axios
        .get("projects/production_charge")
        .then((p_response: AxiosResponse) => {
          m_productionCharge.value = new Array<number>(53).fill(0);

          p_response.data.charge.forEach((p_element: any) => {
            m_productionCharge.value[p_element.week - 1] = p_element.charge;
          });
        });

      m_chartData.value = getChartData();

      m_dataReady.value = true;
    }

    async function redispatchOrders(): Promise<void> {
      await axios.post("orders/redispatchOrders").then(() => {
        logger.info("Tâches de production correctement redistribuées");
      });
    }

    function getChartData(): any {
      return {
        labels: Array.from({ length: 53 }, (_, i) => (i + 1).toString()),
        datasets: [
          {
            label: "Capacité",
            backgroundColor: "#cccccc",
            data: m_productionCapacity.value,
          },
          {
            label: "Charge",
            backgroundColor: "primary",
            data: m_productionCharge.value,
          },
        ],
      };
    }

    async function addConfig(): Promise<void> {
      await ConfigHelper.addConfig(m_newConfig.value as Config).then(() => {
        formNewConfig.value?.reset();
        m_newConfig.value = new Config();
        m_panel.value = -1;
        refresh();
        Logger.getInstance().success("Configuration ajoutée avec succès.");
      });
    }

    async function updateConfig(p_config: Config): Promise<void> {
      await ConfigHelper.updateConfig(p_config).then(() => {
        refresh();
        Logger.getInstance().success("Configuration modifiée avec succès.");
      });
    }

    function updateWeekProductionCapacity(): void {
      m_productionCapacity.value[
        parseInt(m_week.value.substring(m_week.value.indexOf("-W") + 2)) - 1
      ] = parseInt(m_weekProductionCapacity.value);

      const config: Config = useRepo(Config)
        .query()
        .where("paramKey", ConfigConstants.PRODUCTION_CAPACITY_KEY)
        .first()!;
      config.paramValue = JSON.stringify(m_productionCapacity.value);
      updateConfig(config);
    }

    watch(m_week, () => {
      const week: number = parseInt(
        m_week.value.substring(m_week.value.indexOf("-W") + 2)
      );
      m_weekProductionCapacity.value =
        m_productionCapacity.value[week - 1].toString();
    });

    return {
      formNewConfig,
      m_panel,
      m_configs,
      m_isNewConfigFormValid,
      m_newConfig,
      m_week,
      m_weekProductionCapacity,
      m_productionCapacity,
      m_productionCharge,
      m_chartData,
      m_headers,
      m_dataReady,
      refresh,
      addConfig,
      updateConfig,
      updateWeekProductionCapacity,
      redispatchOrders,
    };
  },
});
</script>

<style lang="scss">
.table_config {
  line-height: 1;
}

.card-title-w-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  button {
    margin-bottom: 10px;
  }
}
</style>
