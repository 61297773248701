<template>
  <EditableDataTableMenu
    @cancel="cancel"
    @save="save"
  >
    <template #data
      ><OrderStateChip
        link
        :p_privilege="Privilege.ADMIN"
        :p_state="m_data"
    /></template>

    <template #default>
      <w-select
        v-model="m_data"
        :items="m_items"
        item-title="text"
        item-value="value"
        hide-details
        outlined
        autofocus
      ></w-select>
    </template>
  </EditableDataTableMenu>
</template>

<script lang="ts">
import { EnumHelper } from "@/helpers/EnumHelper";
import { Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent, onMounted, ref } from "vue";
import EditableDataTableMenu from "./EditableDataTableMenu.vue";

export default defineComponent({
  name: "EditableDataTableSelect",
  inheritAttrs: false,
  props: {
    p_data: {
      // prop sync
      type: Number,
      default: 0,
    },
    p_itemvalues: {
      type: Object,
      default() {
        return {};
      },
    },
    p_itemtexts: {
      type: Array as () => string[],
      default() {
        return [];
      },
    },
    p_hover: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const m_data = computed({
      get: () => props.p_data,
      set: (value: number) => context.emit("update:p_data", value),
    });

    const m_dataSave = ref(props.p_data);

    const m_items = computed(() => {
      return EnumHelper.getSelectListAsArray(
        props.p_itemvalues,
        props.p_itemtexts
      );
    });

    onMounted(() => {
      m_dataSave.value = m_data.value;
    });

    function cancel(): void {
      m_data.value = m_dataSave.value;
    }

    function save(): void {
      m_dataSave.value = m_data.value;
      context.emit("save");
    }

    return {
      Privilege,
      m_data,
      m_items,
      save,
      cancel,
    };
  },
  components: { EditableDataTableMenu },
});
</script>
