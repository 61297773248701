<template>
  <div class="px-6 py-2 overflow-y-auto summary-container">
    <!-- Order completion alert -->
    <w-alert
      v-if="p_order.hasNotBeenOrdered(p_privilege)"
      type="info"
      variant="tonal"
    >
      <w-col class="grow pa-0">
        Veuillez Compléter la commande.
        <span
          v-if="
            p_privilege == Privilege.FREEMIUM ||
            p_privilege == Privilege.PREMIUM
          "
          ><br /><br />
          Toute commande non complète 48h après sa date de création sera
          <strong>automatiquement annulée</strong>.
        </span>
      </w-col>
      <w-col
        v-if="!p_isEditing"
        class="shrink pa-0 mt-2"
      >
        <w-btn
          @click="$emit('open-project')"
          block
          :append-icon="mdiArrowRight"
          >Compléter</w-btn
        >
      </w-col>
    </w-alert>

    <!-- Order detail -->
    <div v-if="!p_order.toBeCompleted()">
      <div
        v-if="m_wireValidationStatus === WireValitationAction.NONE"
        class="d-flex flex-row justify-space-between flex-md-column"
      >
        <!-- Matériau -->
        <WireSummaryOrderDetail
          :p_orderCancelled="p_order.isCancelled()"
          :p_checked="true"
          p_label="Matériau"
          :p_value="
            MaterialString[p_order.wire.material] +
            ' ' +
            DimensionString[p_order.wire.dimension]
          "
        />

        <!-- Dents -->
        <WireSummaryOrderDetail
          :p_orderCancelled="p_order.isCancelled()"
          :p_checked="true"
          p_label="Dents"
          :p_value="p_order.wire.teethRange"
        />
      </div>

      <div class="d-none d-md-flex flex-md-column">
        <!-- Clé de positionnement -->
        <WireSummaryOrderDetail
          v-if="
            m_wireValidationStatus === WireValitationAction.NONE &&
            p_privilege !== Privilege.IN_OFFICE
          "
          :p_orderCancelled="p_order.isCancelled()"
          :p_checked="!!p_order.wire.keyNeeded"
          p_label="Clé de positionnement"
          :p_value="p_order.wire.keyNeeded ? 'Oui' : 'Non'"
        />

        <!-- Demande de validation -->
        <WireSummaryOrderDetail
          v-if="
            m_wireValidationStatus === WireValitationAction.NONE &&
            p_privilege !== Privilege.IN_OFFICE
          "
          :p_orderCancelled="p_order.isCancelled()"
          :p_checked="p_order.validationNeed"
          p_label="Demande de validation du dessin"
          :p_value="p_order.validationNeed ? 'Oui' : 'Non'"
        />
      </div>

      <!-- Send to prod -->
      <div
        v-if="m_wireValidationStatus === WireValitationAction.NONE"
        class="mt-4"
      >
        <w-btn
          v-if="
            p_privilege == Privilege.ADMIN &&
            p_order.canSendToProd() &&
            (!p_order.wasValidatedByDr() || p_isEditing)
          "
          @click="sendToProd()"
          :disabled="
            p_order.validationNeed &&
            !p_order.hasBeenUpdated() &&
            !p_order.wasValidatedByDr()
          "
          block
        >
          Envoyer en production.
        </w-btn>
        <w-btn
          v-if="
            p_privilege == Privilege.IN_OFFICE &&
            p_order.canSendToProd() &&
            (!p_order.wasValidatedByDr() || p_isEditing)
          "
          @click="sendToProd()"
          block
        >
          Envoyer au robot.
        </w-btn>
      </div>

      <!-- Request validation -->
      <div
        v-if="m_wireValidationStatus === WireValitationAction.NONE"
        class="mt-4"
      >
        <w-btn
          v-if="
            p_privilege == Privilege.ADMIN &&
            p_order.canRequestValidation() &&
            !p_order.hasBeenUpdated()
          "
          @click="requestValidation()"
          block
        >
          Demande de validation.
        </w-btn>
      </div>

      <!-- Mark as made -->
      <div
        v-if="m_wireValidationStatus === WireValitationAction.NONE"
        class="mt-4"
      >
        <w-btn
          v-if="p_privilege == Privilege.ADMIN && p_order.keyHasToBeMade()"
          @click="setKeyAsMade()"
          block
        >
          Marquer la clé fabriquée.
        </w-btn>
      </div>

      <!-- Mark as shipped -->
      <div
        v-if="
          m_wireValidationStatus === WireValitationAction.NONE &&
          p_privilege == Privilege.ADMIN &&
          p_order.toBeShipped()
        "
        class="mt-4"
      >
        <w-text-field
          v-model="m_trackingNumber"
          label="Numéro de suivi"
          :rules="[(v) => (v && !!v.trim()) || 'Numéro de suivi requis']"
          required
        ></w-text-field>
        <w-btn
          @click="markAsShipped(m_trackingNumber)"
          block
          :disabled="!m_trackingNumber"
        >
          Valider le numéro de suivi.
        </w-btn>
      </div>

      <!-- Mark as delivered -->
      <div
        v-if="m_wireValidationStatus === WireValitationAction.NONE"
        class="mt-4"
      >
        <w-btn
          v-if="p_privilege == Privilege.ADMIN && p_order.isShipped()"
          @click="markAsDelivered()"
          block
        >
          La commande a été livrée.
        </w-btn>
      </div>

      <!-- Download -->
      <div
        v-if="m_wireValidationStatus === WireValitationAction.NONE"
        class="mt-4"
      >
        <!-- close-on-click -->
        <w-menu
          offset-y
          transition="slide-y-transition"
          color="primary"
          v-model="m_downloadMenu"
        >
          <!-- Dropdown button -->
          <template v-slot:activator="{ props }">
            <w-btn
              variant="tonal"
              class="d-none d-md-flex justify-start"
              block
              v-bind="props"
              :iconRight="mdiChevronDown"
            >
              <span>Télécharger...</span>
            </w-btn>
          </template>

          <!-- Download options -->
          <w-list class="d-flex flex-column pa-0 bg-white">
            <STLDownloadButton
              v-if="p_project.orderMaxilla()?.canDownloadSTLI3D(p_privilege)"
              :p_arch="p_project.archMaxilla()"
              :p_privilege="p_privilege"
              :p_for3DPrinting="true"
            />
            <STLDownloadButton
              v-if="p_project.orderMandible()?.canDownloadSTLI3D(p_privilege)"
              :p_arch="p_project.archMandible()"
              :p_privilege="p_privilege"
              :p_for3DPrinting="true"
            />
            <STLDownloadButton
              v-if="hasMaxi()"
              :p_arch="p_project.archMaxilla()"
              :p_privilege="p_privilege"
              :p_for3DPrinting="false"
            />
            <STLDownloadButton
              v-if="hasMandi()"
              :p_arch="p_project.archMandible()"
              :p_privilege="p_privilege"
              :p_for3DPrinting="false"
            />
            <PDFDownloadButton
              :p_wire="p_order.wire"
              :p_privilege="p_privilege"
              @download="$refs.pdfDialog.download()"
            />
            <GCodeDownloadButton
              v-if="p_privilege !== Privilege.FREEMIUM"
              :p_privilege="p_privilege"
              :p_wire="p_order.wire"
            />
            <IGESDownloadButton
              v-if="p_privilege !== Privilege.FREEMIUM"
              :p_privilege="p_privilege"
              :p_wire="p_order.wire"
            />
          </w-list>
        </w-menu>

        <PDFDialog
          ref="pdfDialog"
          :p_wire="p_order.wire"
          :p_privilege="p_privilege"
          @refresh-wire="$emit('requestUpdateFromPinia')"
        ></PDFDialog>

        <div class="mt-4">
          <CommentArea
            v-if="
              (p_order.belongsToCurrentUser() &&
                p_order.clientCanEditComment()) ||
              m_clientComment.content.length > 0
            "
            v-model:p_comment="m_clientComment"
            :p_label="
              p_privilege === Privilege.ADMIN
                ? 'Commentaires Client'
                : 'Commentaires commande'
            "
            :p_readonly="
              !p_order.belongsToCurrentUser() || !p_order.clientCanEditComment()
            "
            @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
          />
          <div
            v-if="m_modifCommentReady"
            class="d-flex flex-row mb-0"
          >
            <CommentArea
              v-if="
                m_wireValidationStatus === WireValitationAction.NONE &&
                (m_updateComment.content.length > 0 ||
                  m_modificationImageLink !== '#')
              "
              data-cy="modification-comment-area"
              v-model:p_comment="m_updateComment"
              :p_label="
                p_privilege === Privilege.ADMIN
                  ? 'Modifications Client Demandées'
                  : 'Modifications Demandées'
              "
              :p_readonly="
                !(p_order.belongsToCurrentUser() && p_order.canValidate())
              "
              class="mr-4 flex-grow-1"
              @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
            />
            <w-hover
              v-if="
                m_modificationImageLink !== '#' &&
                m_wireValidationStatus === WireValitationAction.NONE
              "
              v-slot="{ isHovering }"
            >
              <a
                :href="m_modificationImageLink"
                target="_blank"
                style="position: relative"
              >
                <w-badge
                  :value="!isHovering"
                  class="mt-2 modification-image-div d-flex justify-center align-center"
                  overlap
                  :icon="mdiOpenInNew"
                  color="#9e9e9e"
                >
                  <img
                    data-cy="modification-image"
                    :src="m_modificationImageLink"
                    class="modification-image"
                    alt="Modification"
                  />

                  <div
                    v-if="isHovering"
                    class="modification-image-overlay"
                  >
                    <w-icon
                      color="white"
                      :icon="mdiOpenInNew"
                    ></w-icon>
                  </div>
                </w-badge>
              </a>
            </w-hover>
          </div>

          <!-- User general comment about design preferences -->
          <w-alert
            v-if="
              p_privilege === Privilege.ADMIN &&
              p_project.patient.user.preferences
            "
            type="info"
            color="wBlue"
            class="mt-2 mb-0 text-caption"
            density="compact"
            variant="tonal"
          >
            {{ p_project.patient.user.preferences }}
          </w-alert>
          <CommentArea
            v-if="p_privilege == Privilege.ADMIN"
            v-model:p_comment="m_internalComment"
            p_label="Commentaires Interne"
            @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
          />
          <CommentArea
            v-if="p_privilege == Privilege.ADMIN"
            v-model:p_comment="m_attestationComment"
            p_label="Commentaire Attestation"
            @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
          />
        </div>
      </div>

      <!-- Actions for this wire -->
      <div
        v-if="
          (p_isEditing &&
            p_privilege === Privilege.IN_OFFICE &&
            p_order.cancellable(p_privilege)) ||
          (m_wireValidationStatus === WireValitationAction.NONE &&
            p_isEditing &&
            p_order.belongsToCurrentUser() &&
            p_order.canValidate())
        "
        class="mt-4"
      >
        <w-menu
          v-model="m_doctorMenu"
          offset-y
          close-on-click
          transition="slide-y-transition"
        >
          <!-- Dropdown button -->
          <template v-slot:activator="{ props }">
            <w-btn
              data-cy="doctor-decision-button"
              block
              depressed
              v-bind="props"
              :variant="
                p_isEditing &&
                p_privilege === Privilege.IN_OFFICE &&
                p_order.cancellable(p_privilege)
                  ? 'outlined'
                  : 'elevated'
              "
              class="justify-start"
              :iconRight="mdiChevronDown"
            >
              Décision du praticien pour ce fil...
            </w-btn>
          </template>

          <!-- Wire actions options -->
          <w-card class="d-flex flex-column pa-0 bg-white">
            <!-- Submit validation -->
            <w-btn
              v-if="
                p_isEditing &&
                p_order.belongsToCurrentUser() &&
                p_order.canValidate()
              "
              data-cy="submit-immediate-validation-button"
              @click="submitValidation()"
              style="justify-content: start"
              variant="text"
              :prepend-icon="mdiCheckCircle"
              block
            >
              Valider le design du fil.
            </w-btn>

            <!-- Request update -->
            <w-btn
              v-if="
                p_isEditing &&
                p_order.belongsToCurrentUser() &&
                p_order.canValidate()
              "
              data-cy="request-update-button"
              style="width: 100%; justify-content: start"
              variant="text"
              :prepend-icon="mdiCommentEdit"
              @click="
                m_wireValidationStatus =
                  WireValitationAction.REQUEST_UPDATE_ASK_IMAGE
              "
              block
            >
              Demander une modification.
            </w-btn>

            <!-- Edit wire -->
            <w-btn
              v-if="
                p_privilege === Privilege.PREMIUM &&
                p_isEditing &&
                p_order.belongsToCurrentUser() &&
                p_order.canValidate()
              "
              @click="validationEditWire()"
              data-cy="edit-wire-button"
              class="d-none d-md-flex"
              style="justify-content: start"
              variant="text"
              :prepend-icon="mdiCircleEditOutline"
              block
            >
              Modifier le fil.
            </w-btn>

            <!-- Edit wire (IN OFFICE after design was sent to the robot) -->
            <w-btn
              v-if="
                p_privilege === Privilege.IN_OFFICE &&
                p_isEditing &&
                p_order.belongsToCurrentUser() &&
                p_order.isSentToRobot()
              "
              @click="setOrderStatus(OrderState.TO_CONFIRM)"
              data-cy="edit-wire-button"
              class="d-none d-md-flex"
              style="justify-content: start"
              variant="text"
              :prepend-icon="mdiCircleEditOutline"
              block
            >
              Modifier le fil.
            </w-btn>

            <!-- Cancel the order -->
            <!-- Cancel button -->
            <w-btn
              data-cy="cancel-wire-button"
              color="error"
              style="justify-content: start"
              variant="text"
              :prepend-icon="mdiCloseCircle"
              block
              @click="cancelDialog?.show()"
            >
              <span v-if="p_order.jaw === Arcade.MANDIBLE"
                >Annuler la Mandibule.</span
              >
              <span v-else>Annuler le Maxillaire.</span>
            </w-btn>
          </w-card>
        </w-menu>

        <!-- Cancel dialog -->
        <BaseDialog
          ref="cancelDialog"
          max-width="600px"
          :p_title="'Annuler le fil ' + m_wireTypeString + ' ?'"
          :p_subtitle="
            'Voulez-vous vraiment annuler le fil ' + m_wireTypeString + ' ?'
          "
        >
          <!-- Cancel dialog content -->
          <w-card-actions class="justify-end">
            <w-btn
              variant="tonal"
              @click="cancelDialog?.close()"
            >
              Non, je veux garder le fil
            </w-btn>
            <w-btn
              color="error"
              variant="tonal"
              @click="cancelOrder()"
            >
              Oui, je confirme
            </w-btn>
          </w-card-actions>
        </BaseDialog>
      </div>
      <!-- wire edit request -->
      <!-- Ask image -->
      <div
        v-if="
          m_wireValidationStatus ===
          WireValitationAction.REQUEST_UPDATE_ASK_IMAGE
        "
      >
        <w-card
          class="pa-0 pt-0 ma-0 mt-0 reduce-br"
          elevation="0"
        >
          <!-- show wire config -->
          <w-card-title class="text-h6 pa-2">
            Dessiner sur l'image ?
            <p class="text-caption font-weight-light ma-0">
              En cliquant sur "oui", la vue 3D actuelle sera figée et vous
              pourrez dessiner sur l'image pour indiquer les modifications à
              apporter au fil.
            </p>
          </w-card-title>

          <w-card-actions class="justify-space-around">
            <!-- Skip drawing -->
            <w-btn
              data-cy="skip-drawing-button"
              size="small"
              variant="tonal"
              color="white"
              @click="
                m_wireValidationStatus = WireValitationAction.REQUEST_UPDATE
              "
            >
              non
            </w-btn>
            <!-- Yes, draw on image -->
            <w-btn
              data-cy="draw-on-image-button"
              size="small"
              color="white"
              @click="drawOnImage()"
            >
              Oui
            </w-btn>
          </w-card-actions>
        </w-card>
      </div>

      <!-- Check image -->
      <div
        v-if="
          m_wireValidationStatus ===
          WireValitationAction.REQUEST_UPDATE_CHECK_IMAGE
        "
      >
        <w-card
          class="pa-0 pt-0 ma-0 mt-0 reduce-br"
          elevation="0"
        >
          <!-- show wire config -->
          <w-card-title class="text-h6 pa-2">
            Dessinez sur l'image
            <p class="text-caption font-weight-light ma-0">
              Vous pouvez désormais dessiner sur l'image. Passez à l'étape
              suivante pour ajouter un commentaire et envoyer la demande.
            </p>
          </w-card-title>

          <w-card-actions class="justify-space-around">
            <!-- go back to drawing choice -->
            <w-btn
              data-cy="go-back-to-drawing-choice-button"
              size="small"
              variant="tonal"
              color="white"
              @click="goBackToAskImage()"
            >
              Retour
            </w-btn>
            <!-- continue after drawing -->
            <w-btn
              data-cy="continue-after-drawing-button"
              size="small"
              color="white"
              @click="
                m_wireValidationStatus =
                  WireValitationAction.REQUEST_UPDATE_WITH_IMAGE
              "
            >
              Continuer
            </w-btn>
          </w-card-actions>
        </w-card>
      </div>
      <WireEditRequestCard
        v-if="
          m_wireValidationStatus === WireValitationAction.REQUEST_UPDATE ||
          m_wireValidationStatus ===
            WireValitationAction.REQUEST_UPDATE_WITH_IMAGE
        "
        :p_order="p_order"
        :p_privilege="p_privilege"
        @cancel="changeDecision()"
        @submit="checkModificationRequest()"
        @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
      ></WireEditRequestCard>
      <WireEditCard
        v-if="m_wireValidationStatus === WireValitationAction.WIRE_EDIT"
        :p_order="p_order"
        :p_privilege="p_privilege"
        @cancel="changeDecision()"
        @submit="sendWireModifications()"
      ></WireEditCard>
    </div>
  </div>
</template>

<script lang="ts">
import CommentArea from "@/components/shared/CommentArea.vue";
import GCodeDownloadButton from "@/components/shared/GCodeDownloadButton.vue";
import IGESDownloadButton from "@/components/shared/IGESDownloadButton.vue";
import PDFDownloadButton from "@/components/shared/PDFDownloadButton.vue";
import STLDownloadButton from "@/components/shared/STLDownloadButton.vue";
import { AuthHelper } from "@/helpers/AuthHelper";
import CommentHelper from "@/helpers/CommentHelper";
import OrderHelper from "@/helpers/OrderHelper";
import Comment from "@/models/Comment";
import Order from "@/models/Order";
import Project from "@/models/Project";
import { ColorsConstants } from "@/shared/constants";
import {
  Arcade,
  ArcadeString,
  DimensionString,
  MaterialString,
  OrderState,
  Privilege,
} from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import {
  mdiArrowRight,
  mdiCheckCircle,
  mdiChevronDown,
  mdiCircleEditOutline,
  mdiClose,
  mdiCloseCircle,
  mdiCommentEdit,
  mdiOpenInNew,
} from "@mdi/js";
import axios, { AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BaseDialog from "./BaseDialog.vue";
import PDFDialog from "./PDFDialog.vue";
import WireEditCard from "./WireEditCard.vue";
import WireEditRequestCard from "./WireEditRequestCard.vue";
import WireSummaryOrderDetail from "./WireSummaryOrderDetail.vue";

enum WireValitationAction {
  NONE,
  REQUEST_UPDATE_ASK_IMAGE,
  REQUEST_UPDATE_CHECK_IMAGE,
  REQUEST_UPDATE_WITH_IMAGE,
  REQUEST_UPDATE,
  WIRE_EDIT,
}

export default defineComponent({
  name: "WireSummary",
  components: {
    GCodeDownloadButton,
    PDFDownloadButton,
    STLDownloadButton,
    IGESDownloadButton,
    CommentArea,
    WireEditRequestCard,
    WireEditCard,
    WireSummaryOrderDetail,
    PDFDialog,
  },
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_order: {
      type: Object as () => Order,
      required: true,
    },
    p_isEditing: {
      type: Boolean,
      required: true,
    },
    p_project: {
      type: Object as () => Project,
      required: true,
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_trackingNumber = ref("");
    const m_attestationComment = ref(props.p_order.getAttestationComment());
    const m_modifCommentReady = ref(false);
    const m_wireValidationStatus = ref(WireValitationAction.NONE);

    const m_modificationImageLink = ref("#");

    const m_wireTypeString = computed(() => {
      if (props.p_order.jaw === Arcade.MANDIBLE) {
        return "Mandibulaire";
      } else if (props.p_order.jaw === Arcade.MAXILLA) {
        return "Maxillaire";
      } else {
        return "";
      }
    });

    const m_internalComment = ref(props.p_order.getInternalComment());
    const m_clientComment = ref(props.p_order.getClientComment());
    const m_updateComment = ref(props.p_order.getUpdateComment());
    const m_downloadMenu = ref(false);
    const m_doctorMenu = ref(false);

    const cancelDialog = ref<InstanceType<typeof BaseDialog> | null>(null);

    function hasMaxi(): boolean {
      return props.p_project.archMaxilla()?.existsInDatabase() ?? false;
    }

    function hasMandi(): boolean {
      return props.p_project.archMandible()?.existsInDatabase() ?? false;
    }

    async function _updateOrder(): Promise<void> {
      await OrderHelper.updateOrder(props.p_order);
      context.emit("order_updated", props.p_order);
    }

    function sendToProd(): void {
      props.p_order.sendToProd();
      _updateOrder();
    }

    function requestValidation(): void {
      props.p_order.requestValidation();
      _updateOrder();
    }

    async function submitValidation() {
      props.p_order.submitValidation();
      await _updateOrder();

      m_attestationComment.value.content +=
        "Design validé le " +
        new Date().toLocaleDateString() +
        " à " +
        new Date().toLocaleTimeString() +
        " par Dr. " +
        AuthHelper.getLoggedUser().lastName.toUpperCase() +
        ".\n";
      if (m_attestationComment.value.existsInDatabase())
        CommentHelper.updateComment(m_attestationComment.value as Comment);
      else
        await CommentHelper.addComment(m_attestationComment.value as Comment);

      logger.success("Design validé.");
    }

    function cancelOrder(): void {
      props.p_order.cancel();
      cancelDialog.value?.close();
      _updateOrder();
      logger.success("Commande annulée.");
    }

    function setKeyAsMade(): void {
      props.p_order.markKeyAsBuilt();
      _updateOrder();
    }

    function markAsShipped(p_trackingNumber: string): void {
      props.p_order.markAsShipped(p_trackingNumber);
      _updateOrder();
    }

    function markAsDelivered(): void {
      props.p_order.markAsDelivered();
      _updateOrder();
    }

    function setOrderStatus(p_status: OrderState): void {
      props.p_order.setStatus(p_status);
      _updateOrder();
    }

    async function checkModificationRequest(): Promise<void> {
      // update comment
      const updateComment: Comment = props.p_order.getUpdateComment();
      if (updateComment.content.length > 0) {
        if (updateComment.existsInDatabase()) {
          // update
          await CommentHelper.updateComment(updateComment);
        } else {
          // create
          await CommentHelper.addComment(updateComment);
        }
      } else if (
        m_wireValidationStatus.value !==
        WireValitationAction.REQUEST_UPDATE_WITH_IMAGE
      ) {
        // comment is optionnal when an image is attached
        logger.warning(
          "Veuillez saisir un commentaire avant de cliquer sur envoyer"
        );
        return;
      }

      // update image if necessary
      if (
        m_wireValidationStatus.value ==
        WireValitationAction.REQUEST_UPDATE_WITH_IMAGE
      ) {
        context.emit("sendModificationImage");
        // the order will be updated if the modification image is correctly sent
        // (the Editor will call the function validateModificationRequest)
      } else {
        // update order
        validateModificationRequest();
      }
    }

    function validateModificationRequest(): void {
      context.emit("hideImage");
      props.p_order.requestModifications();
      m_wireValidationStatus.value = WireValitationAction.NONE;
      _updateOrder();
      logger.success("Demande de modification envoyée.");
    }

    watch(
      () => props.p_order,
      () => {
        m_attestationComment.value = props.p_order.getAttestationComment();
        m_internalComment.value = props.p_order.getInternalComment();
        m_clientComment.value = props.p_order.getClientComment();
        m_updateComment.value = props.p_order.getUpdateComment();
        refreshImage();
      }
    );

    function validationEditWire(): void {
      m_wireValidationStatus.value = WireValitationAction.WIRE_EDIT;
      context.emit("editWire");
    }

    function changeDecision(): void {
      context.emit("hideImage");
      m_wireValidationStatus.value = WireValitationAction.NONE;
      context.emit("goToSummary");
    }

    function sendWireModifications(): void {
      m_wireValidationStatus.value = WireValitationAction.NONE;
      context.emit("saveManualWireEdit");
    }

    function drawOnImage(): void {
      m_wireValidationStatus.value =
        WireValitationAction.REQUEST_UPDATE_CHECK_IMAGE;
      context.emit("drawOnImage");
    }

    function sendModificationImage(): void {
      m_wireValidationStatus.value = WireValitationAction.NONE;
      context.emit("hideImage");
      context.emit("sendModificationImage");
      setTimeout(() => {
        refreshImage();
      }, 1000);
    }

    function goBackToAskImage(): void {
      context.emit("clearImage");
      context.emit("hideImage");
      m_wireValidationStatus.value =
        WireValitationAction.REQUEST_UPDATE_ASK_IMAGE;
    }

    function isUserProvidingFeedback(): boolean {
      return m_wireValidationStatus.value !== WireValitationAction.NONE;
    }

    function refreshImage(): void {
      m_modifCommentReady.value = false;
      axios
        .get(`wires/${props.p_order.wire!.id}/drawn-image/check`, {
          headers: { disableLoader: true },
        })
        .then((p_response: AxiosResponse) => {
          const status = p_response.status;
          if (status == StatusCodes.OK) {
            const image_exists = p_response.data.file_exists;
            if (image_exists) {
              axios
                .get(`wires/${props.p_order.wire!.id}/drawn-image`, {
                  responseType: "blob",
                  headers: { disableLoader: true },
                })
                .then((p_response: AxiosResponse) => {
                  // get attached file and display it on the modificationImage
                  if (p_response.data) {
                    const blob = new Blob([p_response.data], {
                      type: "image/png",
                    });
                    const urlCreator = window.URL || window.webkitURL;
                    const imageUrl = urlCreator.createObjectURL(blob);
                    m_modificationImageLink.value = imageUrl;
                    m_modifCommentReady.value = true;
                  }
                });
            } else {
              m_modificationImageLink.value = "#";
              m_modifCommentReady.value = true;
            }
          }
        });
    }

    onMounted(() => {
      refreshImage();
    });

    return {
      Privilege,
      Arcade,
      ArcadeString,
      DimensionString,
      MaterialString,
      OrderState,
      m_trackingNumber,
      m_attestationComment,
      m_wireTypeString,
      m_wireValidationStatus,
      WireValitationAction,
      ColorsConstants,
      m_modificationImageLink,
      m_modifCommentReady,
      m_internalComment,
      m_clientComment,
      m_updateComment,
      m_downloadMenu,
      m_doctorMenu,
      cancelDialog,
      hasMaxi,
      hasMandi,
      sendToProd,
      requestValidation,
      submitValidation,
      cancelOrder,
      setKeyAsMade,
      markAsShipped,
      markAsDelivered,
      checkModificationRequest,
      validationEditWire,
      changeDecision,
      sendWireModifications,
      drawOnImage,
      sendModificationImage,
      goBackToAskImage,
      validateModificationRequest,
      isUserProvidingFeedback,
      setOrderStatus,
      mdiArrowRight,
      mdiCheckCircle,
      mdiChevronDown,
      mdiCircleEditOutline,
      mdiCloseCircle,
      mdiCommentEdit,
      mdiClose,
      mdiOpenInNew,
    };
  },
});
</script>

<style lang="scss" scoped>
.title_wire_summary {
  border-bottom: 2px solid #d8dfea;
  padding-bottom: 6px;
}

.summary-container .text-subtitle-2 {
  line-height: 1.1;
}

.modification-image-div {
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  height: 56px;
  width: 56px;
}

.modification-image {
  max-width: 55px;
  max-height: 55px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}

.modification-image-overlay {
  width: 56px;
  height: 56px;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 143, 255, 0.47);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.text-caption {
  word-break: normal !important;
}
</style>
