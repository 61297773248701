<template>
  <w-container
    fluid
    style="max-width: 800px; margin: 0 auto"
  >
    <!-- Summary -->
    <w-card class="pa-3 my-3">
      <w-row class="my-0">
        <w-col
          cols="1"
          class="token-icon"
        >
          <w-icon
            max-width="30px"
            :icon="mdiCheckCircle"
          ></w-icon>
        </w-col>
        <w-col>
          <h2>Jetons restants : {{ m_tokensLeft }}</h2>
        </w-col>
        <w-col>
          <w-btn
            block
            color="white"
            href="mailto:contact@winnovemed.com?subject=Demande de jetons&body=Bonjour,%0D%0A%0D%0AJe souhaiterais obtenir des jetons pour la plateforme Iform.%0D%0A%0D%0AMerci de votre retour.%0D%0A%0D%0ACordialement."
            >Demander des jetons</w-btn
          >
        </w-col>
      </w-row>
    </w-card>

    <!-- Alerts -->
    <!-- less than 5 tokens -->
    <w-alert
      v-if="m_tokensLeft < 5 && m_tokensLeft > 0"
      class="my-3"
      type="warning"
      variant="tonal"
      prominent
    >
      Il vous reste moins de 5 jetons. Pensez à en recommander pour ne pas être
      bloqué.
    </w-alert>
    <!-- No more tokens -->
    <w-alert
      v-if="m_tokensLeft <= 0 && !isCurrentUserBlocked()"
      class="my-3"
      type="error"
      prominent
      variant="tonal"
    >
      Vous n'avez plus de jetons. Pensez à en recommander pour ne pas être
      bloqué.
    </w-alert>
    <!-- User blocked - no tokens -->
    <w-alert
      v-else-if="isCurrentUserBlocked() && m_tokensLeft <= 0"
      class="my-3"
      type="error"
      variant="tonal"
      prominent
    >
      <strong>Votre compte est actuellement bloqué</strong> car vous n'avez plus
      de jetons.
      <br />
      <w-divider class="my-2"></w-divider>
      <w-icon :icon="mdiEmailOutline"></w-icon> :
      <a href="mailto:contact@winnovemed.com">contact@winnovemed.com</a>
      <br />
      <w-icon :icon="mdiPhone"></w-icon> :
      <a href="tel:+33244848644">+33 2 44 84 86 44</a>
    </w-alert>

    <!-- Billing history -->
    <w-card class="pa-3 bg-white">
      <w-data-table-server
        :loading="m_isLoading"
        :headers="m_historyTableHeaders"
        :items="m_billingsHistory"
        :items-length="m_totalCount"
        :page="m_options.page"
        :items-per-page="m_options.itemsPerPage"
        multi-sort
        :sort-by="m_options.sortBy"
        density="compact"
        class="bg-white"
        @update:options="optionsChanged"
      >
        <template v-slot:top>
          <h3>Historique des consommations</h3>
        </template>

        <template #[`item.date`]="{ item }">
          <span>{{ DateHelper.formatDate(item.date) }}</span>
        </template>
        <template #[`item.comment`]="{ item }">
          <span>{{ formatComment(item.comment) }}</span>
        </template>
      </w-data-table-server>
    </w-card>
  </w-container>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import BillingHelper from "@/helpers/BillingHelper";
import CookieHelper from "@/helpers/CookieHelper";
import DateHelper from "@/helpers/DateHelper";
import { DataTableOptions } from "@/helpers/WinnoveHelper";
import Billing from "@/models/Billing";
import User from "@/models/User";
import { mdiCheckCircle, mdiEmailOutline, mdiPhone } from "@mdi/js";
import { useRepo } from "pinia-orm";
import { defineComponent, onMounted, ref } from "vue";

const DEFAULT_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [
    { key: "date", order: "desc" },
    { key: "cumulativeCredits", order: "asc" },
  ],
  groupBy: [],
  search: "",
};

const m_historyTableHeaders = [
  {
    title: "Date",
    key: "date",
    sortable: true,
    filterable: true,
  },
  {
    title: "Détails",
    key: "comment",
    sortable: false,
    filterable: false,
  },
  {
    title: "Évolution jetons",
    key: "credits",
    sortable: false,
    filterable: false,
  },
  {
    title: "# jetons à date",
    key: "cumulativeCredits",
    sortable: true,
    filterable: false,
  },
];

export default defineComponent({
  name: "BillingTable",
  components: {},
  props: {},
  setup() {
    const m_totalCount = ref(0);
    const m_tokensLeft = ref<number | undefined>(10);
    const m_billingsHistory = ref<Billing[]>([]);
    const m_isLoading = ref(true);
    const m_options = ref<DataTableOptions>(
      CookieHelper.getCookie("BillingDashboardOptionsV3")
        ? JSON.parse(CookieHelper.getCookie("BillingDashboardOptionsV3")!)
        : DEFAULT_OPTIONS
    );
    onMounted(() => {
      refresh();
    });

    async function refresh(): Promise<void> {
      const userId = AuthHelper.getLoggedUser().id!;
      // Get remaining tokens
      const remainings: { remaining: Billing[]; count: number } =
        await BillingHelper.getRemainingTokens(userId);
      m_tokensLeft.value = remainings.remaining[0].credits;
      const billingHistory: { billings: Billing[]; count: number } =
        await BillingHelper.getBillingHistory(userId, m_options.value);
      // Get billing history
      m_totalCount.value = billingHistory.count;
      m_billingsHistory.value = billingHistory.billings;
      m_isLoading.value = false;
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      _onOptionsChanged();
    }

    async function _onOptionsChanged(): Promise<void> {
      CookieHelper.setCookie("BillingDashboardOptionsV3", m_options.value);
      refresh();
    }

    function isCurrentUserBlocked() {
      const user = useRepo(User)
        .query()
        .where("id", AuthHelper.getLoggedUser().id)
        .first();
      if (!user) {
        return false;
      }
      return !!user.blocked;
    }

    function formatComment(value: string) {
      const splittedComment = value.split(" ");
      const reference = splittedComment[splittedComment.length - 1];
      let comment: string = value;
      if (value.includes("Maxillaire") || value.includes("Mandibulaire")) {
        comment = value.includes("Maxillaire") ? "U" : "L";
        comment += `${reference} `;
        if (value.includes("Annulation")) comment += " annulée";
      }
      return comment;
    }

    return {
      m_historyTableHeaders,
      m_billingsHistory,
      m_options,
      m_totalCount,
      m_isLoading,
      m_tokensLeft,
      DateHelper,
      isCurrentUserBlocked,
      optionsChanged,
      formatComment,
      mdiEmailOutline,
      mdiPhone,
      mdiCheckCircle,
    };
  },
});
</script>

<style scoped>
.token-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
