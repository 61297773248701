<template>
  <div>
    <span v-if="orderMaxilla">
      <OrderStateChip
        :p_privilege="p_privilege"
        :p_state="orderMaxilla.status"
        :p_isSelected="p_isSelected"
        :class="{ 'mb-1': orderMandible }"
      />
      <a
        v-if="orderMaxilla.canShowTrackingLink()"
        @click="trackOrder(orderMaxilla)"
      >
        <div>
          <span :class="p_isSelected ? 'span_selected' : 'span_not_selected'">{{
            getTrackingLinkText(orderMaxilla)
          }}</span>
        </div>
      </a>
    </span>

    <span v-if="orderMandible">
      <OrderStateChip
        :p_privilege="p_privilege"
        :p_state="orderMandible.status"
        :p_isSelected="p_isSelected"
      />
      <a
        v-if="orderMandible.canShowTrackingLink()"
        @click="trackOrder(orderMandible)"
      >
        <div>
          <span :class="p_isSelected ? 'span_selected' : 'span_not_selected'">{{
            getTrackingLinkText(orderMandible)
          }}</span>
        </div>
      </a>
    </span>
  </div>
</template>

<script lang="ts">
import OrderHelper from "@/helpers/OrderHelper";
import Order from "@/models/Order";
import Project from "@/models/Project";
import { Privilege } from "@winnove/vue-wlib/enums";
import { computed, ComputedRef, defineComponent } from "vue";
import OrderStateChip from "../../../../shared/OrderStateChip.vue";

export default defineComponent({
  name: "ProjectStateCell",
  components: {
    OrderStateChip,
  },
  props: {
    p_project: {
      type: Object as () => Project,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_isSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const orderMandible: ComputedRef<Order | null> = computed(() => {
      return props.p_project.orderMandible();
    });

    const orderMaxilla: ComputedRef<Order | null> = computed(() => {
      return props.p_project.orderMaxilla();
    });

    function getTrackingLinkText(p_order: Order | null) {
      if (!p_order) return;
      if (p_order.isDelivered()) {
        return "Preuve de livraison >";
      }
      return "Suivre le colis >";
    }

    function trackOrder(p_order: Order | null) {
      if (!p_order) return;
      return OrderHelper.trackOrder(p_order.trackingNumber);
    }

    return {
      orderMandible,
      orderMaxilla,
      trackOrder,
      getTrackingLinkText,
    };
  },
});
</script>

<style scoped>
.span_selected {
  color: white;
  text-decoration: underline;
}

.span_not_selected {
  color: rgb(var(--v-theme-primary));
  text-decoration: underline;
}
</style>
