import Comment from "@/models/Comment";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";

export default class CommentHelper {
  //update comment
  static async updateComment(p_comment: Comment): Promise<void> {
    await axios
      .put("comments/" + p_comment.id, { comment: p_comment.serialize() })
      .then(async () => {
        p_comment.initialContent = p_comment.content;
        useRepo(Comment).where("id", p_comment.id).update(p_comment);
      });
  }

  // add comment
  static async addComment(p_comment: Comment): Promise<Comment> {
    return await axios
      .post("comments", { comment: p_comment.serialize() })
      .then(async (p_response: AxiosResponse) => {
        // save comment in pinia-orm
        const commentData = p_response.data.comment;
        commentData.initialContent = commentData.content;
        useRepo(Comment).save(commentData);
        // remove initial comment from pini-orm
        useRepo(Comment).destroy(p_comment.id!);
        // return new comment
        return useRepo(Comment).find(commentData.id) as Comment;
      });
  }
}
