<template>
  <div class="d-flex justify-center pa-2">
    <w-form
      ref="formAccount"
      v-model="m_isFormValid"
      @submit.prevent="validateForm()"
      style="max-width: 800px; width: 100%"
    >
      <h2 class="mb-4">Mon profil</h2>
      <w-row>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_user.lastName"
            label="Nom"
            :prepend-inner-icon="mdiAccountBox"
            :rules="[(v) => (v && !!v.trim()) || 'Nom requis']"
            required
            autofocus
          ></w-text-field>
        </w-col>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_user.firstName"
            label="Prénom"
            :prepend-inner-icon="mdiAccountBox"
            :rules="[(v) => (v && !!v.trim()) || 'Prénom requis']"
            required
          ></w-text-field>
        </w-col>
      </w-row>
      <w-row>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_user.email"
            label="Email"
            :prepend-inner-icon="mdiEmail"
            required
            disabled
          ></w-text-field>
        </w-col>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_user.phoneNumber"
            label="Téléphone"
            :prepend-inner-icon="mdiPhone"
            type="number"
          ></w-text-field>
        </w-col>
      </w-row>
      <w-row>
        <w-col md="12">
          <AddressTextField
            v-model:p_address="m_address.address"
            p_label="Adresse"
          />
        </w-col>
      </w-row>
      <w-row>
        <w-col md="12">
          <AddressTextField
            v-model:p_address="m_address.address2"
            p_label="Adresse suite"
          />
        </w-col>
      </w-row>
      <w-row>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_address.city"
            label="Ville"
          ></w-text-field>
        </w-col>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_address.postalCode"
            label="Code postal"
            type="number"
            onkeydown="return event.keyCode !== 69"
          ></w-text-field>
        </w-col>
      </w-row>
      <w-row>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_address.country"
            label="Pays"
          ></w-text-field>
        </w-col>
      </w-row>
      <w-row v-if="isProductionRole">
        <w-col md="12">
          <multiple-date-picker-range
            :p_dates="m_user.unavailableDates"
            :p_min="m_todayDate"
            @change="updateUnavailableDates"
          />
        </w-col>
      </w-row>
      <w-row>
        <w-col
          sm="4"
          offset-sm="4"
        >
          <w-btn
            :loading="m_formBeingSubmitted"
            :disabled="!m_isFormValid"
            color="primary"
            type="submit"
            block
            >Enregistrer
          </w-btn>
        </w-col>
      </w-row>
    </w-form>
  </div>
</template>

<script lang="ts">
import AddressTextField from "@/components/shared/AddressTextField.vue";
import AddressHelper from "@/helpers/AddressHelper";
import { AuthHelper } from "@/helpers/AuthHelper";
import UserHelper from "@/helpers/UserHelper";
import Address from "@/models/Address";
import User from "@/models/User";
import { ROUTE_PROJECTS } from "@/router";
import { Role } from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import { mdiAccountBox, mdiEmail, mdiPhone } from "@mdi/js";
import { useRepo } from "pinia-orm";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "Account",
  components: { AddressTextField },
  setup(props, context) {
    const logger = Logger.getInstance();
    const formAccount = ref(null);
    const m_isFormValid = ref(true);
    const m_user = ref(new User());
    const m_address = ref(new Address());
    const m_formBeingSubmitted = ref(false);
    const m_unavailableDates = ref();
    const m_todayDate = ref();
    const m_role = ref(Role.DEFAULT);

    onMounted(() => {
      (async () => {
        await AddressHelper.fetchAddresses();
        m_user.value = AuthHelper.getLoggedUser();
        const addressesRepo = useRepo(Address);
        m_address.value = addressesRepo
          .query()
          .where("userId", m_user.value.id)
          .first()!;
        m_todayDate.value = new Date().toString();
        m_role.value = AuthHelper.getLoggedUser().role;
      })();
    });

    const isProductionRole = computed((): boolean => {
      return m_role.value == Role.PRODUCTION;
    });

    async function validateForm(): Promise<void> {
      m_formBeingSubmitted.value = true;
      // Update user.
      await UserHelper.updateUser(m_user.value as User);

      // Persist address.
      await AddressHelper.updateAddress(m_address.value as Address);

      logger.success("Profil mis à jour");

      // Force to re-render doctor name.
      m_user.value = AuthHelper.getLoggedUser();
      context.emit("update");
      m_formBeingSubmitted.value = false;
    }

    function updateUnavailableDates(value: string) {
      m_user.value.unavailableDates = value;
    }

    return {
      formAccount,
      m_isFormValid,
      m_user,
      m_address,
      ROUTE_PROJECTS,
      m_formBeingSubmitted,
      validateForm,
      mdiPhone,
      mdiEmail,
      mdiAccountBox,
      m_unavailableDates,
      m_todayDate,
      updateUnavailableDates,
      isProductionRole,
    };
  },
});
</script>
